import styled from 'styled-components';
import Button from '../../Button';
import Layout from '../index';

const { Header } = Layout;

export default styled(Header)`
    z-index: 1000;
    position: fixed;
    width: 100%;

    overflow: hidden;
    height: ${({ theme }) => theme.header.height};
    padding: ${({ theme }) => theme.main.padding};
    padding-left: 20px;
    padding-right: 20px;

    background: ${({ theme }) => theme.main.primaryFaded};
    box-shadow: none;

    transition: background-color 0.3s linear, box-shadow 0.3s linear;
    .header-row{
        flex-flow: inherit;
    }

    &.light {
        background: ${({ theme }) => theme.header.backgroundColor};
        box-shadow: ${({ theme }) => theme.main.shadow};
    }
    .header-logo {
        width: calc(100% - 10px);
        // max-height: 60px;
        user-select: none;
    }
   

    .header-btn {
        background: #fff;
        border: 0px;
        box-shadow: none;
        border-bottom: 0px;
        border-radius: 0px;
        padding: 0px 0px;
        font-weight: 600;
        font-size:13px
    }
    .buyer-col{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: flex-start;
    }
    .buyer-col button{
        margin-left:10px
    }
    .buyer-col h4{
        margin-bottom:0px;
        line-height: 18px;
    }
    .buyer-col a{
        margin-bottom:0px;
        line-height: 40px;
    }
    .adisabled {
        cursor: not-allowed ;
    }
    .adisabled a{
        cursor: not-allowed ;
        pointer-events: none;
    }

    .adisabled button:hover{
       border:none ;
    }

    .adisabled span{
       color:gray;
       cursor: not-allowed  !important;
    }
    .adisabled button{
        color:gray;
        cursor: not-allowed  !important;
     }
 

    .active-line,
    .header-btn:active,
    .header-btn:focus,
    .header-btn:hover {
        border: 0px;
        border-bottom: 2px solid ${({ theme }) => theme.main.primaryColor};
    }
    .menu-draw .ant-space{
      
        justify-content: center;
        align-items: center;
    }

    .menu-draw-admin {
        align-items: center;
    justify-content: space-between;
    }
    .menu-draw .ant-space {
        gap: 10% !important;
        width: 100%;
    }
    .ant-collapse > .ant-collapse-item {
        border: none !important;
    }
    .ant-collapse {
        .mobil-menu .ant-collapse {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-variant: tabular-nums;
            line-height: 1.5715;
            list-style: none;
            font-feature-settings: 'tnum', "tnum";
            background: none;
            border: none ;
            border-bottom: 0;
            border-radius: 2px;
          }
          .mobil-menu  .ant-collapse-content {
            color: rgba(0, 0, 0, 0.85);
            background-color: #fff;
            border: none;
          }
          .mobil-menu .ant-collapse > .ant-collapse-item {
            border: none;
          }
          .mobil-menu .ant-collapse-content > .ant-collapse-content-box {
            padding: 0;
            padding-left: 30px;
            display: flex;
            flex-direction: column;
          }
          .mobil-menu .ant-collapse-content > .ant-collapse-content-box a{
            margin-bottom: 10px
          }
          .mobil-menu .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
            flex: auto;
            font-weight: 500;
            font-size: 14px !important;
            color: rgb(13, 15, 123) !important;
          }
          .mobil-menu .ant-btn.ant-btn-block {
            width: 100%;
            font-weight: 500;
            font-size: 14px !important;
            color: rgb(13, 15, 123) !important;
          }
          .mobil-menu .ant-btn > span {
            display: inline-block;
            font-weight: 500;
            font-size: 14px !important;
            color: rgb(13, 15, 123) !important;
          }
   
`;

export const DrawerButton = styled(Button)`
    text-align: start;
`;
