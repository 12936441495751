import React from 'react';
import { Col } from 'antd';
import { InvoiceText, InvoiceRow, InvoiceCol, FooterBox, InvoiceValue, InvoiceButton } from './styles';
import { convertFloatDotSeperated } from '../../../../utils';


const InvoicesDiscountSummary = ({ invoiceCalculate, onChange }) => (

    <FooterBox style={{ margin: '10px auto'}} >
        <Col  background="primaryFaded" margin={false}  >
            <InvoiceRow className='invoiceRow'>
                <div className='table-div-first'>
                    <InvoiceCol style={{minWidth:'180px'}}>
                        {
                            invoiceCalculate.logo 
                        }
                    </InvoiceCol>

                    <InvoiceCol style={{minWidth:'160px'}} className='num-invoice-box'>
                        <InvoiceText type="mini-title">
                            <b>Mevcut Limit:</b>
                        </InvoiceText>
                        <InvoiceValue style={{ textAlign: 'left' }} type="mini-title">
                        {convertFloatDotSeperated((invoiceCalculate?.supplierLimit))} TL
                        </InvoiceValue>
                    </InvoiceCol>

                    <InvoiceCol style={{ minWidth:'160px', paddingLeft:'15px'}}>
                        <InvoiceText type="mini-title">
                            <b>Mevcut Risk:</b>
                        </InvoiceText>
                        <InvoiceValue style={{ textAlign: 'left' }} type="mini-title">
                            {convertFloatDotSeperated(invoiceCalculate?.supplierRisk)} TL
                        </InvoiceValue>
                    </InvoiceCol>
                </div>
                

                <div className='table-div' >
                    <InvoiceCol style={{ minWidth:'160px', borderRight:'1px solid #ededed'}} className='maturity-rate-box'>
                        <InvoiceText type="mini-title">
                            <b>Teklif Tutarı:</b>
                        </InvoiceText>
                        <InvoiceValue style={{ textAlign: 'left' }} type="mini-title">
                            {convertFloatDotSeperated((invoiceCalculate?.price))} TL
                        </InvoiceValue>
                    </InvoiceCol>
                
                
                    <InvoiceCol className='last-limit' style={{ minWidth:'160px',paddingLeft:'15px'}}>
                        <InvoiceText type="mini-title">
                            <b>Kalacak Limit: </b>
                        </InvoiceText>
                        <InvoiceValue style={{ textAlign: 'left' }} type="mini-title">
                            { convertFloatDotSeperated(invoiceCalculate?.supplierLimit - (invoiceCalculate?.supplierRisk + invoiceCalculate?.invoiceTotal ))} TL
                        </InvoiceValue>
                    </InvoiceCol>

                    <InvoiceCol style={{paddingLeft:'0', marginRight:'4px',minWidth:'180px', textAlign:'center'}}>
                        <InvoiceButton
                            key="invoice-discount-button"
                            type="primary"
                            className="apply-box btn-color"
                            onClick={onChange}
                            loading={false}>
                            Hemen Başvur
                        </InvoiceButton>
                    </InvoiceCol>
                </div>
            </InvoiceRow>
        </Col>
    </FooterBox>
);

export default InvoicesDiscountSummary;
