import React, {useEffect, useState} from 'react';
import { Row, Table} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setBankList } from '../../store/reducers/supplierFinanceSlice'
import { BankListBox } from './styles';
import { getBanks } from '../../apiServices/userApi';
import { convertFloatDotSeperated } from '../../utils';

const columns = [
    {
      title: 'Finans Kurumu',
      dataIndex: 'financialInstitutionName',
      width: 200,
      fixed: 'left',
    },
    {
      title: 'Mevcut Risk',
      dataIndex: 'supplierRisk',
      render : (value) => `${convertFloatDotSeperated(value) } TL`
    },
    {
      title: 'Limit',
      dataIndex: 'supplierLimit',
      render : (value) => `${convertFloatDotSeperated(value) } TL`
    },
    {
      title: `Risk / Limit %`,
      render : (row) => (`${row.supplierRisk === 0 || row.supplierLimit === 0 ? '-' : ((row.supplierRisk  * 100)/row.supplierLimit)?.toFixed(2)} %` ) 
    },
  ];
 
const BankRiskLimit = () => {
 const { banks } = useSelector((state) => state.supplierFinance);
 const [loading, setLoading] = useState(true);
 const [bankLimitTotal, setBankLimitTotal] = useState();
 const [bankRiskTotal, setBankRiskTotal] = useState();
 const dispatch = useDispatch();
 
 const getBankList = async () => {  
    try {      
        const data =  await getBanks();
        dispatch(setBankList(data.supplierRiskAndLimits))
        setLoading(false);
      } 
    catch (e) { console.log(e) }
  }  
  
 useEffect(() => {
  getBankList() 
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

useEffect(() => {
setBankRiskTotal(
    banks.reduce(
      (risk, item)=> risk + item.supplierRisk, 
      0)
  );
  setBankLimitTotal(
    banks.reduce(
      (limit, item)=> limit + item.supplierLimit, 
      0)
  ); 
}, [banks]);

    return (
        <BankListBox>        
           <Row>
            <h3 className='page-title'>Risk/Limit Bilgilerim</h3>
           </Row>
           <Row className='table-row'>
            <div>                
                <Table
                    className='bank-table'
                    style={{maxWidth:'850px'}}
                    columns={columns}
                    dataSource={banks}
                    loading={loading} 
                    rowKey="id"
                    scroll={{
                        x: 400,
                      }}
                    bordered
                    summary={() => (
                      <Table.Summary fixed>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0}>TOPLAM</Table.Summary.Cell>
                          <Table.Summary.Cell index={1}>{bankRiskTotal && convertFloatDotSeperated(bankRiskTotal)} TL</Table.Summary.Cell>
                          <Table.Summary.Cell index={2}>{bankLimitTotal &&convertFloatDotSeperated(bankLimitTotal)} TL</Table.Summary.Cell>
                          <Table.Summary.Cell index={3}>
                            {
                              bankRiskTotal && 
                              bankRiskTotal === 0 || bankLimitTotal === 0 ? 
                              '- %'
                              :
                               ((bankRiskTotal * 100)/bankLimitTotal).toFixed(2)} %
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>
                    )}
                />
            </div>
           </Row>
           
        </BankListBox>
    );
};



export default BankRiskLimit;