import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { PDFDocument } from 'pdf-lib';
import moment from 'moment';
import fontkit from '@pdf-lib/fontkit';
import { Form, Divider, Input, Steps, notification, Checkbox, Modal, Tabs} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {  useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import UserStepForm from './UserStepForm';
import GsmCodeVerificationModal from './GsmCodeVerificationModal';
import { fetchProvinces } from '../../../apiServices/commonApi';
import {getDocType, getCode, signUp, verifyCaptchaToken} from '../../../apiServices/userApi'; 
import { setProvinces } from '../../../store/reducers/commonSlice';
import { setDocType, setLoggedIn, setUser } from '../../../store/reducers/userSlice';

import { validatePassword,validateEmail } from '../../../utils/validators';
import urls from '../../../routes/urls';
import ClarificationText from '../../Documents/ClarificationText';
import ExplicitConsentText from '../../Documents/ExplicitConsentText'; 
import MembershipAgreement from '../../Documents/MembershipAgreement';

import illuminationText from '../../../assests/Aydinlatma_metni.pdf';
import expressConsent from '../../../assests/Kvkk.pdf';
import membershipAgreement from '../../../assests/Uyelik_sozlesmesi.pdf';
import NotoFont from '../../../assests/NotoSerif-Regular.ttf';

const { Step } = Steps;

const getStepTitle = (title) => (
    <Text className="m-0" type="subtitle" bold>
        {title}
    </Text>
);

const { TabPane } = Tabs;
function SignUpTab({ setActiveTabLogin }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [signUpForm] = Form.useForm();
    const [codeLoading, setCodeLoading] = useState(false);
    const [loading, setloading] = useState(false);
    const [provinceName, setProvinceName] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
   
    const [isCodeValid, setIsCodeValid] = useState(false);
    const [nextBtn, setNextBtn] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [supTitle, setSupTitle] = useState('');
    const [companyType, setCompanyType] = useState('');
    const [district, setDistrict] = useState('');     
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tabShow, setTabShow] = useState(1); 
    const [register, setRegister] = useState(true);
    const [activeNext, setActiveNext] = useState(false); 
    const [codeShow, setCodeShow] = useState(false);
    const [checkShow, setCheckShow] = useState(true);
    const captchaRef = useRef(null);
    const [illuminationTextData, setIlluminationTextData] = useState('');
    const [expressConsentData, setExpressConsentData] = useState('');
    const [membershipAgreementData, setMembershipAgreementData] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [term, setTerm] = useState([]);


    const { createdUser, supplierTitle} = useSelector((state) => state.user);
    const { userInfo} = useSelector(({ common }) => common);
  
    const onChangeCheck = () => {
        setRegister(false)
      };
  
    const handleCancel = () => {
        setIsModalOpen(false);
      };
      const onChangeTab = (key) => {
        setTabShow(key)
      };

      const showModal = (keyX) => {
        setIsModalOpen(true);
         setTabShow(keyX)
      };

    const toBase64 = (u8) => btoa(u8.reduce((data, byte) => data + String.fromCharCode(byte), ''));

    const sendEmail = async () => {
        const date = new Date();
        const fontBytes = await fetch(NotoFont).then((res) => res.arrayBuffer());
        const illuminationTextPdfBytes = await fetch(illuminationText)
        .then((res) => res.arrayBuffer())
        .then((arrayBufferData) => arrayBufferData);
        if (illuminationTextPdfBytes) {
          const pdfDocIlluminationText = await PDFDocument.load(illuminationTextPdfBytes, {
              updateMetadata: false,
          });
      
          pdfDocIlluminationText.registerFontkit(fontkit);
          const customFont = await pdfDocIlluminationText.embedFont(fontBytes)
      
          const pagesOne = pdfDocIlluminationText.getPages();
          pagesOne[3].moveTo(40, 320);
          pagesOne[3].drawText(moment(date.toDateString()).format('DD/MM/YYYY') , {
                size: 10,
          });
          pagesOne[3].moveTo(40, 306);
          pagesOne[3].drawText( `${name} ${surname}` , {
                size: 10,
                font: customFont,
          });
          
          const illuminationTextPdf = await pdfDocIlluminationText.save();
          setIlluminationTextData(toBase64(illuminationTextPdf))
      }
      
      const expressConsentPdfBytes = await fetch(expressConsent)
      .then((res) => res.arrayBuffer())
      .then((arrayBufferData) => arrayBufferData);
        if (expressConsentPdfBytes) {
          const pdfDocExpressConsent = await PDFDocument.load(expressConsentPdfBytes, {
              updateMetadata: false,
          });
          
          pdfDocExpressConsent.registerFontkit(fontkit);
          const customFont = await pdfDocExpressConsent.embedFont(fontBytes)
      
          const pagesTwo = pdfDocExpressConsent.getPages();
          pagesTwo[0].moveTo(100, 202);
          pagesTwo[0].drawText(moment(date.toDateString()).format('DD/MM/YYYY') , {
              size: 10,
          });
          pagesTwo[0].moveTo(120, 180);
          pagesTwo[0].drawText( `${name} ${surname}`  , {
              size: 10,
              font: customFont,
          });
      
          const expressConsentPdf = await pdfDocExpressConsent.save();
          setExpressConsentData(toBase64(expressConsentPdf));
        }

        const membershipAgreementPdfBytes = await fetch(membershipAgreement)
      .then((res) => res.arrayBuffer())
      .then((arrayBufferData) => arrayBufferData);
        if (membershipAgreementPdfBytes) {
          const membershipAgreementText = await PDFDocument.load(membershipAgreementPdfBytes, {
              updateMetadata: false,
          });
          
          membershipAgreementText.registerFontkit(fontkit);
          const customFont = await membershipAgreementText.embedFont(fontBytes)

          const title = ` ${supTitle}`;
          let titlePay = [];
          titlePay = title.split(' ');
      
          const pagesThree = membershipAgreementText.getPages();
          pagesThree[11].moveTo(110, 450);
          pagesThree[11].drawText(moment(date.toDateString()).format('DD/MM/YYYY') , {
              size: 10,
          });
        
          if(titlePay.length !== 1){
            let x = 1;
            do {
                    pagesThree[11].moveTo(100, 435 - x*6);
                    pagesThree[11].drawText( ` ${titlePay[x] !== undefined ? titlePay[x] : '' }   ${titlePay[x+1] !== undefined ? titlePay[x+1] : ''} ${titlePay[x+2] !== undefined ? titlePay[x+2] : ''}  ${titlePay[x+3] !== undefined ? titlePay[x+3] : ''}`, 
                    {
                        size: 10,
                        font: customFont,
                    });
                
                  x += 4;
              } while (x < titlePay.length -1); 

        }
          const membershipAgreementPdf = await membershipAgreementText.save();
          setMembershipAgreementData(toBase64(membershipAgreementPdf));
         }
       
    }

    const sendTermsEmail = async () => {
        const terms = [];
        terms.push(
          {
            base64File: illuminationTextData,
            fileName: 'Neo Elektronik - Kişisel Verilere İlişkin Aydınlatma Metni.pdf'
          },
          {
            base64File: expressConsentData,
            fileName: 'Neo Elektronik - KVKK ile İlgili Açık Rıza Beyanı.pdf'
          },
          {
            base64File: membershipAgreementData,
            fileName: 'Neo Elektronik - Üyelik Sözleşmesi.pdf'
          }
        )
    
       setTerm(terms)
      }
   
    const createUser = async () => {        
        setloading(true);
        const formValues = signUpForm.getFieldsValue();  
        try {
            if(codeShow){
                if (
                    formValues.name &&
                    formValues.surname &&
                    formValues.email &&
                    formValues.password &&
                    formValues.gsmNumber &&
                    userInfo.taxId &&
                    companyType &&
                    supTitle&&
                    district &&
                    userInfo.taxAdmin  &&
                    term
                ) {
                   
                    const response = await signUp({
                        name: formValues.name,
                        surname: formValues.surname,
                        email: formValues.email,
                        password: formValues.password,
                        phone: formValues.gsmNumber,
                        taxId: `${userInfo.taxId}`,
                        companyType: `${companyType}`,
                        title: `${supTitle}`,
                        district: `${district}`,
                        taxAdministration:  userInfo.taxAdmin,
                        province: provinceName,
                        terms: term
                    });                
                   
                    if (response) {
                            setloading(false);
                        setTimeout(() => {   
                            dispatch(setUser({id: response.supplierId}));
                            dispatch(setLoggedIn(response.token));   
                            history.push(urls.buyers);
                        }, 1000);  
                    } else {
                        setloading(false);
                    }
                } else {
                    notification.warning({
                        message: `Lütfen bilgilerinizi kontrol ediniz.Eksik bilgi girdiniz.`,
                    });
                }
            }else{
                notification.warning({
                    message: `Telefon numaranızı girerek, "Kaydet ve İlerle " ile doğrulama yapmalısınız.`,
                });
             }
            
        } catch (e) {
            setloading(false);
            console.log(e)
        }
    };  

    useEffect(() => {
        if (createdUser) {
            setActiveStep(2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createdUser]);

    const onCodeSuccess = () => {
        setIsCodeValid(true);
        setActiveStep(1);
    };

    const getCodeNo = async () => {
        setCodeLoading(false);
       
        const form1 = signUpForm.getFieldsValue();
        const token = captchaRef.current.getValue();
        if(form1.name === undefined || form1.surname === undefined || form1.email === undefined || form1.password === undefined || form1.gsmNumber=== undefined){
            notification.warning({
                message: 'Uyarı',
                description: (
                  <div className='common-err'>
                   İletişim bilgileri alanlarını boş bırakmamalısınız.
                  </div>
                ),
                duration: 10
              })
        }
        else if(token === '' )
        {
            notification.warning({
                message: 'Uyarı',
                description: (
                    <div className='common-err'>
                        Lütfen Captcha doğrulamasını yapın
                    </div>
                ),
                duration: 10
            })
        }
        else{
            setCodeLoading(true);            
            if (form1.name && form1.surname && token && form1.email && form1.password && form1.gsmNumber)
            {
                try {
                    await verifyCaptchaToken(token);

                        const response = await getCode(form1.gsmNumber);
                        if (response) {
                            setIsVisible(true);
                            setCodeLoading(false);
                            setCodeShow(true);
                            setCheckShow(false)
                        }

                }
                catch (e) {
                    if(e.data.Status === 500){
                        notification.error({
                            message: `Beklenmedik bir hata oluştu.`,
                        });
                    }
                    if(e.data.Status === 400){
                        notification.warning({
                            message: `Lütfen doğrulama testini tekrar deneyin`,
                        });
                    }
                }
            }
        } 
    };

    const getProvinces = async () => {
        const response = await fetchProvinces();       
        if (response) {
            dispatch(setProvinces(response));
        }
    };

    const getDocTypeData = async () => {
        const response = await getDocType();
        if (response) {
            dispatch(setDocType(response));
        }
    };

    useEffect(() => {
        if(supTitle !== '' && name !== '' && surname !== ''){
          sendEmail();
        }
       },[supTitle, name, surname])
     
    
      useEffect(() => {
       if(illuminationTextData !== '' && expressConsentData !== '' && membershipAgreementData !== ''){
        sendTermsEmail()
       }
      },[illuminationTextData,expressConsentData,membershipAgreementData])
  
    useEffect(() => {
        getProvinces();
        getDocTypeData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSteps = (val) => {
        setActiveStep(val);
    };
    const handleNextSteps = (val) => {
        setActiveStep(val);
        setNextBtn(!nextBtn)
    };

    return (
        <>
            <GsmCodeVerificationModal
                onSuccess={onCodeSuccess}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                gsmNumber={signUpForm.getFieldValue('gsmNumber')}
                token={captchaRef}
            />
          
            <Text type="subtitle" color="primaryDark">
                Üye Ol
            </Text>
            <Form name="basic" form={signUpForm} onFinish={createUser} size="large">
                <Steps direction="vertical" current={activeStep} onChange={handleSteps}>
                    <Step
                         title={getStepTitle('Firma Bilgileri')}
                         description={
                            activeStep === 0 ? (
                                <>
                            <UserStepForm
                            form={signUpForm}
                            setSupTitle={setSupTitle}
                            setActiveNext={setActiveNext}
                            setProvinceName={setProvinceName}
                            setCompanyType={setCompanyType} 
                            setDistrict={setDistrict}  
                            // setCaptchaCodeState={setCaptchaCodeState}                        
                            />
                          {supTitle && provinceName  && activeNext && <Button  type="primary" 
                            onClick={() => handleNextSteps(1) 
                            } >İleri</Button>
                        }  
                          </>
                            ): ( '' )
                          
                         }                       
                    />
                    <Step
                        //  disabled={!isCodeValid}
                        title={getStepTitle('İletişim Bilgileri')}
                        description={
                            nextBtn || activeStep === 1 ? (
                                <>
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Lütfen ad giriniz.',
                                        },
                                    ]}>
                                    <Input placeholder="Ad" onChange={(e) => setName(e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    name="surname"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Lütfen soyad giriniz.',
                                        },
                                    ]}>
                                    <Input placeholder="Soyad" onChange={(e) => setSurname(e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            validator: (_, value) => validateEmail(value),
                                        },
                                    ]}>
                                    <Input placeholder="E-posta adresi" />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                           validator: (_, value) => validatePassword(value),
                                        },
                                    ]}>
                                        <Input.Password placeholder="Şifre" />                      
                                </Form.Item>
                                <Form.Item
                                    name="gsmNumber"
                                    rules={[
                                        {
                                            required: true,
                                            validator: (_, value) => {
                                                const patt = new RegExp('(5)[0-9][0-9][0-9]([0-9]){6}');
                                                return patt.test(value) && value.length < 11
                                                    ? Promise.resolve()
                                                    : Promise.reject(
                                                          new Error(
                                                              'Lütfen geçerli bir telefon numarası giriniz.'
                                                          )
                                                      );
                                            },
                                        },
                                    ]}>
                                    <Input addonBefore="+90" placeholder="Telefon Numarası" />
                                </Form.Item>
                                <Form.Item
                                name="captcha"
                                >
                                    <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} ref={captchaRef} hl="tr" />
                                </Form.Item>
                                {!isCodeValid && (
                                    <Button
                                        type="primary"
                                        loading={codeLoading}
                                        onClick={getCodeNo}
                                        size="large"
                                        >
                                        Kaydet ve İlerle
                                    </Button>
                                )}
                               
                            </>
                             ) 
                            : ( '' )
                           
                        }
                       
                    />
                 
                </Steps>
                <div className='contract-modal'>
                    <Checkbox onChange={onChangeCheck} disabled={checkShow} > </Checkbox>
                    <Button onClick={() => showModal(0)}>Üyelik Sözleşmesi</Button>’ni, <Button onClick={() => showModal(1)}>Aydınlatma Metni</Button>'ni ve <Button onClick={() => showModal(2)}> Açık Rıza Metni</Button>'ni okudum, anladım ve onaylıyorum. Kurumunuz ile paylaşmış olduğum iletişim bilgilerim ile elektronik iletiletilerin tarafımla paylaşılmasını onaylıyorum.
                </div>
                <Form.Item>
                    <Button disabled={register } className={register? 'register-btn': ''} type="primary" htmlType="submit" size="large" loading={loading} block>
                        Kayıt Ol
                    </Button>
                </Form.Item>    
            </Form>

            <Divider>
                <Text color="smoke">Hesabım Var</Text>
            </Divider>
            <Button className="sign-up-btn" type="outline" size="large" onClick={setActiveTabLogin} loading={loading} block>
                Giriş Yap
            </Button>

            <Modal className='contract-modal-style'  footer={false} open={isModalOpen} onCancel={handleCancel}>
                <Tabs activeKey={`${tabShow}`}  onChange={onChangeTab}>
                    <TabPane tab="Üyelik Sözleşmesi" key='0'>
                        <MembershipAgreement supplierTitle={supplierTitle}/>
                    </TabPane>
                    <TabPane tab="Aydınlatma Metni" key='1'>
                        <ClarificationText/>
                    </TabPane>
                    <TabPane tab="Açık Rıza Metni" key='2'>
                        <ExplicitConsentText/>
                    </TabPane>
                </Tabs>
            </Modal>
        </>
    );
}

SignUpTab.propTypes = {
    setActiveTabLogin: PropTypes.func.isRequired,
};

export default SignUpTab;
