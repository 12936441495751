import styled from 'styled-components';
import { Table } from 'antd';

export default styled(Table)`
    .ant-table-thead > tr > th {
      
        font-weight: bold;
        font-size: 14px;
    }

    td {
        font-size: 13px;
        font-weight: 500 !important;
    }
`;
