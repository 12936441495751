import styled from 'styled-components';
import Card from '../../../components/Card';
import Button from '../../../components/Button';

export default styled(Card)`
    border: 2px solid #a8c5ff;
    background: #f0f5ff;
`;

export const HelpBox = styled.div`
    width: 70%;
    margin: 40px auto 15px;
    display: flex;
    justify-content: space-between;
    .info_box {
        width: 225px;
        height: 304px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 40px;
        padding: 32px 24px;
        border-radius: 24px;
        border: solid 1px #e6f1ff;
        background-color: #f8fbff;
        text-align: center;
        margin-right: 25px;
    } 
  
`;

export const FaturaButton = styled(Button)`
    width: 264px;
    height: 48px;
    font-weight: 500;
    font-size: 13px;
    color: ${({ theme }) => theme.main.primaryColor};
    background-color: ${({ theme }) => theme.main.primaryLight};
    border-color: ${({ theme }) => theme.main.primaryLight};
    margin: 30px auto;
    &:hover {
        color: ${({ theme }) => theme.main.primaryColor};
        background-color: ${({ theme }) => theme.main.primaryLight};
        border-color: ${({ theme }) => theme.main.primaryLight};
    }
`;

export const BottomCol = styled.div`
padding: 20px;
border: 1px solid #ededed;
border-radius:10px;
margin-bottom: 15px;
.drop-selected-bar{
    display: flex;
    justify-content: flex-start;
    background-color: #fbfbfb;
    border: 1px solid #f2f2f2;
    padding: 15px 0;
    border-radius: 10px;
    align-items: center;
}
h3{
    text-align: left;
    text-decoration: underline;
    margin-bottom: 15px;
    color:#343d56;
    font-weight: 600;
}
.drop-selected-div{
    border-right: 1px solid #ebebeb;
    padding-right: 20px;
    padding-left: 20px;
    color: rgb(13, 15, 123) !important;
    flex-direction: row;
    display: flex;
    align-items: flex-start;
}
.drop-selected-div-svg{
    padding-right: 5px;
}
.drop-selected-div-txt{
    text-align: left;
}
.drop-selected-div span{
    color: rgb(13, 15, 123) !important;
    font-weight:500;
}
.drop-selected-div h4{
    color: rgb(13, 15, 123) !important;
    font-weight:800;
    margin-bottom: 0;
}
.drop-selected-btn{
    padding-right: 20px;
    padding-left: 20px;
}
.see-offer-btn{
    background-color: #20a478;
    color: #fff;
    border-color: #20a478;
    border-radius:4px;
    padding:0 25px
}
.see-offer-btn > span {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    color: #fff !important;
}
.bottom-col-h3{
    color: rgb(13, 15, 123) !important;
    font-weight:600;
}
@media only screen and (max-width: 780px){
    .drop-selected-bar{
        display: block;
    }
   .drop-selected-div{
    margin-bottom:15px;
    border-right:none  !important
   }
}  
`;
