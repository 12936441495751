/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import LocalStorageService from '../../services/LocalStorageService';

const initialState = {
    user: '',
    isLoggedIn:
        LocalStorageService.getAuthToken() || LocalStorageService.getAuthToken() !== 'undefined'
            ? LocalStorageService.getAuthToken()
            : '',
    docTypes: '',
    createdUser: '',
    isRun : false,
    email:'',
    supplierId:'',
    supplierTitle:'',
    isAdmin:false,
    usersCurrent:''
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
        },
        setDocType(state, action) {
            state.docTypes = action.payload;
        },
        setLoggedIn(state, action) {
            state.isLoggedIn = action.payload;
        },
        setEmail(state, action) {
            state.email = action.payload;
        },
        setCreatedUser(state, action) {
            state.createdUser = action.payload;
        },
        setIsRun(state,action) {
            state.isRun = action.payload;
        },
        setSupplierId(state, action) {
            state.supplierId = action.payload;
        },
        setSupplierTitle(state, action) {
            state.supplierTitle = action.payload;
        },
        setIsAdmin(state, action) {
            state.isAdmin = action.payload;
        },
        setUsersCurrent(state, action) {
            state.usersCurrent = action.payload;
        },
    },
});

export const { setUser, setDocType, setLoggedIn, setCreatedUser, setIsRun, setEmail, setSupplierId, setSupplierTitle, setIsAdmin, setUsersCurrent} = userSlice.actions;
export default userSlice.reducer;
