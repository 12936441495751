import styled from 'styled-components';


export const FinanceOffersBox = styled.div`
    .offer-institution{
        display: flex;
        margin: 30px auto 0px;
        font-weight: 700;
        flex-direction: column;
        max-width:1200px
    }
    .title-msj{
        color: rgb(13, 15, 123) !important;
        text-align: center;
        width: 100%;
        margin: 0 auto;
        font-weight: 800;
        font-size: 17px;
        margin-bottom:15px
    }
    .result-box{
        display:flex;
        border-radius: 8px;
        border:1px solid #f0f0f0;
        background-color: #fff;
        padding: 10px 5px;
        margin-bottom:15px
      
    }
    .result-box div{
        padding: 5px;
        border-right:1px solid #f0f0f0;
        display:flex;
        flex-direction: column;
        text-align: center;
        color:#0c0a7d;
        min-width:198px
    }
    .result-box div svg{
        text-align: center;
        margin: 0 auto;
        font-weight: 900;
    }
    .result-box div:nth-child(3){
        border-right:none
    }
    .result-box div span:nth-child(2){
        font-weight: 800;
        margin-top:10px;
    }
    .result-box div span:nth-child(3){
        font-weight: 500;
    }
    .cancel-offer{
        background-color: #f15932;
        color: #fff !important;
        border-color: #f15932;
        border-radius:4px;
        margin: 0 auto;
        margin-top:20px
    }
    .cancel-offer > span {
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        color: #fff !important;

    }
    @media only screen and (max-width: 640px){
        .result-box{
            display: block;
        }
        .result-box div{
            border-right:none !important
        }
    }
   
`;
