import styled from 'styled-components';

export const BankListBox = styled.div`
   padding:40px 0;
   .page-title{
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #001444;
    margin-bottom: 40px
    }
    .bank-table .ant-table-thead th{
        color: #001444;
        background: rgba(255, 255, 255, 0);
        font-size: 13px;
        font-weight: 700;
        border: solid 1px #e6f1ff;
        border-right: none
    }
    .bank-table  .ant-table-tbody tr td{
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: #001444;
        border: solid 1px #e6f1ff;
    }
    .bank-table   .ant-table-summary td{
        font-weight: 600;
        font-size: 13px;
    }
    .bank-table   .ant-table-summary td:nth-child(1) {
        text-align:center;
    }
    .bank-table  .ant-table-tbody tr:nth-child(2n) td{
        background: #f0f7ff;
    }
    .noti-row {
        width:100%;
        margin-bottom:60px;
        text-align:center;
        font-size: 15px;
        font-weight: 800;
        margin-bottom: 20px;
    }
    .underline{ margin-top: 6px;}
    .buyers-list-table{
        width:100%
    }
    .table-row{
        display:block
    }
    
`;

