/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import { Row, Col, Button, Modal, Form, Input } from 'antd';
import moment from 'moment';
import 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import InputMask from 'react-input-mask';
import Text from '../../../components/Text';

import { uploadInvoices} from '../../../apiServices/supplierFinanceApi';
import { setInvoiceResId} from '../../../store/reducers/supplierFinanceSlice';
import InvoicesDiscountSummary from './InvoicesDiscountSummary';
import { FinanceOffersBox} from './styles';
import { convertFloatDotSeperated } from '../../../utils';
import urls from '../../../routes/urls';
import akbank from '../../../assests/bankLogo/akbank.png';
import denizbank from '../../../assests/bankLogo/denizbank.png';
import dyatirimbank from '../../../assests/bankLogo/Dogan-Yatirim-Bankasi-D-Yatirim-Bankasi-A.S..png';
import denizfactoring from '../../../assests/bankLogo/DenizFaktoringLogo.png';


function FinanceOffers() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [formIban] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [isModalEntry , setIsModalEntry] = useState(false);
    const [invoiceDataArray,setInvoiceDataArray] = useState([]); 
    const [supplierAverageCreditDayArray,setSupplierAverageCreditDayArray] = useState(0);
    const [financialInstitutionsArray,setFinancialInstitutionsArray] = useState([]); 
    const [ibanNo, setIbanNo] = useState('');
    const [isModalOk , setIsModalOK] = useState(false);
    const {  financialList, totalInvoice, averageMaturitys,selectInvoices, invoices, selectbuyers, risks } = useSelector((state) => state.supplierFinance);
    const { user, isLoggedIn } = useSelector((state) => state.user);

    const cancelOffer = () => {
        history.push(urls.supplierFinancing);
    }

     const handleUploadInvoice = async (financialId, financialName,supplierAverageCreditDay, financialTaxId) => {
        const invoiceData = selectInvoices.map((item) =>
        (
            {
                id : item.id,
                date :  moment.tz(item.invoiceDate, 'Europe/Istanbul').format(),
                fileName : item.fileName,
                number : item.invoiceNumber,
                total : item.invoiceTotal,
                currency :item.currency,
                term : moment.tz(item.invoiceTerm, 'Europe/Istanbul').format(), 
                isApproved : item.isApproved,
                createdDate : moment.tz(item.createdDate, 'Europe/Istanbul').format(),
                hashCode : item.hashCode,
                invoiceType: item.invoiceType,
            }
        )
     ) 

        if(financialTaxId === '2651554884'){
            setIsModalEntry(true);
            setInvoiceDataArray(invoiceData)
            setSupplierAverageCreditDayArray(supplierAverageCreditDay || 0);
            setFinancialInstitutionsArray(
              {
                id: financialId,
                name: financialName,
              },
            )
        }else{

        try {
            setLoading(true);
            const response = await uploadInvoices(
                {
                    supplierId: user.id,
                    buyer : {
                        id: invoices[0].buyerId,
                        title :  selectbuyers.buyerTitle,
                        taxId : selectbuyers.buyerTaxNumber,
                    },
                    averageMaturity: supplierAverageCreditDay || 0,
                    invoices : invoiceData,
                    financialInstitutions: [
                        {
                            id: financialId,
                            name: financialName,
                        },
                    ],
                },
                isLoggedIn
            );
           
            if (response) {
                setLoading(false);
                dispatch(setInvoiceResId(response.applicationNumber));
                history.push(urls.getCreatedInvoiceResultPath(user.id));
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }
      } 
    };

    const onFinishConfirmation = (values) => {
        if(values){
          setIbanNo(values);
          setIsModalOK(true)
        }
      };
  
      const ibanModalClose = () => {
        formIban.resetFields();
        setIsModalEntry(false)
      }
  
      const handleOk = async () => {
        setIsModalOK(false);
        setIsModalEntry(false);
        formIban.resetFields();
        try {
          setLoading(true);
          const response = await uploadInvoices(
              {
                  supplierId: user.id,
                  buyer : {
                      id: invoices[0].buyerId,
                      title :  selectbuyers.buyerTitle,
                      taxId : selectbuyers.buyerTaxNumber,
                  },
                  averageMaturity: supplierAverageCreditDayArray,
                  invoices : invoiceDataArray,
                  financialInstitutions: [financialInstitutionsArray],
                  iban:(ibanNo.iban).replace(/TR/g, '').replace(/\s+/g, ''),
              },
              isLoggedIn
          );
          if (response) {
              setLoading(false);
              dispatch(setInvoiceResId(response.applicationNumber));
              history.push(urls.getCreatedInvoiceResultPath(user.id));
          } else {
              setLoading(false);
          }
        } catch (e) {
            setLoading(false);
        }
      };
    
      const handleCancel = () => {
        setIsModalOK(false);
      };
      const getLogo = (vkn, name) => {
        let content;
        if (vkn=== '0150015264') {
            content = <img src={akbank} alt="akbank" width="160px" /> 
          } 
          else if(vkn=== '2920084496'){
            content = <img src={denizbank} alt="akbank" width="160px" /> 
          }else if(vkn=== '2651554884'){
            content = <img src={dyatirimbank} alt="akbank" width="160px" /> 
          }else if(vkn=== '2910141668'){
            content = <img src={denizfactoring} alt="akbank" width="160px" /> 
          } else{
            content = <h3>{name}</h3> 
          }
          return content
      }

    return (
       <FinanceOffersBox>
          <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Col xl={24}>
                    <Text className='page-title'>Finans Kurumu Teklifleri</Text>
                </Col>
            </Row>
            {
                financialList.length > 0 ?
                (
                        <>
                            <Row>
                                <Col style={{margin:'0 auto', alignItems:'center'}}>
                                <div className='offer-institution' >
                                    <h2 className='title-msj'>Seçmiş Olduğunuz Faturaların Özeti:</h2>
                                    <div className='result-box'>
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 384 512"
                                                width="50"
                                                height="50"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM112 256l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64l160 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-160 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                            <span>Fatura Adedi</span>
                                            <span>{(selectInvoices && selectInvoices.length) || 0}</span>
                                        </div>
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                width="50"
                                                height="50"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                            <span>Ortalama Vade</span>
                                            <span>{averageMaturitys[0].averageMaturity} Gün</span>
                                        </div>
                                        <div>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 384 512"
                                                width="50"
                                                height="50"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M96 32c17.7 0 32 14.3 32 32l0 35.3L247.2 65.2c17-4.9 34.7 5 39.6 22s-5 34.7-22 39.6L128 165.9l0 29.4 119.2-34.1c17-4.9 34.7 5 39.6 22s-5 34.7-22 39.6L128 261.9 128 416l63.8 0c68.2 0 124.4-53.5 127.8-121.6l.4-8c.9-17.7 15.9-31.2 33.6-30.4s31.2 15.9 30.4 33.6l-.4 8C378.5 399.8 294.1 480 191.8 480L96 480c-17.7 0-32-14.3-32-32l0-167.9-23.2 6.6c-17 4.9-34.7-5-39.6-22s5-34.7 22-39.6L64 213.6l0-29.4-23.2 6.6c-17 4.9-34.7-5-39.6-22s5-34.7 22-39.6L64 117.6 64 64c0-17.7 14.3-32 32-32z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                            <span>Fatura Toplamı</span>
                                            <span>{convertFloatDotSeperated(totalInvoice)} TL</span>
                                        </div>
                                    </div>
                                </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{margin:'0 auto', alignItems:'center'}} >
                                {
                                    financialList.map(item => (
                                        <InvoicesDiscountSummary key={item.id}
                                            onChange={() => handleUploadInvoice(item.financialId, item.financialName, item.supplierAverageCreditDay, item.financialTaxId)}
                                            invoiceCalculate={{
                                                financialName: item.financialName,
                                                financialId: item.financialId,
                                                financialTaxId:item.financialTaxId,
                                                invoiceCount: 0,
                                                invoiceTotal: item.total,
                                                supplierAverageCreditDay: item.supplierAverageCreditDay || 0,
                                                price: item.price,
                                                supplierLimit: item.supplierLimit,
                                                supplierRisk: item.supplierRisk,
                                                logo: getLogo(item.financialTaxId,item.financialName),
                                            }}
                                        />
                                    ))
                                }
                                </Col>
                            </Row>
                            <Row>
                                <Button onClick={cancelOffer} className='cancel-offer'>Teklif Talebini İptal Et</Button>
                            </Row>
                        </>
                ):
                (
                    <>
                        <p>Bir hata oluştu seçili fatura bulunamadı. </p>
                        <Button onClick={cancelOffer}>Yüklenen Faturalara Geri Dön</Button>
                    </>
                )
            }
          
            <Modal
            open={isModalEntry}
            className='modal-iban'
            footer={false}
            closable={false}
            >
            <h3>Başvuru Ödemesi Yapılacak IBAN</h3>
            <p>Lütfen başvurunuzun D Yatırım Bankası tarafından onaylanması durumunda, ödemenizin yapılmasını istediğiniz IBAN numarasını giriniz.</p>
            <Form
              form={formIban}
              name="iban_form"
              layout="vertical"
              onFinish={onFinishConfirmation}
            >
              <Form.Item
                name="iban"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen IBAN numaranızı giriniz!',
                  },
                  {
                    pattern: /^TR\d{2} \d{4} \d{4} \d{4} \d{4} \d{4} \d{0,2}$/,
                    message: 'Geçerli bir IBAN numarası giriniz!',
                  },
                ]}
              >
                <InputMask
                  mask="TR99 9999 9999 9999 9999 9999 99"
                >
                  {() => <Input placeholder="TR00 0000 0000 0000 0000 0000 00" />}
                </InputMask>
              </Form.Item>
              <div style={{display:'flex'}} className='iban-modal-btn'>
                <Form.Item className='iban-modal-btn-xx'>
                  <Button className='close-btn'  onClick={ibanModalClose} style={{color:'#000 !important'}} >
                    İptal
                  </Button>
                </Form.Item>
                <Form.Item  className='iban-modal-btn-xx'>
                <Button className='submit-btn'  type="primary" htmlType="submit">
                  Onayla
                </Button>
              </Form.Item>
              </div>
              
          </Form>
        </Modal>
        <Modal
            open={isModalOk}
            className='modal-iban modal-iban-confirmation'
            closable={false}
            onOk={handleOk} 
            onCancel={handleCancel}
            >
            <p> Girmiş olduğunuz IBAN numarası <br/> <strong>{ibanNo.iban} </strong> olarak D Yatırım Bankası'na iletilecektir. Onaylıyor musunuz?</p>
           
        </Modal>
       </FinanceOffersBox>
    );
}

export default FinanceOffers;
