/* eslint-disable no-empty */
import { endpoints, apiV3 } from '../services/apis';

const fetchProvinces = async () => {
    try {
        const data = await apiV3.get(endpoints.provinces);
        return data;
    } catch (error) {console.log(error)}
};

const fetchCompanies = async () => {
    try {
        const data = await apiV3.get(endpoints.companies);
        return data;
    } catch (error) {console.log(error)}
};

const fetchDistricts = async (id) => {
    try {
        const { data } = await apiV3.get(endpoints.getDistrictsEndpoint(id));
        const { districts } = data;
      
        return districts;
    } catch (error) {console.log('getDistrictsEndpoint', error)}
};

const fetchTaxOffices = async (id) => {
    try {
        const data = await apiV3.get(endpoints.getTaxOfficesEndpoint(id));
        return data;
    } catch (error) {console.log(error)}
};

export { fetchProvinces, fetchDistricts, fetchTaxOffices,fetchCompanies };
