import styled from 'styled-components';

export const TextBox = styled.div`
   padding:30px 0;
   font-size:12px;
   height: 500px;
   padding-right:20px;
   overflow-y: scroll;
   .title-center{
      text-align:center
   }
   ul{
      margin-top:5px;
      margin-bottom:15px
   }
   ol{
      margin-left:0;
      padding-left: 20px;
   }
   table td{
      border:1px solid;
      padding:4px
   }
   .top-li {
      margin-top:15px
    }
   li::marker{
      font-weight:600;
    }
    span{
      font-weight:600;
      margin-bottom:10px

    }
`;

