import { createGlobalStyle } from 'styled-components';

/* - INDEX -
 * COLOR
 * SPACING
 * TEXT
 */

export default createGlobalStyle`
body {
  margin: 0;
  font-family: 'Montserrat'!important;
  font-size: 13px;
}
  // COLOR
  .primary{
    color: ${({ theme }) => theme.main.primaryColor}
  }
  .secondary{
    color: ${({ theme }) => theme.main.secondaryColor}
  }

  .smoke-background{
    background: ${({ theme }) => theme.main.smokeBackgroundColor}
  }

  .shadow{
    box-shadow: ${({ theme }) => theme.main.shadow};
  }
  .shadow-hover{
    box-shadow: ${({ theme }) => theme.main.shadowHover};
  }

  .opacity-50{
    opacity: 0.5;
  }
  .opacity-40{
    opacity: 0.4;
  }

  .cursor-pointer{
    cursor: pointer;
  }
  
  .hover-scale{
    transition: transform ${({ theme }) => theme.main.defaultAnimation};
    
    &:hover{
      transform: scale(1.05);
    }
  }

  .overflow-visible{
    overflow: visible;
  }


  // SPACING
  .w-100{
    width: 100%;
  }
  .limited-width{
    max-width: ${({ theme }) => theme.main.limitedWidth};
  }
  .h-100{
    height: 100%;
  }
  .d-block{
    display: block;
  }
  .d-inline{
    display: inline;
  }

  .m{
    margin: ${({ theme }) => theme.main.margin};
  }
  .m-0{
    margin: 0px;
  }
  .mx{
    margin-right: ${({ theme }) => theme.main.margin};
    margin-left: ${({ theme }) => theme.main.margin};
  }
  .ml{
    margin-left: ${({ theme }) => theme.main.margin};
  }
  .mr{
    margin-right: ${({ theme }) => theme.main.margin};
  }
  .my{
    margin-top: ${({ theme }) => theme.main.margin};
    margin-bottom: ${({ theme }) => theme.main.margin};
  }
  .mt{
    margin-top: ${({ theme }) => theme.main.margin};
  }
  .mb{
    margin-bottom: ${({ theme }) => theme.main.margin};
  }
  .m-big{
    margin: ${({ theme }) => theme.main.marginBig};
  }
  .mx-big{
    margin-right: ${({ theme }) => theme.main.marginBig};
    margin-left: ${({ theme }) => theme.main.marginBig};
  }
  .mr-big{
    margin-right: ${({ theme }) => theme.main.marginBig};
  }
  .ml-big{
    margin-left: ${({ theme }) => theme.main.marginBig};
  }
  .my-big{
    margin-top: ${({ theme }) => theme.main.marginBig};
    margin-bottom: ${({ theme }) => theme.main.marginBig};
  }
  .mb-big{
    margin-bottom: ${({ theme }) => theme.main.marginBig};
  }
  .mt-big{
    margin-top: ${({ theme }) => theme.main.marginBig};
  }

  .p{
    padding: ${({ theme }) => theme.main.padding};
  }
  .p-0{
    padding: 0px;
  }
  .px{
    padding-right: ${({ theme }) => theme.main.padding};
    padding-left: ${({ theme }) => theme.main.padding};
  }
  .pl{
    padding-left: ${({ theme }) => theme.main.padding};
  }
  .pr{
    padding-right: ${({ theme }) => theme.main.padding};
  }
  .py{
    padding-top: ${({ theme }) => theme.main.padding};
    padding-bottom: ${({ theme }) => theme.main.padding};
  }
  .pt{
    padding-top: ${({ theme }) => theme.main.padding};
  }
  .pb{
    padding-bottom: ${({ theme }) => theme.main.padding};
  }
  .p-big{
    padding: ${({ theme }) => theme.main.paddingBig};
  }
  .px-big{
    padding-right: ${({ theme }) => theme.main.paddingBig};
    padding-left: ${({ theme }) => theme.main.paddingBig};
  }
  .pl-big{
    padding-left: ${({ theme }) => theme.main.paddingBig};
  }
  .pr-big{
    padding-right: ${({ theme }) => theme.main.paddingBig};
  }
  .py-big{
    padding-top: ${({ theme }) => theme.main.paddingBig};
    padding-bottom: ${({ theme }) => theme.main.paddingBig};
  }
  .pb-big{
    padding-bottom: ${({ theme }) => theme.main.paddingBig};
  }
  .pt-big{
    padding-top: ${({ theme }) => theme.main.paddingBig};
  }

  // TEXT
  .bold{
    font-weight: bold;
  }
  .light{
    color: ${({ theme }) => theme.main.light};
  }
  .dark{
    color: ${({ theme }) => theme.main.dark};
  }
  .text-center{
    text-align: center;
  }
  .text-r{
    text-align: right;
  }
  .text-l{
    text-align: left;
  }

  // ANTD
  .ant-input-number-handler-wrap {
    display: none;
  }

  // REACT-PDF
  .annotationLayer {
    display: none;
  }
  .google-btn {
    width: 100%;
  }

  .status-title {
    display: block;
    font-weight: 500;
    font-size: 20px;
  }

  .item-title {
    display: block;
    font-weight: 400;
    font-size: 18px;
  }

  .font-18 {
    font-size: 18px !important;
  }

  .invoiceTable .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link
  {
    border: 0px;
  }

  .signup-popup .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .legal-doc .ant-upload {
    width: max-content !important;
    padding: 0px 15px;
    background-color: #e6f1ff;
  }
  
  .legal-doc-download {
    width: max-content !important;
    padding: 0px 15px;
    background-color: #e6f1ff;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
  }

  .legal-doc .ant-upload-select {
    height: 46px !important;
  }

  .temlik-upload .ant-upload-select {
    width: max-content !important;
    height: 46px !important;
    padding: 0px 20px;
    background-color: #e6f1ff;
    border: 1px dashed #d9d9d9;
  }

 
  .hint-modal-btn {
    height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 56px;
    border-radius: 8px;
    margin-top: 30px;
  }

  .skip-btn {
    width: 79px;
    background-color: #e9f3ff;
    color: #000;
  }

  .start-btn {
    width: 116px;
    background-color: #0d0f7b;
    color: #fff;
    margin-left: 20px;
  }

  .hint-modal-btn:hover, .hint-modal-btn:focus, .hint-modal-btn:active {
    height: 40px;
  }

  .react-joyride__tooltip {
    width: 480px !important;
  }

  .react-joyride__tooltip div:first-child{
      text-align: left !important;
  }

  .react-joyride__tooltip div:first-child div{
      padding: 0px 5px 0px!important;
  }

  .react-joyride__beacon span {
      background-color: #0d0f7b !important ;
      border-color: #0d0f7b !important ;
  }
  .limited-width .ant-table-cell{ cursor: pointer}
  .cursor-pointer tr{ cursor:pointer}
  .email-input input {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 16px 12px;
    border-radius: 8px;
    font-size: 15px;
}
.email-input .ant-form-item{
  margin-bottom: 15px !important;
}

.update-input  .ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
  border-radius: 8px;
  height: 46px;
}
.update-input  .ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  align-items: center;
}


.menu-style .ant-dropdown-menu-title-content > a{
  color:#0d0f7b;
}
.financial-list-row{
  position: fixed;
  bottom: 0;
  background: #fff;
  z-index: 999;
  max-width: 1200px;
  width: 1200px;
}
@media only screen and (max-width: 1250px) {
  .financial-list-row {
    overflow-x: auto;
    padding: 0 2px;
    max-width: calc(100% - 50px);
  }
}
.page-title {
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
  font-weight: 600 !important;
  color: rgb(13, 15, 123) !important;
  font-size: 17px !important;
}
.page-title::before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 5px;
  width: 55px;
  background-color: rgb(13, 15, 123);
}
.page-title::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 2px;
  height: 1px;
  width: 100%;
  max-width: 255px;
  background-color: rgb(13, 15, 123);
}
.ant-table.ant-table-middle {
  font-size: 13px;
}
.ant-table {
  font-size: 13px;
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background: none;
  border: none ;
  border-bottom: 0;
  border-radius: 2px;
}
.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border: none;
}
.ant-collapse > .ant-collapse-item {
  border: none;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
}
.ant-collapse-content > .ant-collapse-content-box a{
  margin-bottom: 10px;
  font-weight: 500;
    font-size: 13px !important;
    color: rgb(13, 15, 123) !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
  flex: auto;
  font-weight: 500;
  font-size: 14px !important;
  color: rgb(13, 15, 123) !important;
}
.mobil-menu .ant-btn.ant-btn-block {
  width: 100%;
  font-weight: 500;
  font-size: 14px !important;
  color: rgb(13, 15, 123) !important;
  border: none !important;
  margin-bottom: 5px;
  box-shadow: none;
}
.ant-btn > span {
  display: inline-block;
  font-weight: 500;
  font-size: 14px ;
  color: rgb(13, 15, 123) ;
}
 .mobil-menu .ant-btn{
  border:none !important;
  box-shadow: none;
}
.menu-toggle {
  position: absolute;
  top: 2.5em;
  right: 1em;
  cursor: pointer;
  display: none;
  border: none;
}
.header-menu button {
  background: rgb(255, 255, 255) !important;
  border: 0px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
  font-weight: 600 !important;
  margin-right: 40px !important;
  color: rgb(13, 15, 123) !important;
  user-select: none !important;
  font-size: 14px !important;
}
.hamburger{
  cursor: pointer;
}
.hamburger{
  
  display: block !important;
  background: #0d0f7b;
  height: 3px;
  width: 1.75em;
  border-radius: 2px;
  transition: all ease-in-out 500ms;
  cursor: pointer;
 
}

.hamburger::after {
  position:absolute;
  content:'';
  display: block;
  background: #0d0f7b;
  height: 3px;
  width: 1.75em;
  border-radius: 2px;
  transition: all ease-in-out 500ms;
  cursor: pointer;
  top: 4px;
}
.hamburger::before {
  position:absolute;
  content:'';
  display: block;
  background: #0d0f7b;
  height: 3px;
  width: 1.75em;
  border-radius: 2px;
  transition: all ease-in-out 500ms;
  cursor: pointer;
  top: 9px;
}
.hamburger::before {
  transform: translateY(-7px);
}

.hamburger::after {
  transform: translateY(4px);
}


.open .hamburger {
  transform: rotate(45deg);
  background: #0d0f7b;
}

.open .hamburger::before {
  opacity: 0;            
}

.open .hamburger::after {
  transform: translateY(-3px) rotate(-90deg);
  background: #0d0f7b;
}
.mobil-buyer-col{
  align-items: center;
    display: flex;
    align-content: center;
    justify-content: space-between;
    gap: 13px;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-bottom: 20px;
}
.mobil-buyer-title{
  display: flex;
  flex-direction: column;
}
.mobil-buyer-title span{
  font-weight: 600;
  font-size: 12px !important;
  color: rgb(13,15,123) !important;
}
.mobil-buyer-col .ant-btn > span{
  font-size: 12px !important;
}
.mobil-userName {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
  color: rgb(13, 15, 123) !important;
  margin-bottom: 20px;
}
.mobil-userName .left{
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 800;
 
}
.mobil-userName p{
 margin-bottom: 0;
 color: rgb(13, 15, 123) !important;
 font-size: 14px;
    font-weight: 500;
}
.mobil-user{
  font-size:12px  !important;
  color:red !important;
}
.apply-box span{
  color:#fff;
  font-size:13px
}
.ant-btn-primary span{
  color:#fff;
  font-size:13px
}
.left-btn span{
  color:#fff;
  font-size:13px;
  width: auto;
}

@media screen and (max-width: 1300px)
{
  .menu-toggle {
    display: block;
  }
  .menu-draw{
    display: none;
  }
  .ant-pagination-item{
    min-width: 4px;
  }
  .ant-col-xs-0 {
    display: none;
}

  main{
    padding-left:25px;
    padding-right:25px;
    min-width: 100% !important;
    width: 100%;
  }

}
.ant-btn{
  font-size:13px
}
.modal-login-logout .ant-modal-close-x{
  display: none !important;
}
.modal-login-logout  .ant-btn{
  color:#fff !important;
}
.modal-login-logout .ant-modal-footer{
  text-align: center;
    border-top: none;
}
.modal-login-logout .ant-modal-body {
  padding-bottom: 10px;
}
.modal-login-logout .ant-modal-content {
  border-radius: 6px;
}
.modal-login-logout  .ant-btn > span {
  color: #fff !important;
}

.iban-modal-btn{
  display: flex;
    justify-content: center;
    gap: 20px;
    margin-top:30px
}

.iban-modal-btn .submit-btn{
  min-width: 140px;
    border-radius: 6px;
    color:#fff !important;
    background-color: #0d0f7b;
    border-color: #0d0f7b;
}

.iban-modal-btn .close-btn{
  min-width: 140px;
    border-radius: 6px;
    color:#000 !important;
    background-color: #fff !important;
  border-color:#000;
}
.iban-modal-btn .close-btn span{
    color:#000 !important;
}
.modal-iban .ant-btn-primary{
  min-width: 140px;
    border-radius: 6px;
    color:#fff !important;
    background-color: #0d0f7b;
    border-color: #0d0f7b;
}
.modal-iban .ant-btn-default{
  min-width: 140px;
    border-radius: 6px;
    color:#000 !important;
    background-color: #fff !important;
  border-color:#000;
}
.iban-modal-btn-xx {
  margin-bottom: 0 !important;
}
.modal-iban h3{
  text-align: center;
    font-weight: 800;
}
.modal-iban-confirmation .ant-modal-body{
  padding-bottom: 0 !important;
}
.modal-iban-confirmation .ant-modal-footer{
  text-align: center;
}
.contract-modal {
  margin-bottom:15px;
  margin-top:15px
}

.contract-modal-style  {
  width: 800px !important;
  heigth:500px
}

.contract-modal-style .ant-modal-body{
  padding-right: 5px !important;
}

.contract-modal-style .ant-tabs-nav{
  margin: 0 auto;
}

.contract-modal button{
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding: 0 4px;
  border: none;
  height: auto !important;
}

.contract-modal button span{
  text-decoration: underline;
}
.register-btn span{
  color:#000 !important 
}
.sign-up-btn .ant-btn:hover, .sign-up-btn .ant-btn:focus{
  color:#fff !important;
}
.sign-up-btn span:hover {
  color:#fff !important; 
}
.mail-modal .ant-modal-body{
  text-align: center;
}
.mail-modal .ant-modal-body p{
  margin-bottom: 0;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: fixed;
  top: 40%;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text{
  font-weight: 700;
    font-size: 16px;
    letter-spacing: 2px;
}
.ant-btn-primary[disabled]{
  color: #000;
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}

.default-form .ant-form-item{
  margin-bottom: 15px;
}
.default-form .ant-input{
  border-radius: 6px;
  padding: 6px 11px !important;
}
.default-form .ant-input-affix-wrapper{
  padding-left: 0 !important;
  padding: 6px 11px !important;
  border-radius: 6px;
}
.default-form .ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  padding: 4px 0px !important;

.adisabled  {
  color: gray;
  cursor: not-allowed !important;
}
.modal-iban .ant-input{
  border-radius: 9px;
  padding: 6px 11px;
}
.drop-selected-bar{
  display: flex;
  justify-content: space-between;
}
.bottom-col{
  padding: 20px;
  border: 1px solid #ededed;
  border-radius:10px;
  margin-bottom: 15px;
}

`;
