import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Select, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../../components/Text';
import { oneColLayout } from '../../../utils';
import { fetchTaxOffices, fetchProvinces, fetchCompanies } from '../../../apiServices/commonApi';
import { setDistricts, setTaxOffices, setProvinces,setCompanies } from '../../../store/reducers/commonSlice';


const { Option } = Select;

function CompanyInfo() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [showTaxOffice, setShowTaxOffice] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const { user } = useSelector((state) => state.user);
    const { districts, provinces, taxOffices, companies } = useSelector((state) => state.common);
     

    const onChangeProvince = async () => {
        const _province = provinces?.find((province) => province.provinceId === user?.province);
        if (_province) {
            dispatch(setDistricts(_province.districts));
            const response = await fetchTaxOffices(_province.provinceId);
            if (response) {
                dispatch(setTaxOffices(response));
            }
        }
    };

    const getProvinces = async () => {
        setLoadingData(true);
        const response = await fetchProvinces();
        if (response) {
            dispatch(setProvinces(response));
            setLoadingData(false);
        }
    };

    const getCompanies = async () => {
        const response = await fetchCompanies();
        if (response) {
            dispatch(setCompanies(response));
        }  
    };


    useEffect(() => {
        getProvinces();    
        getCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (provinces?.length > 0) {
            onChangeProvince();
            setShowTaxOffice(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provinces, user]);
   

    return (
        <Row>
            <Col {...oneColLayout}>
                <div className="mb" style={{marginBottom:'20px'}}>
                    <Text type="title" bold className='page-title'> 
                        Firma Bilgileri
                    </Text>
                    <Text>Firmanıza ait bilgileri görüntüleyin.</Text>
                </div>

                <Form form={form} name="accountSettings">
                    <Form.Item name="taxId">
                        <Input disabled placeholder={user?.taxId} />
                    </Form.Item>
                    <Form.Item name="companyType">
                        <Select 
                            disabled 
                            placeholder={user?.companyType}>
                            {companies.map((company) => (
                                <Option key={`bank-option-${company.name}`} value={company.name}>
                                    {company.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item name="province">
                        <Select
                            disabled
                            placeholder={user?.province}
                            loading={loadingData}
                            onChange={onChangeProvince}
                            optionFilterProp="children"
                            showSearch>
                            {provinces.map((provnce) => (
                                <Option key={`province-${provnce.name}`} value={provnce.name}>
                                    {provnce.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {showTaxOffice && (
                        <Form.Item name="district">
                            <Select 
                                disabled 
                                placeholder={user?.district}
                                optionFilterProp="children" showSearch>
                                {districts.map((district, id) => (
                                    <Option key={`district-${id}`} value={district}>
                                        {district}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    {showTaxOffice && (
                        <Form.Item name="taxAdministration">
                            <Select 
                                disabled 
                                placeholder={user?.taxAdministration} 
                                optionFilterProp="children" showSearch>
                                {taxOffices.map((taxOfc, id) => (
                                    <Option key={`tax-Administration-${id}`} value={taxOfc.name}>
                                        {taxOfc.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    <Form.Item name="title" >
                        <Input disabled placeholder={user?.title} />
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
export default CompanyInfo;
