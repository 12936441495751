import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import Login from '../pages/Login';
import urls from './urls';
import SupplierFinancingList from '../pages/SupplierFinancing/SupplierFinancingList';
import FundList from '../pages/FundList';
import FundDetails from '../pages/FundDetails';
import GeneralInfo from '../pages/Account/GeneralInfo';
import CompanyInfo from '../pages/Account/CompanyInfo';
import ChangePassword from '../pages/Account/ChangePassword';
import TwoFactor from '../pages/Account/TwoFactor';
import PasswordNew from '../pages/Account/PasswordNew';
import InvoiceResult from '../pages/SupplierFinancing/InvoiceResult';
import LateInvoices from '../pages/LateInvoices';
import InvoicesPendingPayment from '../pages/InvoicesPendingPayment';
import PaidInvoice from '../pages/PaidInvoice';
import LegalInfo from '../pages/Account/LegalInfo';
import Buyers from '../pages/Buyers';
import PasswordReset from '../pages/Login/PasswordReset';
import PasswordUpdate from '../pages/Login/PaswordUpdate'
import BankRiskLimit from '../pages/BankRiskLimit';
import NewUser from '../pages/Users/NewUser';
import UserTracing from '../pages/Users/UserTracing';
import LocalStorageService from '../services/LocalStorageService';
import FinanceOffers from '../pages/SupplierFinancing/FinanceOffers';
 
function ProtectedRoute({ ...props }) {
    const { isLoggedIn } = useSelector(({ user }) => user);
    return isLoggedIn ? <Route {...props} /> : <Redirect to={urls.login}/>  ;
}

function TwoFactorRoute({ ...props }) {
    const { email } = useSelector(({ user }) => user);
    return email ? <Route {...props} /> : <Redirect to={urls.login}/>  ;
}

function GuestRoute({ ...props }) {
    const { isLoggedIn } = useSelector(({ user }) => user);
    const isLogout = JSON.parse(LocalStorageService.getIsLogout()) ;
    const routing = isLogout ?  <Redirect to={urls.login}/>  : <Redirect to={urls.buyers}/>
    return !isLoggedIn  ? <Route {...props} /> :  routing ;
}
function Routes() {
    const location = useLocation();
    useEffect(() => {
        setTimeout(() => window.scroll({ top: 0, behavior: 'smooth' }));
    }, [location]);

    return (
        <Switch>
            <GuestRoute path={urls.login} exact component={Login} />
            <GuestRoute path={urls.passwordReset} exact  component={PasswordReset} />

            <GuestRoute path={urls.passwordChange} exact component={ChangePassword} />
            <GuestRoute path={urls.passwordNew}  component={PasswordNew} /> 
            <TwoFactorRoute path={urls.twoFactor} exact component={TwoFactor} />
             
            <ProtectedRoute path={urls.changePassword}  component={PasswordUpdate} />
                     
            <ProtectedRoute exact path={urls.supplierFinancing} component={SupplierFinancingList} />
            <ProtectedRoute exact path={urls.financeOffers} component={FinanceOffers} />

            <ProtectedRoute path={urls.fundDetail} component={FundDetails} />
            <ProtectedRoute path={urls.funds} component={FundList} />
            <ProtectedRoute path={urls.generalInfo} exact component={GeneralInfo} />
            <ProtectedRoute path={urls.companyInfo} exact component={CompanyInfo} />
           
            <ProtectedRoute path={urls.legalDocuments} exact component={LegalInfo} />
            <ProtectedRoute path={urls.createdInvoiceResult} exact component={InvoiceResult} />
            <ProtectedRoute path={urls.lateInvoices} exact component={LateInvoices} />
            <ProtectedRoute path={urls.invoicesPendingPayment} exact component={InvoicesPendingPayment} />
            <ProtectedRoute path={urls.paidInvoices} exact component={PaidInvoice} />
            <ProtectedRoute path={urls.buyers} exact component={Buyers} /> 
            <ProtectedRoute path={urls.bankRiskLimit} exact component={BankRiskLimit} />  

             <ProtectedRoute path={urls.newUser} exact component={NewUser} />  
             <ProtectedRoute path={urls.userTracing} exact component={UserTracing} />         
        </Switch>
    );
}

export default Routes;
