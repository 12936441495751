import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../components/Text';
import { getLateInvoices } from '../../apiServices/fundApi';
import { setDiscountInvoices } from '../../store/reducers/fundSlice';
import { convertFloatDotSeperated } from '../../utils';


function LateInvoices() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => state.user);
    const { discountInvoices } = useSelector((state) => state.fund);
    const [pSize, setPsize] = useState(10);
    const [pNumber, setpNumber] = useState(1);
    const [totalDataCount, setTotalDataCount] = useState(1);

    // const getInvoiceAssigned = async () => {
    //     try {
    //         setLoading(true);
    //         const response = await getInvoicesAssigned(user.id);          
    //         if (response) {
    //             setLoading(false);
    //             dispatch(setDiscountInvoices(response.invoices));
    //         } else {
    //             setLoading(false);
    //         }
    //     } catch (e) {
    //         setLoading(false);
    //     }
    // };

    const getLateInvoice = async () => {
        try {
            setLoading(true);
            const response = await getLateInvoices(user.id, pNumber, pSize);   
        
            if (response) {
                setLoading(false);
                dispatch(setDiscountInvoices(response.invoices.data));
                setTotalDataCount(response.invoices.totalDataCount)
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }
    };

    const handleShowSize = (current, size) => {
        setpNumber(current);
        setPsize(size);
      };

    useEffect(() => {
        user && user.id && getLateInvoice();        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // const onClickFund = (fund) => {
    //     history.push(urls.getfundDetail(fund.id));
    // };

    const tableCols = [
        {
            title: 'Alıcı Ünvanı',
            dataIndex: 'buyerTitle',
            key: 'buyerTitle',
            width: 180,
        },
        {
            title: 'Fatura No',
            dataIndex: 'invoiceNumber',
            key: 'invoiceNumber',
        },
        {
            title: 'Fatura Tarihi',
            dataIndex: 'invoiceDate',
            key: 'invoiceDate',
            render: (date) => moment(date).format('DD-MM-YYYY'),
        },
        {
            title: 'Fatura Tutarı',
            dataIndex: 'invoiceTotal',
            key: 'invoiceTotal',
            render: convertFloatDotSeperated,
        },
        {
            title: 'Fatura Vadesi',
            dataIndex: 'invoiceTerm',
            key: 'invoiceTerm',
            render: (date) => moment(date).format('DD-MM-YYYY'),
        },
        {
            title: 'Alıcı VKN',
            dataIndex: 'buyerTaxNumber',
            key: 'buyerTaxNumber',
        },
        {
            title: 'Temlik Ettiğim Kurum',
            dataIndex: 'financialInstitutionName',
            key: 'financialInstitutionName',
        },
    ];

    // const showTotal = (total) => (
    //     <div style={{display:'flex', justifyContent:'space-between'}}>
    //         <div style={{  color: '#727272', fontSize: 15 }}>
    //             <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Temlik Ettiğim Faturalar</Text>
    //         </div>
    //         <div style={{ textAlign: 'right', color: '#727272', fontSize: 15, marginLeft: 'auto' }}>
    //             Toplam {total}
    //         </div>
    //     </div>
    // );

    return (
        <>
              <Row style={{ marginTop:'20px', marginBottom:'20px'}}>
                <Col xl={24}>
                <Text className='page-title'> Alıcı Ödemesi Geciken Faturalarım</Text>
                </Col>
            </Row>
        
            <Row>
                <Col span={24} >
                    <Table
                        rowKey="invoiceNumber"
                        loading={loading}
                        dataSource={discountInvoices}
                        columns={tableCols}
                        cursorPointer
                        pagination={{
                            position: ['topRight', 'none'],
                            showLessItems: true,                          
                            showSizeChanger: true,
                            current: pNumber,
                            pageSize: pSize,
                            total:totalDataCount,                                                       
                            onChange: handleShowSize
                        }}
                        size="middle"
                    />
                </Col>
            </Row>
        </>
    );
}

export default LateInvoices;
