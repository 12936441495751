import React, {useEffect, useState} from 'react';
import { Row,  Table, Button, notification} from 'antd';
import { useSelector,useDispatch } from 'react-redux';
import {useHistory } from 'react-router-dom';
import { setBuyersList,setBuyerIdentity } from '../../store/reducers/supplierFinanceSlice'
import { BuyersListBox } from './styles';
import { getBuyers,setBuyers } from '../../apiServices/userApi';
import urls from '../../routes/urls';
import LocalStorageService from '../../services/LocalStorageService';

const columns = [
    {
      title: 'Alıcı VKN',
      dataIndex: 'taxId',
      width: 300,
      fixed: 'left',
    },
    {
      title: 'Alıcı Ünvanı',
      dataIndex: 'title',
    }
  ];
 

const Buyers = () => {
 const { user} = useSelector((state) => state.user);
 const { buyerData } = useSelector((state) => state.supplierFinance);
 const [loading, setLoading] = useState(true);
 const [getBuyerData, setGetBuyerData] = useState([]);
 const [title, setTitle] = useState('')
 const dispatch = useDispatch()
 const history = useHistory();

 const rowSelection = {
    onChange:  (selectedRowKeys, selectedRows) => {
        if (selectedRowKeys.length > 0) {
            const buyerIData = selectedRows.reduce((newData, row) => {               
                newData.push({
                    id: row.id,
                    title: row.title,
                });             
                return newData;
            }, []);
            setGetBuyerData(buyerIData[0].id) 
            if(buyerData.length>0){
              setTitle(buyerIData[0].title)
            }
        } 
    },
  };


  const setActiveBuyer = async () => {
    if(getBuyerData.length > 0){
      await setBuyers(user.id, getBuyerData);
      dispatch(setBuyerIdentity(title));
      LocalStorageService.setBuyerUser(title);
      history.push(urls.supplierFinancing);
      setLoading(false);  
    }
    else{
      notification.error({
        message: 'Alıcı seçimi yapmalısınız.',
      });
    }     
  }
  

 
 const getBuyersList = async () => { 

  const suppId = user.id

    try {      
        const data =  await getBuyers(suppId);       
        dispatch(setBuyersList(data.buyers))
        setLoading(false);
      } 
    catch (e) { console.log(e) }
  }
  
  
 useEffect(() => {
if(user){
  getBuyersList() 
}       
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [user]);

    return (
        <BuyersListBox>
           <Row>
            <h3 className='noti-row'>Lütfen işlem yapmak istediğiniz alıcıyı seçiniz..</h3>
           </Row>
           <Row className='table-row'>
            <div>
                
                <Table
                    rowSelection={{
                    type: 'radio',
                    ...rowSelection,
                    className:'buyers-list-table'
                    }}
                    columns={columns}
                    dataSource={buyerData}
                    loading={loading} 
                    rowKey="id"
                    scroll={{
                        x: 800,
                      }}
                />
            </div>
           </Row>
           <Row>
            <Button  type="primary" onClick={setActiveBuyer} loading={loading} >Seçili Alıcı ile Devam et</Button>
           </Row>
        </BuyersListBox>
    );
};



export default Buyers;