/* eslint-disable no-empty */
import { notification } from 'antd';
import { endpoints, apiV1 } from '../services/apis';

const fetchDiscountInvoices = async () => {
    try {
        const data = await apiV1.get(endpoints.uploadInvoices);
     
        return data;
    } catch (error) {
        console.log(error);
    }
};

const getImportTemplate = async (id) => {
    try {
        const data = await apiV1.get(endpoints.importTemplate(id));
        return data;
    } catch (error) {
        console.log(error);
    }
};

const getDiscountBuyerInvoices = async (id,pSize, pNumber) => {  
    try {
        const data = await apiV1.get(`suppliers/${id}/discounts?pageNumber=${pNumber}&pageSize=${pSize}`);
       
        return data;
    } catch (error) {
         console.log(error);
    }
};

const fetchDiscountInvoiceById = async (discountId) => {
    try {
        const data = await apiV1.get(`discounts/${discountId}`);
        return data;
    } catch(e) {
        console.log(e)
        return [];
    }
};

const fetchInvoiceOffer = async (id) => {
    try {
        const data = await apiV1.get(`discounts/${id}/offer`);
        return data;
    } catch(e) {
        console.log(e)
        return [];
    }
};

const acceptInvoiceOffer = async ( disId, financeId, payload) => {
    try {
        const data = await apiV1.patch(
            `/discounts/${disId}/financial-institution/${financeId}/accept`,
            payload
        );
        notification.success({
            message:
                'Onayınız finans kurumuna bildirildi. İşlemi tamamlamak için sizin için ürettiğimiz temliknameyi bastırıp kaşeli ve imzalı olarak en yakın şubeye götürebilirsiniz',
        });
        return data;
    } catch(e) {
        console.log(e)
        return [];
    }
};

// const getInvoicesAssigned = async (supplierId) => {
//     try {
//         const data = await apiV1.get(`suppliers/${supplierId}/invoices/assigned`);
//         return data;
//     } catch(e) {
//         console.log(e)
//         return [];
//     }
// };

const getLateInvoices = async (supplierId,pNumber,pSize) => {
    try {
        const data = await apiV1.get(`suppliers/${supplierId}/invoices/unpaid?pageNumber=${pNumber}&pageSize=${pSize}`);
        return data;
    } catch(e) {
        console.log(e)
        return [];
    }
};

const getInvoicesPending = async (supplierId,pNumber,pSize) => {
    try {
        const data = await apiV1.get(`suppliers/${supplierId}/invoices/payment-waiting?pageNumber=${pNumber}&pageSize=${pSize}`);
        return data;
    } catch(e) {
        console.log(e)
        return [];
    }
};
const getPaidInvoice = async (supplierId,pNumber,pSize) => {
    try {
        const data = await apiV1.get(`suppliers/${supplierId}/invoices/paid-off?pageNumber=${pNumber}&pageSize=${pSize}`);
        return data;
    } catch(e) {
        console.log(e)
        return [];
    }
};

export {
    fetchDiscountInvoices,
    getDiscountBuyerInvoices,
    fetchDiscountInvoiceById,
    fetchInvoiceOffer,
    acceptInvoiceOffer,   
    getLateInvoices,
    getInvoicesPending,
    getPaidInvoice,
    getImportTemplate
};
