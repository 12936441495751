class LocalStorageService {
    constructor() {
        this.authTokenKey = 'depar-auth';
        this.buyerUserKey = 'buyer-user';
        this.buyerRisks = 'buyer-risks';
        this.buyerLimit = 'buyer-limit';
        this.email = 'email';
        this.isAdmin = 'isAdmin';
        this.isLogout = false;
    }

    setIsLogout(data) {
        localStorage.setItem(this.isLogout, data);
    }
    
    getIsLogout() {
        return localStorage.getItem(this.isLogout) ;
    }

    removeIsLogout() {
        return localStorage.removeItem(this.isLogout) ;
    }

    getAuthToken() {
        return localStorage.getItem(this.authTokenKey);
    }

    setAuthToken(token) {
        localStorage.setItem(this.authTokenKey, `${token}`);
    }

    removeAuthToken() {
        localStorage.removeItem(this.authTokenKey);
    }


    getBuyerUser() {
        return localStorage.getItem(this.buyerUserKey);
    }

    setBuyerUser(token) {
        localStorage.setItem(this.buyerUserKey, `${token}`);
    }

    setBuyerRisks(data){
        localStorage.setItem(this.buyerRisks, `${data}`);
    }

    getBuyerRisks() {
        return localStorage.getItem(this.buyerRisks);
    }

    removeBuyerRisk(){
        return localStorage.removeItem(this.buyerRisks);
    }

    setBuyerLimit(data){
        localStorage.setItem(this.buyerLimit, `${data}`);
    }

    getBuyerLimit() {
        return localStorage.getItem(this.buyerLimit);
    }

    removeBuyerLimit(){
        return localStorage.removeItem(this.buyerLimit);
    }

    removeBuyerUser() {
        localStorage.removeItem(this.buyerUserKey);
    }

    setUserEmail(email){
        localStorage.setItem(this.email, `${email}`);
    }

    getUserEmail() {
        return localStorage.getItem(this.email);
    }
    
    removeUserEmail() {
        localStorage.removeItem(this.email);
    }

    setIsAdmin(isAdmin){
        localStorage.setItem(this.isAdmin, `${isAdmin}`);
    }

    getIsAdmin() {
        return localStorage.getItem(this.isAdmin);
    }
    
    removeIsAdmin() {
        localStorage.removeItem(this.isAdmin);
    }
}

export default new LocalStorageService();
