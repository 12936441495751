/* eslint-disable no-empty */
import { notification } from 'antd';
import { endpoints, apiV1, generalApi } from '../services/apis';
import LocalStorageService from '../services/LocalStorageService';

const token = LocalStorageService.getAuthToken();

const fetchInvoices = async (taxNumber, _token, filters, pSize, pNumber) => {
  
    try {
        const data = await apiV1.get(`${endpoints.invoices}?supplier-tax-id=${taxNumber}&${filters ? `${filters}` : ''}pageNumber=${pNumber}&pageSize=${pSize}`, {
            headers: {
                Authorization: `Bearer ${_token}`,
                accept: 'application/json',
            },
        });
        return data;
    } catch (error) {
         console.log(error);
    }
};

const fetchFinancialInstitution = async () => {   
    try {
        const data = await apiV1.get(endpoints.financialInstitution);
        return data;
    } catch (error) {
         console.log(error);
    }
};

const fetchMaturityRates = async (oran) => {   
    try {
        const data = await apiV1.get(endpoints.rates(oran));
        return data;
    } catch (error) {
         console.log(error);
    }
};

const fetchBusinessDay = async (day, month, year) => {   
    try {
        const data = await generalApi.get(endpoints.businessDay(day, month, year));
        return data;
    } catch (error) {
         console.log(error);
    }
};



const uploadInvoices = async (payload, _token) => {
    
    try {
        const data = await apiV1.post(endpoints.uploadInvoices, payload, {
            headers: {
                Authorization: `Bearer ${token || _token}`,
                accept: 'application/json',
            },
        });
      
        return data;
    } catch (error) {
        if(error.status === 403 && error.data.Type.includes('InterestRatesExpiredException')){ 
            notification.warning({
                message: 'Uyarı',
                description: (
                  <div className='common-err'>
                  İşleminiz şu anda gerçekleştirilemiyor lütfen bizimle <a href="mailto:destek@depar.io">destek@depar.io</a> e-posta adresinden ya da <a href="tel:+905348123264">0534 812 32 64</a>  numaralı telefondan iletişime geçin.
                  </div>
                ),
                duration: 20
              })
        }

        if(error.status === 451 && error.data.Type.includes('SupplierDoesNotHavePermissionException')){
            notification.warning({
                message: 'Uyarı',
                description: (
                  <div className='common-err'>
                  İşleminize devam edebilmek için lütfen sağ altta bulunan kırmızı ikona tıklayarak bizimle iletişime geçin.
                  </div>
                ),
                duration: 8
              })
        }
        if(error.status === 406 && error.data.Type.includes('NotEnoughSupplierLimitException')){
            notification.warning({
                message: 'Uyarı',
                description: (
                  <div className='common-err'>
                   Tedarikci Limitiniz Yetersiz.
                  </div>
                ),
                duration: 8
              })
        }
        if(error.status === 406 && error.data.Type.includes('NotEnoughBuyerLimitException')){
            notification.warning({
                message: 'Uyarı',
                description: (
                  <div className='common-err'>
                   Alicinizin Yeterli Kredi Limiti Bulunmuyor.
                  </div>
                ),
                duration: 8
              })
        }
    }
};

const uploadTemlikInvoicesApi = async (payload, supplierId, discountId) => {
    try {
        const data = await apiV1.post(endpoints.uploadTemlikInvoice(supplierId, discountId), payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        });
        return data;
    } catch (error) {
         console.log(error);
         if(error.data.Status === 400 && error.data.Type.includes('InvalidFileTypeException')){
            notification.error({
                message: `Dosya uzantısı .pdf olmalıdır. `,
            });
        } 
        if(error.data.Status === 400 && error.data.Type.includes('InvalidSizeFileException')){
            notification.error({
                message: `Dosya boyutu 10mb büyük olamaz. `,
            });
        } 
        if(error.data.Status === 413 && error.data.Type.includes('BadHttpRequestException')){
            notification.error({
                message: `En fazla 10MB büyüklüğünde dosya yükleyebilirsiniz. `,
            });
        } 
    }
};

const calculateDiscountAmountApi = async (payload) => {
    try {
        const data = await apiV1.post(endpoints.calculateDiscountAmount, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        });
        return data;
    } catch (error) {
         console.log(error);
         if(error.data.Status === 400 && error.data.Type.includes('RateValueNotFoundException')){
            notification.error({
                message: `Seçmiş olduğunuz faturanın vadesi 270 günden uzun olduğu için işlem yapılamamaktadır. `,
            });
        } 
    }
};

const conveyanceMailApi = async (payload, supplierId, discountId, fileN) => {
    const newData = {
        base64File: payload,
        fileName: `${fileN}.pdf`
    }
    try {
        const data = await apiV1.post(endpoints.conveyanceMail(supplierId, discountId), newData, {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        });
        notification.success({
            message: `Temlikname e-postanıza gönderildi.`,
        });
        return data;
    } catch (error) {
         console.log(error);
         if(error.status === 429 ){
            notification.warning({
                message: `Birden çok gönderim talebinde bulundunuz. Lütfen 1 dakika sonra tekrar deneyiniz.`,
            });
        }
    }
};

const employeesNewUser = async (payload, _token) => {
    
    try {
        const data = await apiV1.post(endpoints.employees, payload, {
            headers: {
                Authorization: `Bearer ${token || _token}`,
                accept: 'application/json',
            },
        });
      
        return data;
    } catch (error) {
        console.log(error)
        if(error.status === 406 && error.data.Type.includes('EmployeeExistsException')){
            notification.warning({
                message: 'Tanımlamak istediğiniz kullanıcı mevcuttur. Lütfen kontrol ediniz..',
              })
        }
    }
};

const getUsersDataApi = async () => {   
    try {
        const data = await apiV1.get(endpoints.employees);
        return data;
    } catch (error) {
         console.log(error);
    }
};

const deleteUserApi = async (userId) => {
    try {
        const response = await apiV1.delete(endpoints.deleteUser(userId));
        return response;
    } catch (error) {console.log(error)}
};


export { fetchInvoices, uploadInvoices, uploadTemlikInvoicesApi, fetchFinancialInstitution, fetchMaturityRates, 
    fetchBusinessDay, calculateDiscountAmountApi, conveyanceMailApi, employeesNewUser, getUsersDataApi, deleteUserApi };
