import { Row } from 'antd';
import styled from 'styled-components';
import Text from '../../../../components/Text';
import Button from '../../../../components/Button';


export const FooterBox = styled(Row)`

    border-radius: 8px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    padding:10px 5px;
                                                                                                                
    margin: 0 auto;
    h3{
        color:#0d0f7b;
        text-decoration: underline;
        font-weight: 800;
        white-space: nowrap
    }
    h4{
        color:#0d0f7b;
        white-space: nowrap
    }
    b{
        font-size:13px;
        font-weight: 500;
    }
    .border-box{
        padding: 7px 11.2px 5.3px 10px;
        border-radius: 8px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
        background-color: #fafbff;
        margin-bottom: 0;
    }
    .invoiceRow .table-div-first div{
        border-right:1px solid #0d0f7b; 
        
    }
   .invoiceRow .table-div-first div:first-child{
        border-right:none;    
    }
    .invoiceRow .table-div-first div:nth-last-child(1){
        border-right:none;    
    }
   
    .last-limit b{
        color:#e34230
    }
    .last-limit h4{
        color:#e34230;
        white-space: nowrap
    }
    .table-div{
        background-color:#fbfbfb;
        width: 100%;
        display: flex;
        padding: 15px;
        border-radius:8px;
        border:1px solid #efefef;
        margin-right:15px
    }
    .table-div div{
        width: calc(100%/3);  
    }
    .btn-color{
        background-color:#20a478;
        color:#fff;
        border-color:#20a478;
    }
    @media only screen and (max-width: 1680px) {
        .interest-rate-box{
            max-width: 106px;
        }
        .maturity-rate-box{
            max-width: 130px;
        }
        .num-invoice-box{
            max-width: 120px;
        }
        .apply-box{
            padding:0
        }
      }
      @media only screen and (max-width: 1100px){
        .table-div{
            display: block;
        }
        .table-div div{
            width:100%;
            border-right: none !important;
            border-bottom: 1px solid rgb(237, 237, 237);
            padding-bottom: 4px;
            padding-top: 8px;
            padding-left: 0 !important;
            min-width: 100% !important;
        }
        .table-div div:nth-child(3){
            border-bottom:none !important;
        }
    }
    @media only screen and (max-width: 780px){
        .table-div{
            display: block;
        }
        .table-div div{
            width:100%;
            border-right: none !important;
            border-bottom: 1px solid rgb(237, 237, 237);
            padding-bottom: 4px;
            padding-top: 8px;
            padding-left: 0 !important;
            min-width: 160px !important;
        }
       
    }  
   
      
      
`;



export const InvoiceText = styled(Text)`
    line-height: 1.05;
    font-weight: 300;
    color: ${({ theme }) => theme.main.primaryColor};
  
`;

export const InvoiceValue = styled(Text)`
    line-height: 1;
    font-weight: 600;
    color: ${({ theme }) => theme.main.primaryColor};
`;

export const InvoiceRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    .table-div-first{
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        gap: 0;
    }
    @media only screen and (max-width: 1680px) {
        justify-content:space-between;
        gap: 0;
      }
    @media only screen and (max-width: 780px){
        display:block;
        .table-div-first{
            margin-bottom:15px;
        }
        .table-div{
            display:flex
        }
        .table-div div{
           border:none
        }
    }
    @media only screen and (max-width: 590px){
        display:block;
        padding:10px;
        .table-div-first{
            display:block;
            margin-bottom:15px;
        }
        .table-div-first div{
            border-right:none !important;
            border-bottom:1px solid #0d0f7b
        }
        .table-div-first div:nth-child(1){
            border-bottom:none !important;
            min-height:60px
        }
        .table-div-first div:nth-child(2){
            padding-bottom:10px;
        }
        .table-div-first div:nth-child(3){
            border-bottom:none !important;
            margin-top:5px;
            padding-left: 4px !important;
        }
        .table-div{
            display:block;
        }
        .table-div div{
           border:none
        }
    }
`;

export const InvoiceCol = styled.div`   
padding:8px;
    @media only screen and (max-width: 1680px) {
        padding:0;
        padding-top:5px;
        padding-left: 5px;
       
    }
`;

export const InvoiceButton = styled(Button)`
    padding-left: 27px;
    paddind-right: 27px;
    background-color: ${({ theme }) => theme.main.primaryColor};
    border-color: ${({ theme }) => theme.main.primaryColor};
    min-width : 168px;
    font-weight: 600;
   
`;
