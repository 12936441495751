import React, {useState} from 'react';
import { Col, Row, Form, Input } from 'antd';
import { Link} from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import urls from '../../../routes/urls';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import {  twoFactor} from '../../../apiServices/userApi';
import { setLoggedIn, setIsAdmin } from '../../../store/reducers/userSlice';
import LocalStorageService from '../../../services/LocalStorageService';

function TwoFactor() {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const {email} = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const history = useHistory();

    const onLogin = async (codeNo) => { 
        setLoading(true);      
        const code =  Object.values(codeNo)[0]
        if(email && code) {
            const response =  await twoFactor(email, code);
            if(response){
                dispatch(setLoggedIn(response.token));
                dispatch(setIsAdmin(response.isAdmin));
                LocalStorageService.setIsAdmin(response.isAdmin);
                LocalStorageService.setIsLogout(false);
                form.resetFields();
                history.push(urls.buyers);
                setLoading(false); 
            }else{
                setLoading(false); 
                form.resetFields();
            }
        }
    };

     

    return (
        <Row>
            <Col xs={24} md={12} lg={8} style={{ margin: '30px auto' }}>
                <Text type="title" style={{  marginBottom:'20px', fontSize:'15px'}}>
                Doğrulama kodunuz e-postanıza iletilmiştir. 
                </Text>
                <Form 
                    name="codeNo" 
                    onFinish={onLogin}
                    className="d-block email-input" 
                    labelCol={{ span: 5 }}
                    layout="horizontal"
                    form={form}
                    >
                    <Form.Item
                        name="code"
                        style={{width:'100%'}}
                        rules={[
                            { 
                                type: 'text',
                                message: 'Lütfen, mailinize gelen doğrulama kodunu giriniz!' },
                                    {
                                        required: true,
                                        message: 'Lütfen doğrulama kodunu giriniz!',
                                    },
                                ]}>
                            <Input placeholder="Doğrulama Kodu" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                loading={loading}
                                style={{width:'100%', margin:'0 auto', display:'block'}}                                
                                block>
                                    Gönder
                            </Button>
                        </Form.Item>
                    </Form>
                    <Link to={urls.login}>Tekrar Doğrulama Kodu Al</Link>

            </Col>
        </Row>
    );
}

export default TwoFactor;
