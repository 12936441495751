/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer' 
import tr_TR from 'antd/es/locale/tr_TR';
import { ConfigProvider, Row, Button, Modal, Typography } from 'antd';
import Joyride from 'react-joyride';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Layout from './components/Layout';
import Content from './components/Layout/Content';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import GlobalStyles from './GlobalStyles';
import ThemeProvider from './theme/ThemeProvider';
import 'antd/dist/antd.css';
import Routes from './routes';
import { getSupOnboading, setSupOnboading } from './apiServices/userApi';
import {setIsRun, setLoggedIn} from './store/reducers/userSlice';
import LocalStorageService from './services/LocalStorageService';

import urls from './routes/urls';

const { Text } = Typography;

const steps = [
  {
    target: '.yuklenen-fatura',
    disableBeacon: true,
    content: 'Alıcının yüklediği faturaları bu alanda görebilirsiniz',
  },
  {
    target: '.basvurular',
    content: 'Yaptığınız başvuruların durumunu buradan takip edebilirsiniz.',
  },
  {
    target: '.temlik-fatura',
    content: 'Temlik Ettiğiniz faturaları bu alanda görebilirsiniz.',
  },
];

function App() {
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [isStatus, setIsStatus] = useState(false);
    const { user, isLoggedIn, isRun } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const sessionTimeOutRef = useRef(null)

    const setOnboardStatus = async (status) => {
      await setSupOnboading(user.id, status);
    }

    const handleHint = (run) => {
      run ? setOnboardStatus('SKIPPED') :''  ;
      dispatch(setIsRun(run))
      setIsOpen(false)
    }
    
    const getOnboardStatus = async () => {
      const response = await getSupOnboading(user.id);       
      if(response.status === 'COMPLETED'){
        dispatch(setIsRun(false));
      }
      setIsStatus(true);
      if (response?.status && (response.status === 'NONE' || response.status === 'SKIPPED')) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }

    const handleSteps = (e) => {
      if(e.index === steps.length - 1) {
        setOnboardStatus('COMPLETED')
      }
    }

    const handleExit = () => {
      setIsModalOpen(false);
      dispatch(setLoggedIn(''));
      LocalStorageService.removeAuthToken();
      LocalStorageService.removeUserEmail()
      LocalStorageService.removeBuyerUser();
      // LocalStorageService.removeIsAdmin();
      history.push(urls.login);
    };
  
    const onIdle = () => {
      if(isLoggedIn){
        sessionTimeOutRef.current = setTimeout(handleExit, 5000)
        setIsModalOpen(true)
      }
    }
  
    const handleStay = () => {
      setIsModalOpen(false);
      clearTimeout(sessionTimeOutRef.current)
    };

    useEffect(()=>{
      dispatch(setIsRun(false))
    },[])

    useEffect(() => {
      if (user && user.id && isLoggedIn && !isStatus) {
        getOnboardStatus();
        dispatch(setIsRun(false))
      }    
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, isLoggedIn]);

    const { getRemainingTime } = useIdleTimer({
      onIdle,
      timeout: 1000 * 5 * 60,
      throttle: 500
    })
    
    useEffect(() => {
      const interval = setInterval(() => {
        getRemainingTime()
      }, 500)
      return () => {
        clearInterval(interval)
      }
    })
    
    return (
      <ThemeProvider>
        <ConfigProvider locale={tr_TR}>
          <GlobalStyles />
          <Layout>
            <Header getOnboardStatus={getOnboardStatus} />
            <Content>
              <Routes />
            </Content>
            <Footer />
            <Modal 
              title="Depar.io’a Hoş Geldiniz  🎉" 
              open={isOpen} 
              footer={null}
              onOk={() => setIsOpen(false)} 
              onCancel={() => setIsOpen(false)}
            >
              <Text style={{fontSize: 16, marginTop: 10}}>Depar.io Platformunu nasıl kullanacağınızı 4 adımda öğreninin</Text>
              <Row>
                <Button type="default" onClick={() => handleHint(false)} className="hint-modal-btn skip-btn">Geç</Button>
                <Button type="primary" onClick={() => handleHint(true)} className="hint-modal-btn start-btn">Başla</Button>
              </Row>
            </Modal>
            <Joyride 
              steps={steps}
              showProgress
              continuous
              callback={handleSteps}
              run={isRun}
              hideBackButton
              scrollToFirstStep
              showSkipButton={false} 
              locale={
                {
                  next: `Sonraki`,
                  last: `Sonraki`,
                }
              }
              styles={{
                buttonNext: {
                  fontSize: '.75rem',
                  width: '141px',
                  height: '40px',
                  color: '#fff',
                  backgroundColor: '#0d0f7b',
                  borderRadius: 8,
                  padding: '10px',
                },
                buttonClose: {
                  width: '11px',
                  marginTop: 4
                },
                beacon: {
                  display: 'none',
                  outline: 'none',
                  left: -60,
                }
              }} 
            />
             <Modal  
                open={isModalOpen} 
                className='modal-login-logout'
                footer={[
                  <Button key="back" style={{backgroundColor:'#504d4d'}} onClick={handleExit}>
                    Oturumu kapat
                  </Button>,
                  <Button key="back" style={{backgroundColor:'#0d0f7b'}} onClick={handleStay}>
                    Oturumu açık tut
                  </Button>
                ]}
                >
                  <p style={{fontSize:'16px', textAlign:'center', marginBottom:'0', fontWeight:'500'}}>Bir süredir hareketsizsiniz! Ne yapmak isterseniz?</p>
            </Modal>
          </Layout>
        </ConfigProvider>
      </ThemeProvider>
    );
}

export default App;
