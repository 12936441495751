const urls = {
    login: '/',
    changePassword: '/sifremi-degistir',
    passwordReset: '/sifre-sifirla/:id/:forgetPasswordToken',

    passwordChange: '/sifremi-unuttum',
    passwordNew: '/sifre-yenile',
    twoFactor: '/dogrulama-kodu',

    funds: '/fonlar',
    fundDetail: '/fonlar/:id',
    generalInfo: '/temel-bilgiler',
    companyInfo: '/firma-bilgiler',    
    legalDocuments: '/legal-bilgiler',
    lateInvoices: '/geciken-faturalar',
    invoicesPendingPayment: '/odeme-bekleyen-faturalar',
    paidInvoices : '/odenenler-faturalar',
    supplierFinancing: '/tedarikci-finansmani',
    financeOffers: '/teklifler',
    bankRiskLimit : '/banka-risk-limit',
    buyers: '/buyers',
    help: '/yardım',
    createdInvoiceResult: '/yeni-fon/:id',

    newUser:'/kullanici-olustur',
    userTracing:'/kullanici-izleme',

    getCreatedInvoiceResultPath: (fundId) => `/yeni-fon/${fundId}`,
    getfundDetail: (fundId) => `/fonlar/${fundId}`,
};

export default urls;

const breadcrumbItems = {
    [urls.login]: { name: 'Üye Girişi', url: urls.login },
    [urls.passwordReset]: { name: 'Parolamı Unuttum', url: urls.passwordReset },
    [urls.help]: { name: 'Yardım', url: urls.help },

    [urls.funds]: { name: 'Başvurularım', url: urls.funds },
    [urls.lateInvoices]: { name: 'Geciken Faturalar', url: urls.lateInvoices },
    [urls.invoicesPendingPayment]: { name: 'Ödeme Bekleyen Faturalar', url: urls.invoicesPendingPayment },
    [urls.paidInvoices]: { name: 'Ödeme Bekleyen Faturalar', url: urls.paidInvoices},
    [urls.fundDetail]: { name: 'Başvuru Detay', url: urls.fundDetail },
    [urls.supplierFinancing]: { name: 'Fatura İşlemleri', url: urls.supplierFinancing },
    [urls.generalInfo]: { name: 'Temel Bilgileri', url: urls.generalInfo },
    [urls.companyInfo]: { name: 'Firma Bilgileri', url: urls.companyInfo },
    [urls.changePassword]: { name: 'Şifre Değiştir', url: urls.changePassword },
    [urls.legalDocuments]: { name: 'Firma Bilgileri', url: urls.legalDocuments },
   
};

// use breadcrumbItems to create breadcrumbs
export const breadcrumbs = {
    [urls.chequeCalculator]: breadcrumbItems[urls.chequeCalculator],
    [urls.help]: breadcrumbItems[urls.help],
    [urls.login]: breadcrumbItems[urls.login],
    [urls.passwordReset]: [breadcrumbItems[urls.login], breadcrumbItems[urls.passwordReset]],
    [urls.funds]: breadcrumbItems[urls.funds],
    [urls.fundDetail]: [breadcrumbItems[urls.funds], breadcrumbItems[urls.fundDetail]],
    [urls.supplierFinancing]: breadcrumbItems[urls.supplierFinancing],
    [urls.generalInfo]: breadcrumbItems[urls.generalInfo],
    [urls.companyInfo]: breadcrumbItems[urls.companyInfo],
    [urls.changePassword]: breadcrumbItems[urls.changePassword],
    [urls.legalDocuments]: breadcrumbItems[urls.legalDocuments],
};
