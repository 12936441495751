
import { PDFDocument, rgb} from 'pdf-lib';

import moment from 'moment';
import fontkit from '@pdf-lib/fontkit';
import { fileTypes } from '../constants';
import Temlikname from '../assests/temlikname.pdf';
import TemliknameDenizFactoring from '../assests/denizFaktoringTemlikname.pdf';
import Dyb from '../assests/dyb.pdf';
import NotoFont from '../assests/NotoSerif-Regular.ttf';


import PlatformuKullanım from '../assests/Uyelik_sozlesmesi.pdf';
import { convertFloatDotSeperated } from './index';
import  {conveyanceMailApi}  from '../apiServices/supplierFinanceApi';

const writtenNumber = require('written-number');

export const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};

export const getIsFilePdf = (fileName) => fileName && fileName.type && fileName.type.endsWith('pdf');
export const getFileType = (file) => {
    const type = (file && file.type) || '';
    const splitted = type.split('/');
    return splitted[splitted.length - 1];
};
export const getFileTypeByUrl = (fileUrl = '') => {
    const splittedUrl = fileUrl.split('.');
    const type = splittedUrl[splittedUrl.length - 1];

    if (Object.values(fileTypes).includes(type)) {
        return type;
    }
    return '';
};

export const downloadURI = (uri, name) => {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
const toBase64 = (u8) => btoa(u8.reduce((data, byte) => data + String.fromCharCode(byte), ''));

const printCol = (page, font, textSize, text, textx, texty, rectx, recty, textWidth, textHeight) => {
    page.drawText(text, {
        x: textx,
        y: texty,
        size: textSize,
        font,
        // color: rgb(0, 0.53, 0.71),
        color: rgb(0, 0, 0, 1),
    });
    page.drawRectangle({
        x: rectx,
        y: recty,
        width: textWidth,
        height: textHeight,
        borderColor: rgb(0, 0, 0, 1),
        borderWidth: 0.5,
    });
};

const printCol1 = (page, font, textSize, text, textx, texty, rectx, recty, textWidth, textHeight) => {
   
    page.drawRectangle({
        x: rectx,
        y: recty,
        width: textWidth,
        height: textHeight,
        borderColor: rgb(0, 0, 0, 1),
        borderWidth: 0.8,
       
        color: rgb(0.9, 0.9, 0.9),
        fillOpacity: 0,
    });
    page.drawText(text, {
        x: textx,
        y: texty,
        size: textSize,
        font,
        // color: rgb(0, 0.53, 0.71)
       color: rgb(0, 0, 0, 1),
    });
};

const size = 6;
const textY = 718;
const rectY = 712; 
const colH = 16;

const colW1 = 28.2;
const colW2 = 42;
const colW3 = 64;
const colW4 = 50;
const colW5 = 99;
const colW6 = 35;
const colW7 = 57;
const colW8 = 56;
const colW9 = 51;
const colW10 = 78.2;

const printRows = (page, font, invoicesData, info ,x ) => {

   let sayi = 0 ;
   const xValue = x !== undefined ? x : 0
   const newValue = 40 * xValue;

    invoicesData &&
    invoicesData.map(({ invoiceDate, invoiceTerm, invoiceNumber, invoiceTotal }, index) => {
            const invoDate = moment(invoiceDate).format('DD-MM-YYYY');
            const termDate = moment(invoiceTerm).format('DD-MM-YYYY');
            const total = `${convertFloatDotSeperated(invoiceTotal)}`;
           
            const txtY = textY - colH * index;
            const rctY = rectY - colH * index;

            const next = index + 1
            sayi = newValue + next ;
            
            /* eslint-disable no-nested-ternary */
            printCol(page, font, size, sayi <10 ? `00${sayi.toString()}`: sayi<100 ? `0${sayi.toString()}`: sayi.toString()  , 74, txtY, 70, rctY, colW1, colH);
          
            printCol(page, font, size, invoDate, 100, txtY, 98.2, rctY, colW2, colH);
            printCol(page, font, size, invoiceNumber, 142, txtY, 140, rctY, colW3, colH);
            printCol(page, font, size, info.buyerTaxNumber, 206, txtY, 204, rctY, colW4, colH);
            printCol(page, font, size, `${info.buyerTitle.substring(0, 32)}..` , 256, txtY, 254, rctY, colW5, colH);
            printCol(page, font, size, 'TL', 366, txtY, 353, rctY, colW6, colH);
            printCol(page, font, size, total, 391, txtY, 388, rctY, colW7, colH);
            printCol(page, font, size, total, 447, txtY, 445, rctY, colW8, colH);
            printCol(page, font, size, termDate, 503, txtY, 501, rctY, colW9, colH);

            return index;
        });
};

const printLastRows = (page, font, invoices, invoTotal) => {
  
    const txtY = textY - colH * invoices.length;
    const rctY = rectY - colH * invoices.length;
    const total = `${convertFloatDotSeperated(invoTotal)}`;

    printCol(page, font, size, 'TOPLAM TL', 330, txtY, 310, rctY, colW10, colH);
    printCol(page, font, size, `${total}`, 391, txtY, 388, rctY, colW7, colH);
    printCol(page, font, size, `${total}`, 448, txtY, 445, rctY, colW8, colH);
};

const textNY = 692;
const rectNY = 687; 
const colNW1 = 95;
const colNW2 = 53;
const colNW3 = 63;
const colNW4 = 63;
const colNW5 = 87;
const colNW6 = 64;
const colNH = 18;

const printRows2 = (page, customFont, invoicesData,  title) => {
    
     invoicesData &&
     invoicesData.map(({ invoiceDate, invoiceTerm, invoiceNumber, invoiceTotal}, index) => {
             const invoDate = moment(invoiceDate).format('DD-MM-YYYY');
             const termDate = moment(invoiceTerm).format('DD-MM-YYYY');
             const total = `${convertFloatDotSeperated(invoiceTotal)}`;
            
             const txtY = textNY - colNH * index;
             const rctY = rectNY - colNH * index;
 
             /* eslint-disable no-nested-ternary */
            //  printCol(page, font, size, sayi <10 ? `00${sayi.toString()}`: sayi<100 ? `0${sayi.toString()}`: sayi.toString()  , 74, txtY, 70, rctY, colW1, colH);
            printCol(page, customFont, size, invoiceNumber, 74, txtY, 70, rctY, colNW1, colNH);
            printCol(page, customFont, size, 'TL', 170, txtY, 165, rctY, colNW2, colNH);
            printCol(page, customFont, size, total, 223, txtY, 218, rctY, colNW3, colNH);
            printCol(page, customFont, size, invoDate, 286, txtY, 281, rctY, colNW4, colNH);
            printCol(page, customFont, size, termDate, 348, txtY, 344, rctY, colNW5, colNH); 
            printCol(page, customFont, size, total, 434, txtY, 431, rctY, colNW6, colNH);

             return index;
    });
    
    let supTitle = [];
    let z=0;
    supTitle = title?.supplierTitle.split(' ');
    let rowLine = 0;

    page.moveTo(70, 120);
    page.drawText('TEMLİK EDEN ', {
        size: 9,
        font: customFont,
    });

    if(title?.supplierTitle.split('').length > 45){
        do {
            supTitle[z] && page.moveTo(68, 107  - rowLine);
            supTitle[z] && page.drawText(` ${supTitle[z] ? supTitle[z] : ''}  ${supTitle[z+1] ? supTitle[z+1] : ''} ${supTitle[z+2] ? supTitle[z+2] : ''} ${supTitle[z+3] ? supTitle[z+3] : ''} ${supTitle[z+4] ? supTitle[z+4] : ''}`, {
                size: 9,
                font: customFont,
            });
            rowLine += 12;
            z += 5
        } while (z < supTitle.length);

        }else{
            page.moveTo(68, 107);
            page.drawText(`${title?.supplierTitle}`, {
            size: 9,
            font: customFont,
        });
    }
   
    page.moveTo(350, 120);
    page.drawText('TEMLİK ALAN ', {
        size: 9,
        font: customFont,
    });
    page.moveTo(350, 107);
    page.drawText('DENIZ FAKTORİNG A.Ş.', {
        size: 9,
        font: customFont,
    });
 };

 const printLastRowsDenizFactoring = (page,customFont, sayi) => {
    // const txtY = textY - colH * sayi.length;
    const rctY = rectY - colH * sayi.length - 40;
    const text1 = `Sayın Noter;  İş bu dört nüshadan ibaret temliknamenin bir nüshasının temlik alan, tasdikli bir nüshasının temlik `
    const text2 = `borçlusuna/muhataba tebliğini, tebellüğ şerhini havi bir nüshanın tarafımıza verilmesini, tasdikli bir nüshanın `
    const text3 = `dairenizde hıfzını talep ederiz.`
    const text4 = `Saygılarımızla,`

    page.moveTo(70, rctY);
    page.drawText( text1, {
            size: 8,
            font: customFont,
        });

    page.moveTo(70, rctY-10);
    page.drawText( text2, {
            size: 8,
            font: customFont,
        });
    
    page.moveTo(70, rctY-20);
        page.drawText( text3, {
                size: 8,
                font: customFont,
            });
   page.moveTo(70, rctY-40);
       page.drawText( text4, {
        size: 8,
        font: customFont,
    });
    
};

const textXY = 519;
const rectXY = 515; 
const colXW0 = 39;
const colXW1 = 101;
const colXW2 = 96;
const colXW3 = 50;
const colXW4 = 36;
const colXW5 = 58;
const colXW6 = 142;
const colXW7 = 64;
const colXW8 = 50;
const colXW9 = 98;
const colXW10 = 80;
const colXW11 = 380;
const colXH = 14;
const colXH1 = 30;
const colXH2 = 12;
const colXH3 = 18;
const colXWY1 = 80;
const colXWY2 = 100;


const printRows3 = (page, customFont, invoicesData,  title, x, dataLoop) => {
    let sayi = 0 ;
    const xValue = x !== undefined ? x : 0
    const newValue = 30 * xValue;
    
    invoicesData &&
    invoicesData.map(({ invoiceDate, invoiceTerm, invoiceNumber,invoiceTotal, invoiceOwnerTaxNumber, invoiceOwnerTitle}, index) => {
            const invoDate = moment(invoiceDate).format('DD-MM-YYYY');
            const termDate = moment(invoiceTerm).format('DD-MM-YYYY');
            const total = `${convertFloatDotSeperated(invoiceTotal)}`;
           
            const txtY = textXY - colXH * index;
            const rctY = rectXY - colXH * index;

            const next = index + 1
            sayi = newValue + next ;

            /* eslint-disable no-nested-ternary */

           printCol(page, customFont, size, sayi <10 ? `00${sayi.toString()}`: sayi<100 ? `0${sayi.toString()}`: sayi.toString()  , 18, txtY, 15, rctY, colXW0, colXH);
           printCol(page, customFont, size, invoiceOwnerTaxNumber, 57, txtY, 54, rctY, colXW1, colXH);
           printCol(page, customFont, size, `${invoiceOwnerTitle.substring(0, 22)}..` , 158, txtY, 155, rctY, colXW2, colXH);
           printCol(page, customFont, size, invoDate, 254, txtY, 251, rctY, colXW3, colXH);
           printCol(page, customFont, size, `${invoiceNumber.substring(0, 3)}`, 304, txtY, 301, rctY, colXW4, colXH);
           printCol(page, customFont, size, `${invoiceNumber.substring(3, 16)}`, 340, txtY, 337, rctY, colXW5, colXH); 
           printCol(page, customFont, size, total, 398, txtY, 395, rctY, colXW6, colXH); 
           printCol(page, customFont, size, '-', 540, txtY, 537, rctY, colXW7, colXH);
           printCol(page, customFont, size, 'TL', 604, txtY, 601, rctY, colXW8, colXH);
           printCol(page, customFont, size, total, 654, txtY, 651, rctY, colXW9, colXH);
           printCol(page, customFont, size, termDate, 752, txtY, 749, rctY, colXW10, colXH);

            return index;
   });
 
   let supTitle = [];
   let z=0;
   supTitle = title?.supplierTitle.split(' ');
   let rowLine = 0;

   page.moveTo(70, 90);
   page.drawText(moment().format('DD-MM-YYYY'), {
       size: 9,
       font: customFont,
   });

   page.moveTo(70, 80);
   page.drawText('TEMLİK EDEN ', {
       size: 9,
       font: customFont,
   });

   if(title?.supplierTitle.split('').length > 65){
       do {
           supTitle[z] && page.moveTo(68, 70  - rowLine);
           supTitle[z] && page.drawText(` ${supTitle[z] ? supTitle[z] : ''}  ${supTitle[z+1] ? supTitle[z+1] : ''} ${supTitle[z+2] ? supTitle[z+2] : ''} ${supTitle[z+3] ? supTitle[z+3] : ''} ${supTitle[z+4] ? supTitle[z+4] : ''} ${supTitle[z+5] ? supTitle[z+5] : ''}  ${supTitle[z+6] ? supTitle[z+6] : ''} ${supTitle[z+7] ? supTitle[z+7] : ''}`, {
               size: 9,
               font: customFont,
           });
           rowLine += 12;
           z += 8
       } while (z < supTitle.length);

       }else{
           page.moveTo(70, 70);
           page.drawText(`${title?.supplierTitle}`, {
           size: 9,
           font: customFont,
       });
   }

   page.moveTo(70, 30);
   page.drawText(`(Kaşe + İmza)`, {
       size: 8,
       font: customFont,
   });

   page.moveTo(70, 15);
   page.drawText(`İş bu Alacak Bildirim Formu ${dataLoop + 1} sayfadan ibarettir.`, {
       size: 8,
       font: customFont,
   });
};

const printLastRows3 = async (page, fonty, invoices, invoTotal) => {

    const txtY = textXY - colXH * invoices.length;
    const rctY = rectXY - colXH * invoices.length;
    const total = `${convertFloatDotSeperated(invoTotal)}`;

    printCol(page, fonty, size, 'TOPLAM TL', 350, txtY, 15, rctY, colXW11, colXH);
    printCol(page, fonty, size, `${total}`, 398, txtY, 395, rctY, colXW6, colXH);
    printCol(page, fonty, size, ``, 540, txtY, 537, rctY, colXW7, colXH);

    printCol(page, fonty, size, ``, 605, txtY, 601, rctY, colXW8, colXH);
    printCol(page, fonty, size, ``, 654, txtY, 651, rctY, colXW9, colXH);
    printCol(page, fonty, size, ``, 752, txtY, 749, rctY, colXW10, colXH);

// DYB son tablo header
    printCol1(page, fonty, 9 , 'ALICI  VKN', 29, txtY - 30, 15, rctY - 40 , colXWY1, colXH1);
    printCol1(page, fonty, 9 , 'ALICI ÜNVANI ', 115, txtY - 30, 95, rctY - 40 , colXWY2, colXH1);
    printCol1(page, fonty, 9 , 'BELGE ', 300, txtY - 22, 195, rctY - 22 , 254, colXH2);
    printCol1(page, fonty, 9 , 'TARİH ', 210, txtY - 38, 195, rctY - 40 , 60, colXH3);
    printCol1(page, fonty, 8 , 'SERİ NO ', 258, txtY - 38, 255, rctY - 40 , 40, colXH3);
    printCol1(page, fonty, 8 , 'SIRA NO ', 298, txtY - 38, 295, rctY - 40 , 40, colXH3);
    printCol1(page, fonty, 8 , 'TUTARI ', 348, txtY - 38, 335, rctY - 40 , 60, colXH3);
    printCol1(page, fonty, 8 , 'YEVMİYE NO ', 397, txtY - 38, 395, rctY - 40 , 54, colXH3);
    printCol1(page, fonty, 8 , 'PARA BİRİMİ', 452, txtY - 30, 449, rctY - 40 , 56, colXH1);
    printCol1(page, fonty, 8 , 'VADE', 530, txtY - 30, 505, rctY - 40 , 70, colXH1);
    printCol1(page, fonty, 9 , 'İADE FATURA TARİHİ', 578, txtY - 30, 575, rctY - 40 , 100, colXH1);
    printCol1(page, fonty, 9 , 'İADE ', 695, txtY - 22, 675, rctY - 22 , 60, colXH2);
    printCol1(page, fonty, 9 , 'FATURA NO ', 678, txtY - 38, 675, rctY - 40 , 60, colXH3);
    printCol1(page, fonty, 9 , 'İADE ', 770, txtY - 22, 735, rctY - 22 , 95, colXH2);
    printCol1(page, fonty, 9 , 'FATURA TUTARI ', 745, txtY - 38, 735, rctY - 40 , 95, colXH3);
// ---------------
    const index = 0;

    printCol(page, fonty, 9 , '-', 18 , txtY - 53 - colXH * index , 15 , rctY - 54 - colXH * index, colXWY1, colXH);
    printCol(page, fonty, 9 , '-', 98 , txtY - 53 - colXH * index , 95 , rctY - 54 - colXH * index, colXWY2, colXH);
    printCol(page, fonty, 9 , '-', 198 , txtY - 53 - colXH * index , 195 , rctY - 54 - colXH * index, 60, colXH);
    printCol(page, fonty, 9 , '-', 258 , txtY - 53 - colXH * index , 255 , rctY - 54 - colXH * index, 40, colXH);
    printCol(page, fonty, 9 , '-', 298 , txtY - 53 - colXH * index , 295 , rctY - 54 - colXH * index, 40, colXH);
    printCol(page, fonty, 9 , '-', 338 , txtY - 53 - colXH * index , 335 , rctY - 54 - colXH * index, 60, colXH);
    printCol(page, fonty, 9 , '-', 398 , txtY - 53 - colXH * index , 395 , rctY - 54 - colXH * index, 54, colXH);
    printCol(page, fonty, 9 , '-', 452 , txtY - 53 - colXH * index , 449 , rctY - 54 - colXH * index, 56, colXH);
    printCol(page, fonty, 9 , '-', 508 , txtY - 53 - colXH * index , 505 , rctY - 54 - colXH * index, 70, colXH);
    printCol(page, fonty, 9 , '-', 578 , txtY - 53 - colXH * index , 575 , rctY - 54 - colXH * index, 100, colXH);
    printCol(page, fonty, 9 , '-', 678 , txtY - 53 - colXH * index , 675 , rctY - 54 - colXH * index, 60, colXH);
    printCol(page, fonty, 9 , '-', 738 , txtY - 53 - colXH * index , 735 , rctY - 54 - colXH * index, 95, colXH);

// Burası iptal edililen fatura datası geldiğinde aktif edilecek
// invoices && invoices.map((item,index) => {

//     printCol(page, fonty, 9 , item.invoiceOwnerTaxNumber, 18 , txtY - 53 - colXH * index , 15 , rctY - 54 - colXH * index, colXWY1, colXH);
//     printCol(page, fonty, 9 , item.invoiceOwnerTitle, 98 , txtY - 53 - colXH * index , 95 , rctY - 54 - colXH * index, colXWY2, colXH);
//     printCol(page, fonty, 9 , moment(item.createdDate).format('DD-MM-YYYY'), 198 , txtY - 53 - colXH * index , 195 , rctY - 54 - colXH * index, 60, colXH);
//     printCol(page, fonty, 9 , '-', 258 , txtY - 53 - colXH * index , 255 , rctY - 54 - colXH * index, 40, colXH);
//     printCol(page, fonty, 9 , '-', 298 , txtY - 53 - colXH * index , 295 , rctY - 54 - colXH * index, 40, colXH);
//     printCol(page, fonty, 9 , item.invoiceTotal.toString(), 338 , txtY - 53 - colXH * index , 335 , rctY - 54 - colXH * index, 60, colXH);
//     printCol(page, fonty, 9 , '-', 398 , txtY - 53 - colXH * index , 395 , rctY - 54 - colXH * index, 54, colXH);
//     printCol(page, fonty, 9 , 'TL', 452 , txtY - 53 - colXH * index , 449 , rctY - 54 - colXH * index, 56, colXH);
//     printCol(page, fonty, 9 , moment(item.invoiceTerm).format('DD-MM-YYYY'), 508 , txtY - 53 - colXH * index , 505 , rctY - 54 - colXH * index, 70, colXH);
//     printCol(page, fonty, 9 , '-', 578 , txtY - 53 - colXH * index , 575 , rctY - 54 - colXH * index, 100, colXH);
//     printCol(page, fonty, 9 , item.invoiceOwnerTaxNumber, 678 , txtY - 53 - colXH * index , 675 , rctY - 54 - colXH * index, 60, colXH);
//     printCol(page, fonty, 9 , item.invoiceTotal.toString(), 738 , txtY - 53 - colXH * index , 735 , rctY - 54 - colXH * index, 95, colXH);
//     return index
// })


};


export const LoadPdfTemlik = async (invoiceData, btnId, supplierId, discountId) => {

    const fontBytes = await fetch(NotoFont).then((res) => res.arrayBuffer());
    const existingPdfBytes = await fetch(Temlikname)
        .then((res) => res.arrayBuffer())
        .then((arrayBufferData) => arrayBufferData);
    if (existingPdfBytes) {
        const pdfDoc = await PDFDocument.load(existingPdfBytes, {
            updateMetadata: false,
        });

        pdfDoc.registerFontkit(fontkit);
        const customFont = await pdfDoc.embedFont(fontBytes)
    
        // console.log(writtenNumber(35.000,50, {lang: 'tr'}));
        // Get the first page of the document
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const invoices = invoiceData?.invoices?.length > 0 && invoiceData?.invoices.filter(item => item.status === 'SECILDI');
      
        const dataLoop = Math.ceil(invoices.length/40);
       
        firstPage.moveTo(152, 127);
        firstPage.drawText(invoiceData?.supplierTitle, {
            size: 11,
            font: customFont,
        });

        firstPage.moveTo(269, 38);
        firstPage.drawText('Kaşe + İmza', {
            size: 8,
            font: customFont,
        });

        pages[1].moveTo(269, 38);
        pages[1].drawText('Kaşe + İmza', {
            size: 8,
            font: customFont,
        });

        firstPage.moveTo(70, 140);
        firstPage.drawText(moment().format('DD-MM-YYYY'), {
            size: 10,
            font: customFont,
        });

        const data = [];
        let startNumber = 0;
        let numberPage = 40;
        let i = 0;
       
        do {
            data.push(invoices.slice(startNumber,numberPage))
            startNumber += 40 ;
            numberPage += 40
            i += 1
          } while (i < dataLoop);

        const selectedInvoiceTotal = invoices.reduce((a, c) => a + c.invoiceTotal, 0)

        if(data.length > 0 ){
            printRows(pages[1], customFont, data[0], invoiceData)
            data.length === 1 &&  printLastRows(pages[1], customFont, data[data.length-1], selectedInvoiceTotal);

            if(data.length !== 1){
                let x = 1;
                do {
                   
                    const pagex =  pdfDoc.addPage();
                     printRows(pagex, customFont, data[x], invoiceData, x)
                     pagex.moveTo(269, 38);
                     pagex.drawText('Kaşe + İmza', {
                         size: 8,
                         font: customFont,
                     });
                      x += 1;
                      if(x === dataLoop ){
                          printLastRows(pagex, customFont, data[data.length-1], selectedInvoiceTotal);
                          pagex.moveTo(269, 38);
                          pagex.drawText('Kaşe + İmza', {
                              size: 8,
                              font: customFont,
                          });
                      }
                  } while (x < dataLoop); 

            }
        }

      const fileName = `Temlikname-${((invoiceData.financialInstitutionName).split(' ')[0])}-${invoiceData.number}`; 

        if(btnId === 'indir'){
            const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
            downloadURI(pdfDataUri, `Temlikname-${((invoiceData.financialInstitutionName).split(' ')[0])}-${invoiceData.number}`);
        }

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const updatedPdf = await pdfDoc.save();
        if(btnId === 'email'){
            await conveyanceMailApi(toBase64(updatedPdf), supplierId, discountId , fileName)
        }
        
        return toBase64(updatedPdf);
    }
};

export const LoadPdfTemlikDenizFactoring = async (invoiceData, btnId, supplierId, discountId) => {
    const fontBytes = await fetch(NotoFont).then((res) => res.arrayBuffer());
    const existingPdfBytes = await fetch(TemliknameDenizFactoring)
        .then((res) => res.arrayBuffer())
        .then((arrayBufferData) => arrayBufferData);
    if (existingPdfBytes) {
        const pdfDoc = await PDFDocument.load(existingPdfBytes, {
            updateMetadata: false,
        });

        pdfDoc.registerFontkit(fontkit);
        const customFont = await pdfDoc.embedFont(fontBytes)

        writtenNumber.defaults.lang = 'tr';

        // Get the first page of the document
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const invoices = invoiceData?.invoices?.length > 0 && invoiceData?.invoices.filter(item => item.status === 'SECILDI');
        const selectedInvoiceTotal = invoices.reduce((a, c) => a + c.invoiceTotal, 0);
        const oddNumber = parseFloat(selectedInvoiceTotal).toFixed(2).toString().split('.');
        const odd = oddNumber[oddNumber.length -1];
        const dataLoop = Math.ceil(invoices.length/30);
        const totalTextData = writtenNumber(oddNumber[0]).split(' ');

         const textData = [];
        let startText = 0;
        let numberText = 9;
        let y = 0;
        let rowLine = 0;
        const loopTotalLoopNumber = Math.ceil(totalTextData.length/9);
      
        firstPage.moveTo(278, 598);
        firstPage.drawText('(', {
            size: 11,
            font: customFont,
        });
        
        let supTitle = [];
        let buyerTitle = [];
        let z=0;
        supTitle = invoiceData?.supplierTitle.split(' ');
        buyerTitle = invoiceData?.buyerTitle.split(' ');

        do {
            textData.push(totalTextData.slice(startText,numberText))
            startText += 9 ;
            numberText += 9;
            rowLine += 12;

            textData[y] && firstPage.moveTo(280, 610 - rowLine);
            textData[y] && firstPage.drawText(` ${textData[y].toString().split(',').join(' ')} türk lirası ${y === loopTotalLoopNumber - 1 && oddNumber[oddNumber.length -1] !== '00' ? 
            `${writtenNumber(odd)} kuruş)` : y === loopTotalLoopNumber - 1 && ')' }`, {
                 size: 9,
                 font: customFont,
             });
            
            y += 1
          } while (y < loopTotalLoopNumber);
          
            // ilk sayfanın en üst temlik eden supplier title
          if(invoiceData?.supplierTitle.split('').length > 45){
            z=0;
            rowLine = 0;
            do {
                supTitle[z] && firstPage.moveTo(275, 770 - rowLine);
                supTitle[z] && firstPage.drawText(` ${supTitle[z] ? supTitle[z] : ''}  ${supTitle[z+1] ? supTitle[z+1] : ''} ${supTitle[z+2] ? supTitle[z+2] : ''} ${supTitle[z+3] ? supTitle[z+3] : ''} ${supTitle[z+4] ? supTitle[z+4] : ''}`, {
                    size: 9,
                    font: customFont,
                });
                rowLine += 12;
                z += 5
            } while (z < supTitle.length);
          }else{
             firstPage.moveTo(280, 765);
                firstPage.drawText(`${invoiceData?.supplierTitle}`, {
                size: 9,
                font: customFont,
            });
          }
          // ilk sayfanın altında temlik eden supplier title
          if(invoiceData?.supplierTitle.split('').length > 45){
            z=0;
            rowLine = 0;
            do {
              supTitle[z] && firstPage.moveTo(70, 76 - rowLine);
              supTitle[z] && firstPage.drawText(` ${supTitle[z] ? supTitle[z] : ''}  ${supTitle[z+1] ? supTitle[z+1] : ''} ${supTitle[z+2] ? supTitle[z+2] : ''} ${supTitle[z+3] ? supTitle[z+3] : ''} ${supTitle[z+4] ? supTitle[z+4] : ''}`, {
                   size: 10,
                   font: customFont,
               });
               rowLine += 12;
               z += 5
            } while (z < supTitle.length);
          }else{

            firstPage.moveTo(70, 76);
            firstPage.drawText(invoiceData?.supplierTitle, {
                size: 10,
                font: customFont,
            });
          }
         // ilk sayfanın üstte temlik eden buyer title

        if(invoiceData?.buyerTitle.split('').length > 45){
            z=0;
            rowLine = 0;
            do {
                buyerTitle[z] && firstPage.moveTo(275, 667 - rowLine);
                buyerTitle[z] && firstPage.drawText(` ${buyerTitle[z] ? buyerTitle[z] : ''}  ${buyerTitle[z+1] ? buyerTitle[z+1] :''} ${buyerTitle[z+2] ? buyerTitle[z+2] : ''} ${buyerTitle[z+3] ? buyerTitle[z+3] : ''} ${buyerTitle[z+4] ? buyerTitle[z+4] : ''}`, {
                     size: 9,
                     font: customFont,
                 });
                 rowLine += 12;
                 z += 5
              } while (z < buyerTitle.length); 
            }else{
                firstPage.moveTo(280, 660);
                firstPage.drawText(`${invoiceData.buyerTitle}`, {
                size: 9,
                font: customFont,
            });  
        }
// tablodaki toplam
        firstPage.moveTo(314, 612);
        firstPage.drawText(`${(convertFloatDotSeperated(selectedInvoiceTotal))} TL`, {
            size: 10,
            font: customFont,
        });

        // Temlik Eden, muhatap …. yazılı metin alanı
        if(invoiceData?.buyerTitle.split('').length > 45){
            z=0;
            rowLine = 0;
            do {
                buyerTitle[z] && firstPage.moveTo(218, 522 - rowLine);
                buyerTitle[z] && firstPage.drawText(` ${buyerTitle[z] ? buyerTitle[z] : ''}  ${buyerTitle[z+1] ? buyerTitle[z+1] :''} ${buyerTitle[z+2] ? buyerTitle[z+2] : ''} ${buyerTitle[z+3] ? buyerTitle[z+3] : ''} ${buyerTitle[z+4] ? buyerTitle[z+4] : ''} ${buyerTitle[z+5] ? buyerTitle[z+5] : ''} `, {
                     size: 9,
                     font: customFont,
                 });
                 rowLine += 12;
                 z += 6
              } while (z < buyerTitle.length); 
            }else{
                firstPage.moveTo(210, 522);
                firstPage.drawText(`${invoiceData.buyerTitle}`, {
                size: 9,
                font: customFont,
            });  
        }
      

        firstPage.moveTo(348, 495);
        firstPage.drawText( convertFloatDotSeperated(selectedInvoiceTotal), {
            size: 10,
            font: customFont,
        });

        firstPage.moveTo(112, 482);
        firstPage.drawText( `${writtenNumber(oddNumber[0])} türk lirası ${oddNumber[oddNumber.length -1] !== '00' ? `${writtenNumber(odd)} kuruş` : '' } ` , {
            size: 10,
            font: customFont,
        });
       

        firstPage.moveTo(190, 141);
        firstPage.drawText(moment().format('DD-MM'), {
            size: 10,
            font: customFont,
        });

        

        const data = [];
        let startNumber = 0;
        let numberPage = 30;
        let i = 0;
       
        do {
            data.push(invoices.slice(startNumber,numberPage))
            startNumber += 30 ;
            numberPage += 30
            i += 1
          } while (i < dataLoop);

        // const selectedInvoiceTotal = invoices.reduce((a, c) => a + c.invoiceTotal, 0)

        if(data.length > 0 ){
            printRows2(pages[1], customFont, data[0], invoiceData, invoiceData?.supplierTitle)
            if(data.length !== 1 ){
                let x = 1;
                do {
                    const pagex =  pdfDoc.addPage();
                     printRows2(pagex, customFont, data[x], invoiceData, invoiceData?.supplierTitle)
                      x += 1;
                      if(x === dataLoop ){
                        //   printLastRows(pagex, customFont, data[data.length-1], selectedInvoiceTotal);
                          printLastRowsDenizFactoring(pagex, customFont, data[data.length-1], invoiceData?.supplierTitle);
                      }
                  } while (x < dataLoop); 
              
            }else if(data.length === 1){
                printLastRowsDenizFactoring(pages[1], customFont, data[data.length-1]);

            }
        }

      const fileName = `Temlikname-${((invoiceData.financialInstitutionName).split(' ')[0])}-${invoiceData.number}`; 

        if(btnId === 'indir'){
            const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
            downloadURI(pdfDataUri, `Temlikname-${((invoiceData.financialInstitutionName).split(' ')[0])}-${invoiceData.number}`);
        }

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const updatedPdf = await pdfDoc.save();
        if(btnId === 'email'){
            await conveyanceMailApi(toBase64(updatedPdf), supplierId, discountId , fileName)
        }
        
        return toBase64(updatedPdf);
    }
};

export const LoadPdfTemlikDyb = async (invoiceData, btnId, supplierId, discountId) => {
    const fontBytes = await fetch(NotoFont).then((res) => res.arrayBuffer());
    const existingPdfBytes = await fetch(Dyb)
        .then((res) => res.arrayBuffer())
        .then((arrayBufferData) => arrayBufferData);
    if (existingPdfBytes) {
        const pdfDoc = await PDFDocument.load(existingPdfBytes, {
            updateMetadata: false,
        });

        pdfDoc.registerFontkit(fontkit);
        const customFont = await pdfDoc.embedFont(fontBytes)

        writtenNumber.defaults.lang = 'tr';

        // Get the first page of the document
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const invoices = invoiceData?.invoices?.length > 0 && invoiceData?.invoices.filter(item => item.status === 'SECILDI');
        const selectedInvoiceTotal = invoices.reduce((a, c) => a + c.invoiceTotal, 0);
        const dataLoop = Math.ceil(invoices.length/30);
        let rowLine = 0;
        let supTitle = [];
        let z=0;
        supTitle = invoiceData?.supplierTitle.split(' ');


        firstPage.moveTo(30, 130);
        firstPage.drawText(`Saygılarımızla; `, {
            size: 8,
            font: customFont,
        });

        firstPage.moveTo(30, 115);
        firstPage.drawText(moment().format('DD-MM-YYYY'), {
            size: 8,
            font: customFont,
        });

        firstPage.moveTo(30, 100);
        firstPage.drawText(`Temlik Eden`, {
            size: 8,
            font: customFont,
        });
          
          // ilk sayfanın altında temlik eden supplier title
          if(invoiceData?.supplierTitle.split('').length > 65){
            do {
                supTitle[z] && firstPage.moveTo(30, 86  - rowLine);
                supTitle[z] && firstPage.drawText(` ${supTitle[z] ? supTitle[z] : ''}  ${supTitle[z+1] ? supTitle[z+1] : ''} ${supTitle[z+2] ? supTitle[z+2] : ''} ${supTitle[z+3] ? supTitle[z+3] : ''} ${supTitle[z+4] ? supTitle[z+4] : ''} ${supTitle[z+5] ? supTitle[z+5] : ''}  ${supTitle[z+6] ? supTitle[z+6] : ''} ${supTitle[z+7] ? supTitle[z+7] : ''}`, {
                    size: 9,
                    font: customFont,
                });
                rowLine += 12;
                z += 8
            } while (z < supTitle.length);
     
            }else{
                firstPage.moveTo(30, 86);
                firstPage.drawText(`${invoiceData?.supplierTitle}`, {
                size: 9,
                font: customFont,
            });
        }
   
// tablodaki toplam
        firstPage.moveTo(221, 168);
        firstPage.drawText(`${(convertFloatDotSeperated(selectedInvoiceTotal))} `, {
            size: 10,
            font: customFont,
        });

        firstPage.moveTo(95, 168);
        firstPage.drawText(`${(convertFloatDotSeperated(selectedInvoiceTotal))} `, {
            size: 10,
            font: customFont,
        });

        firstPage.moveTo(48, 168);
        firstPage.drawText(`TL`, {
            size: 10,
            font: customFont,
        });

        firstPage.moveTo(343, 168);
        firstPage.drawText(`${invoiceData?.invoices?.length}`, {
            size: 10,
            font: customFont,
        });

        firstPage.moveTo(430, 168);
        firstPage.drawText(`-`, {
            size: 10,
            font: customFont,
        });

        firstPage.moveTo(530, 168);
        firstPage.drawText(`-`, {
            size: 10,
            font: customFont,
        });

        const data = [];
        let startNumber = 0;
        let numberPage = 30;
        let i = 0;
       
        do {
            data.push(invoices.slice(startNumber,numberPage))
            startNumber += 30 ;
            numberPage += 30
            i += 1
          } while (i < dataLoop);

          firstPage.moveTo(30, 30);
          firstPage.drawText(`(Kaşe + İmza)`, {
              size: 8,
              font: customFont,
          });

          firstPage.moveTo(30, 15);
          firstPage.drawText(`İş bu Alacak Bildirim Formu ${dataLoop+1}  sayfadan ibarettir.`, {
              size: 8,
              font: customFont,
          });

        // const selectedInvoiceTotal = invoices.reduce((a, c) => a + c.invoiceTotal, 0)

        if(data.length > 0 ){
            
            printRows3(pages[1], customFont, data[0], invoiceData,'', dataLoop)
            if(data.length !== 1 ){
                let x = 1;
                do {
                    const pagex =  pdfDoc.addPage([841.89, 595.28]);
                    
                     printRows3(pagex, customFont, data[x], invoiceData,  x, dataLoop)
                      x += 1;
                      if(x === dataLoop ){
                        printLastRows3(pagex, customFont, data[data.length-1], selectedInvoiceTotal);

                       
                      }
                  } while (x < dataLoop); 
              
            }else if(data.length === 1){
                printLastRows3(pages[1], customFont, data[data.length-1], selectedInvoiceTotal);

            }
        }

      const fileName = `Temlikname-${((invoiceData.financialInstitutionName).split(' ')[0])}-${invoiceData.number}`; 

        if(btnId === 'indir'){
            const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
            downloadURI(pdfDataUri, `Temlikname-${((invoiceData.financialInstitutionName).split(' ')[0])}-${invoiceData.number}`);
        }

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const updatedPdf = await pdfDoc.save();
        if(btnId === 'email'){
            await conveyanceMailApi(toBase64(updatedPdf), supplierId, discountId , fileName)
        }
        
        return toBase64(updatedPdf);
    }
};

export const LoadPdfPlatform = async (text) => {
    const fontBytes = await fetch(NotoFont).then((res) => res.arrayBuffer());
    const existingPdfBytes = await fetch(PlatformuKullanım)
        .then((res) => res.arrayBuffer())
        .then((arrayBufferData) => arrayBufferData);
    if (existingPdfBytes) {
        const pdfDoc = await PDFDocument.load(existingPdfBytes, {
            updateMetadata: false,
        });

        pdfDoc.registerFontkit(fontkit);
        const customFont = await pdfDoc.embedFont(fontBytes)

        // Get the first page of the document
        const pages = pdfDoc.getPages();
        const firstPage = pages[11];

        const title = text.split(' ')
        const pageNumber = [];
        let x=0;
        let y=0;
        let z=0;

        do {
            pageNumber[y] = title.slice(y, y+3).join(' ')
             
            firstPage.moveTo(76, 460 - z );
            firstPage.drawText( pageNumber[y] , {
                font: customFont,
                    size: 10,
                });
            
            x += 3;
            z += 10;
            y += 3;
          } while (x < title.length);

          firstPage.moveTo(485, 525 );
          firstPage.drawText(moment().format('DD-MM-YYYY'), {
              size: 10,
              font: customFont,
          });

     
        const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
            downloadURI(pdfDataUri, 'Üyelik Sözleşmesi');
      

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const updatedPdf = await pdfDoc.save();
        return toBase64(updatedPdf);
    }
};
