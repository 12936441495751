import React from 'react';
import { Row} from 'antd';
import { TextBox } from './styles';


const ClarificationText = () => (
   <TextBox>        
      <Row>
         <h3 className='title-center'>Neo Elektronik Ticaret Bilişim Teknolojileri Yazılım ve Danışmanlık Anonim Şirketi
         Kişisel Verilere İlişkin Aydınlatma Metni
         </h3>
         <ol >
            <li className='top-li'>
            Veri Sorumlusu: Neo Elektronik Ticaret Bilişim Teknolojileri Yazılım ve Danışmanlık Anonim Şirketi (“Depar.io” ve/veya “Şirket”), MERSİS No: 0631183973400001, Ticaret Sicil No: 449049-5Biz, Neo Elektronik Ticaret Bilişim Teknolojileri Yazılım ve Danışmanlık Anonim Şirketi olarak; Türkiye Cumhuriyeti Anayasası, insan haklarına ilişkin ülkemizin tarafı olduğu uluslararası sözleşmeler ile 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) başta olmak üzere, 6361 sayılı Finansal Kiralama, Faktoring ve Finansman Şirketleri Kanunu, BDDK Destek Hizmetleri Yönetmeliği, 5411 sayılı Bankacılık Kanunu ve ilgili diğer mevzuatlar çerçevesinde; kişisel verilerinizin güvence altına alınması ve işlenmesi konusuna hassasiyetle yaklaşmaktayız. Bu çerçevede, KVKK kapsamında Veri Sorumlusu sıfatıyla sizleri aydınlatmak istiyoruz. Şirketimiz ile paylaştığınız kişisel verileriniz KVKK’ya uygun şekilde, faaliyet ve hizmet amaçlarımız ile bağlantılı ve ölçülü olarak işlenebilecek, saklanacak, profilleme için kullanılabilecek ve sınıflandırılabilecektir.
            <p>Kişisel Verilerinizin İşlenme Amacı Kişisel verileriniz;</p>
            <div>
               <ol>
                  <li>Hukuka uygun ve dürüstlük kuralının öngördüğü biçimde,</li>
                  <li>İşlenme amaçları ile bağlantılı, sınırlı ve ölçülü olarak,</li>
                  <li>Doğru ve güncel olarak</li>
                  <li>Belirli açık ve meşru amaçlar ile işlenecektir.</li>
                  <li>İşlenen Kişisel Veriler</li>
               </ol>
            </div>
            <p>İşlenen kişisel verileriniz aşağıda yer alan tabloda kategori bazında listelenmiştir.</p>
            <table style={{width:'100%'}}>
               <thead>
                  <tr>
                     <th style={{textAlign:'center'}}>Veri Kategorisi</th>
                     <th style={{textAlign:'center'}}>Kişisel Veri</th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td>Kimlik</td>
                     <td>Ad-Soyad, TCKN</td>
                  </tr>
                  <tr>
                     <td>İletişim</td>
                     <td>Adres no, e-posta adresi, iletişim adresi, telefon no v.b.</td>
                  </tr>
                  <tr>
                     <td>Lokasyon</td>
                     <td>Bulunduğu yerin konum bilgileri v.b.</td>
                  </tr>
                  <tr>
                     <td>Müşteri işlem  </td>
                     <td>Çağrı/İletişim merkezi kayıtları, fatura, imza beyannamesi, vergi levhası  v.b.</td>
                  </tr>
                  <tr>
                     <td>İşlem Güvenliği</td>
                     <td>IP adresi bilgileri, İnternet sitesi giriş çıkış bilgileri, Şifre ve parola bilgileri v.b.</td>
                  </tr>
                  <tr>
                     <td>Finans</td>
                     <td>Kredi ve risk bilgileri vb.</td>
                  </tr>
                  <tr>
                     <td>Pazarlama</td>
                     <td>Anket, Çerez kayıtları, Kampanya çalışmasıyla elde edilen bilgiler v.b.</td>
                  </tr>
                  <tr>
                     <td>Görsel ve İşitsel Kayıtlar</td>
                     <td>Görsel ve İşitsel kayıtlar v.b.(örn. çağrı merkezi kayıtları, görüntülü görüşme kayıtları, fotoğraf vb.)</td>
                  </tr>
               </tbody>
            </table>
            </li>
            <li className='top-li'>
              <span>Kişisel Verilerin Toplanma Yöntemi</span> 
               <p>
               Veri sorumlusu olarak Şirketimiz tarafından,  sizleri daha iyi tanıyarak ihtiyaçlarınızı anlamak, isteklerinize daha hızlı cevap verebilmek, sizlerle olan iletişimimizi geliştirerek sizlere daha iyi hizmet vermek, daha kişisel ürün, hizmet ve teklif sunabilmek, bunları yapabilmek için analiz yapmak, sizleri ürün ve hizmetlerimiz hakkında bilgilendirebilmek, ürün ve hizmetlerimiz hakkında memnuniyetinizi ölçmek, istekleriniz ve ihtiyaçlarınız doğrultusunda ürün ve hizmetlerimizi geliştirmek ve çeşitlendirmek, gerekli kalite ve standart denetimlerimizi ilgili kanun ve mevzuatların öngördüğü şekilde yapabilmek, bizim tabi olduğumuz kanun ve mevzuatlar tarafından öngörülen raporlama ve sair yükümlülüklerimizin yerine getirilmesi gibi amaçlar için kişisel verileriniz işlenebilecektir. 
Verileriniz; otomatik ya da otomatik olmayan yöntemlerle, internet sitesi, sosyal medya, mobil uygulamalar ve benzeri vasıtalarla sözlü, yazılı ya da elektronik olarak toplanabilecektir.
               </p>
               <p>
               (i) Kişisel Verilerinizin Sizden Elde Edilme Yöntemleri;
               </p>
               <span>Kısmen otomatik yöntemlerle;</span>
               <div>
                  <ul>
                     <li>Ürün ve hizmetlerimize başvurmanız</li>
                     <li>Bize yazmanız, (örneğin kayıtlı elektronik posta, elektronik tebligat, elektronik posta, posta, faks, kısa mesaj, sosyal medya yöntemleri ile diğer yazılı veya sözlü iletişim kanallarını kullanarak</li>
                     <li>Sosyal medya aracılığıyla olanlar dâhil olmak üzere, herhangi bir zamanda bize bilgi vermeniz.</li>
                  </ul>
                  <span>Otomatik yöntemlerle;</span>
                  <div>
                     <ul>
                        <li>
                        İnternet sitelerimizi ya da dijital hizmetlerimizi kullanmanız durumunda işlem güvenliğinizi sağlamak amacıyla işlem güvenliği veri kategorisinde yer alan dijital ortamları kullanımınız sırasında lokasyon bilgileriniz, cihazınızın bağlı olduğu IP adresi, ürün ve hizmetlerimize giriş ve çıkış bilgileriniz, kullandığınız cihazın türü, işletim sistemi türü bilgileri, şifre ve parola  bilgileriniz, bu hizmetlere nasıl erişim sağladığınız ve bunları nasıl kullandığınız ile ilgili bilgi edinebiliriz. Yine sizden lokasyon veri kategorinizde yer alan verilerinizi elde ederek bulunduğunuz yerdeki, ürün ve hizmetlerimizi daha verimli gerçekleştirilebilmesi amacıyla finans kurumu bilgilerinin paylaşılması (Örneğin; size en yakın banka şube bilgisi).
                        </li>
                     </ul>
                  </div>
                  <span>Kişisel Verilerinizin Üçüncü Kişilerden Elde Edilme Yöntemleri</span>
                  <div>
                     <ul>
                        <li>Anlaşma yapılmış kurum ve kuruluşlardan</li>
                        <li>İşverenler</li>
                        <li>Sizin adınıza hareket etmek üzere atanmış kişiler</li>
                        <li>Ticaret ve esnaf odaları gibi birlik ve dernekler</li>
                     </ul>
                  </div>
               </div>
            </li>
            <li>
               <span>Kişisel Verilerin İşlenme Amaçları ve Hukuki Sebepleri</span>
               <p>
               Kişisel Verilerinizin Aktarılması Kişisel verileriniz yukarıda sayılan amaçlar dâhilinde, KVKK ve yürürlükte olan mevzuata uygun olarak, Şirket içerisinde paylaşılabileceği gibi, sayılanlar ile sınırlı olmamak üzere; iş ortaklarımız, iş bağlantılarımız, ifa yardımcılarımız ve alt yüklenicilerimiz ile sunduğumuz hizmetin amacı doğrultusunda ya da düzenleyici denetleyici kurumlar ve resmi mercilerle ilgili mevzuatın öngördüğü durumlarda yurtiçine veya KVKK’nın kişisel verilerin aktarılmasına ilişkin 8. maddesi ve kişisel verilerin yurtdışına aktarılmasına ilişkin 9. maddesinde belirtilen şartlar çerçevesinde yurtdışına aktarılabilecektir. Kişisel Verilerinizin Toplanma Yöntemi ve Hukuki Sebebi Kişisel verileriniz, yukarıda belirtilen amaç ve kapsamda sözlü, yazılı veya elektronik olarak toplanabilir. Kişisel verileriniz, Şirketimiz veya Şirketimiz adına veri işleyen gerçek ya da tüzel kişiler tarafından sayılanlarla sınırlı olmamak üzere, internet sitesi, muhtelif sözleşmeler, mobil uygulamalar, SMS bildirimleri, elektronik posta, başvuru formları gibi benzeri araçlar üzerinden, Şirketimiz ile yapılan yazılı veya sözlü iletişimler vb. kanallar aracılığıyla sözlü, yazılı veya elektronik ortamda toplanmaktadır.
               </p>
               <p>
               Toplanan kişisel verileriniz aşağıda belirtilen gerekçelerle, KVKK’nın 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları kapsamında işlenecektir; bu amaçlardan başlıca olanları aşağıda listelenmiş olup, Depar.io faaliyet alanına göre aşağıda sayılmayan fakat kanunla sınırlı alanlarda da kişisel verilerinizi işleyebilecektir.
               </p>
               <div>
                  <ul>
                     <li>Depar.io tarafından sunulan ürün ve hizmetlerden sizleri faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından yapılması,</li>
                     <li>Depar.io tarafından beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek sizlere önerilmesi, müşteri portföyü oluşturulması,</li>
                     <li>Depar.io ve iş ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğinin temini,</li>
                     <li>Depar.io tarafından yürütülen iletişime yönelik idari operasyonlar, Depar.io’ya ait lokasyonların fiziksel güvenliğinin ve denetiminin sağlanması,</li>
                     <li>Şirketimizin müşteri değerlendirme/şikâyet yönetimi süreçleri, itibar araştırma süreçleri, hukuki uyum süreci, denetim v.b. süreçlerinin yürütülmesi,</li>
                     <li>Depar.io’nun ticari ve iş stratejilerinin belirlenmesi ve uygulanması.</li>
                  </ul>
               </div>
               <p>
               Üyelik sözleşmesinin tarafınızca imzalamasının ardından Depar.io’nun sağladığı hizmetlerden yararlanmasanız dahi kişisel verileriniz işlenebilecek ve bu işlemler için ayrıca bir rıza vermeniz aranmayacaktır.
               </p>
            </li>
            <li>
               <span>Kişisel Verilerinizin Üçüncü Kişilere ve/veya Yurtdışına Aktarılması</span>
               <p>Kişisel Verileriniz yukarıda KVKK Madde 5.2 ve Madde 6.3 kapsamında belirlenen amaçların varlığı halinde açık rızanız aranmaksızın yahut Aydınlatma Metni’nde yer alan amaçlarla KVKK Madde 5.1 ve Madde 6.3 kapsamında bu metni imzalayarak vermiş olduğunuz açık rızanıza istinaden yurt içinde ve yurt dışında bulunan depolama, arşivleme, bilişim teknolojileri desteği (sunucu, hosting, program, bulut bilişim), güvenlik, çağrı merkezi, satış, pazarlama gibi alanlarda destek aldığımız üçüncü kişilere, iş birliği yapılan ve/veya hizmet alınan grup şirketlerine, iş ortaklarına, tedarikçi firmalar, bankalar, finans kuruluşları, hukuk, vergi vb. alanlarda destek alınan danışmanlık firmalarına, satış, pazarlama, hedefleme ve Depar.io’nun faaliyetleri ile ilgili sair alanlarda destek veren üçüncü kişilere (e-posta gönderimi, kampanya oluşturulması amacı ile reklam firmaları, ve benzeri) ile kurum ve kuruluşlara aktarılabilir, işlem sürecinde bu üçüncü kişiler tarafından gerekli değerlendirmenin yapılması amacıyla sınırlı ölçüde işlenebilir ve devir halinde devralan taraf varlıklarla birlikte bu varlıklara bağlı değerler olan kişisel verilerinizi kendisi veri sorumlusu olacak şekilde işlemeye devam edebilir. </p>
            </li>
            <li>
               <span>Çerezler</span>
               <p>
               Çerezler, ziyaret ettiğiniz internet siteleri tarafından tarayıcılar aracılığıyla bilgisayarınıza (ya da akıllı telefon veya tablet gibi diğer cihazlarınıza) kaydedilen ve genelde harf ve rakamlardan oluşan çok küçük metin dosyalarıdır. Çerezler, ziyaretçilere ilişkin isim, cinsiyet veya adres gibi kişisel verileri içermezler.
               </p>
               <p>
               Çerezler ziyaret ettiğiniz web sitesini yöneten sunucular tarafından oluşturulurlar. Böylelikle ziyaretçi aynı siteyi ziyaret ettiğinde sunucu bunu anlayabilir. Çerezler, web sitesi sahiplerine aynı ziyaretçinin siteyi yeniden ziyaret ettiğini gösteren kimlik kartlarına benzetilebilir.
               </p>
               <p>
               Çerezleri kullanırken kişisel verilerinizin korunmasına önem veriyoruz. Site’de kullanılan çerezlere ilişkin Kanun kapsamındaki yükümlülüklerimize ve bilgilendirmelerimize dair detaylı bilgiye https://depar.io/cerez-politikasi adresinden ulaşabilir ve Çerez Politikamızı inceleyebilirsiniz.
               </p>
            </li>
            <li>
               <span>işisel Verilerin Saklanması</span>
               <p>
               Depar.io tarafından elde edilen kişisel verilerin ilgili mevzuat uyarınca işleme şartlarının devam ettiği sürelere yönelik saklama ve işleme şartlarının ortadan kalkması halinde imha sürelerinin tespitinde aşağıda belirtilen ölçütlerden yararlanılmaktadır:
               </p>
               <p>
               İlgili mevzuatta söz konusu kişisel verinin saklanmasına ilişkin olarak bir süre öngörülmüş ise bu süreye uyulur. Anılan sürenin sona ermesiyle gerekli işlemler yapılır.
               </p>
               <p>
               İlgili mevzuatta öngörülen sürenin sona ermesi veya söz konusu verinin saklanmasına ilişkin olarak herhangi bir süre öngörülmemiş ise bilgilerin saklanmasının ilkelere uygunluğu; örneğin; verinin saklanmasında Depar.io’nun meşru bir amacının olup olmadığı sorgulanır. Mevzuata uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalktığı tespit edilen veriler silinir, yok edilir ya da anonim hale getirilir.
               </p>
            </li>
            <li>
               <span>Kişisel Veri Sahibi Olarak Haklarınız</span>
               <p>KVKK ve yürürlükte bulunan diğer mevzuat çerçevesinde kalmak kaydıyla,</p>
               <div>
                  <ol>
                     <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,
                     </li>
                     <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
                     <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                     <li>Yurtiçinde veya yurtdışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
                     <li>Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin, kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,,</li>
                     <li>6698 sayılı Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde, KVKK mevzuatında öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemlerin, kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                     <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</li>
                     <li>
                     Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde bu zararın giderilmesini talep etme haklarına sahipsiniz.
                     </li>
                  </ol>
               </div>
               <p>
               Bu haklarınızı kullanmak ile ilgili talebinizi yazılı olarak veya Kişisel Verileri Koruma Kurulu tarafından ayrı bir yöntem belirlenmesi halinde bu yönteme uygun olarak bize iletebilirsiniz. Yazılı talebinizi, Büyükdere Caddesi No:255 Nurol Plaza B 02 Sarıyer/İstanbul adresine ıslak imzalı olarak veya info@depar.io kayıtlı elektronik posta adresimize güvenli elektronik imza ile imzalanmış olarak gönderebilirsiniz.
               </p>
            </li>
         </ol>
      </Row>
   </TextBox>
);

export default ClarificationText;