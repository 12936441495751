import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { useSelector } from 'react-redux';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import { oneColLayout } from '../../../utils';
import { updateUser } from '../../../apiServices/userApi';

function GeneralInfo() {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { usersCurrent } = useSelector((state) => state.user);

    const validateGSMNo = (gsmNumber) =>
        gsmNumber?.charAt(0) === '0' ? gsmNumber?.replace('0', '') : gsmNumber;

    useEffect(() => {        
        if (usersCurrent) {
            form.setFields([
                { name: 'email', value: usersCurrent?.emailAddress},
                { name: 'name', value: usersCurrent.name },
                { name: 'surname', value: usersCurrent.surname },
                {
                    name: 'gsmNumber',
                    value: validateGSMNo(usersCurrent?.phoneNumber),
                },
            ]);
        }
    }, [usersCurrent, form]);

    const onSubmit = async (vals) => {
        setLoading(true);
        const response = await updateUser(vals);
        if (response) {
            form.resetFields();
            setLoading(false);
        }
    };

    return (
        <Row>
            <Col {...oneColLayout}>
                <div className="mb" style={{marginBottom:'20px'}}>
                    <Text type="title" bold className='page-title'>
                        Kullanıcı Bilgileri
                    </Text>
                    <Text>Kullanıcı bilgilerinizi görüntüleyin.</Text>
                </div>

                <Form form={form} name="accountSettings" onFinish={onSubmit}>
                    {/* <Text className="mt-big" type="subtitle">
                        Telefon Numarası:{' '}
                        <Text className="d-inline" type="subtitle" bold>
                            {user && user.gsmNumber}
                        </Text>
                    </Text> */}

                    <Form.Item name="name">
                        <Input disabled placeholder="Ad" />
                    </Form.Item>

                    <Form.Item name="surname">
                        <Input disabled placeholder="Soyad" />
                    </Form.Item>

                    <Form.Item name="email">
                        <Input disabled placeholder="E-posta adresi" />
                    </Form.Item>

                    <Form.Item name="gsmNumber">
                        <Input disabled placeholder="Cep Telefonu" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" size="large" loading={loading} block>
                            Kaydet
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
export default GeneralInfo;
