import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Divider, Upload, notification, Spin } from 'antd';
import { CheckCircleOutlined, DownloadOutlined, MailOutlined } from '@ant-design/icons';
import {useDispatch } from 'react-redux';
import Text from '../../components/Text';
import Icon from '../../components/Icon';
import { SuccessBox } from './styles';
import { LoadPdfTemlik ,LoadPdfTemlikDenizFactoring, LoadPdfTemlikDyb} from '../../utils/file';
import { uploadTemlikInvoicesApi } from '../../apiServices/supplierFinanceApi';
import {  fetchUserRisks, assignmentDownloaded } from '../../apiServices/userApi';
import {setRisk } from '../../store/reducers/supplierFinanceSlice'
import LocalStorageService from '../../services/LocalStorageService';


const SuccessMsg = ({ invoiceData, supplierId, discountId, financialInstitutionName, financialInstitutionTaxId }) => {
    const [isLoadingTemlikFile, setIsLoadingTemlikFile] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [mailLoading, setMailLoading] = useState(false);
    const [temlikFile, setTemlikFile] = useState(null);
    const [data, setData] = useState([])
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const btn1= 'indir';
    const btn2= 'email';

    
    const handleTemlikDownload = async (btnId) => { 
        if(financialInstitutionTaxId === '2910141668')
        {
            if(btnId==='email'){
                setMailLoading(true);
               
                const res = await LoadPdfTemlikDenizFactoring(invoiceData, btnId, supplierId, discountId, financialInstitutionName);
                    if(res){
                        setMailLoading(false);
                    }
              
            }else{
                await LoadPdfTemlikDenizFactoring(invoiceData, btnId, supplierId, discountId,financialInstitutionName);  
                await assignmentDownloaded({
                    applicationNumber : invoiceData.number,
                    invoiceNumbers : data
                })  
            }
        } else if(financialInstitutionTaxId === '2651554884'){
            if(btnId==='email'){
                setMailLoading(true);
               
                const res = await LoadPdfTemlikDyb(invoiceData, btnId, supplierId, discountId, financialInstitutionName);
                    if(res){
                        setMailLoading(false);
                    }
              
            }else{
                await LoadPdfTemlikDyb(invoiceData, btnId, supplierId, discountId,financialInstitutionName);  
                await assignmentDownloaded({
                    applicationNumber : invoiceData.number,
                    invoiceNumbers : data
                })  
            }
        } else if(financialInstitutionTaxId !== '2910141668' || financialInstitutionTaxId !== '2651554884' ){
            if(btnId==='email'){
                setMailLoading(true);
               
                const res = await LoadPdfTemlik(invoiceData, btnId, supplierId, discountId, financialInstitutionName);
                    if(res){
                        setMailLoading(false);
                    }
              
            }else{
                await LoadPdfTemlik(invoiceData, btnId, supplierId, discountId,financialInstitutionName);  
                await assignmentDownloaded({
                    applicationNumber : invoiceData.number,
                    invoiceNumbers : data
                })  
            }
        }
       

       
    };

    const getRisks =  async () => {
        const response = await fetchUserRisks(LocalStorageService.getAuthToken());
        if (response) {
          LocalStorageService.setBuyerRisks(response.supplierRisk);
          LocalStorageService.setBuyerLimit(response.supplierLimit);
          dispatch(setRisk(response))
        }
    };
  
    const uploadTemlikFile = async (file) => {
        setPageLoading(true)
        const isPdf = file.type === 'application/pdf' || file.type === 'pdf';
        if(isPdf){
            if (file &&  file.size < 10e6) {
                setShow(true)
              setTemlikFile(file);
              setIsLoadingTemlikFile(true);
              try {
                const formData = new FormData();
                formData.append('File', file);
                 await uploadTemlikInvoicesApi(formData, supplierId, discountId);
               
                    setIsLoadingTemlikFile(false);
                    notification.success({ message: 'Başarıyla yüklendi.' }); 
                    setTimeout(()=>{
                        window.location.reload(false);
                    }, 800); 
                    getRisks();
                    setPageLoading(false)
                
              } catch (error) {
                setIsLoadingTemlikFile(false);
                console.log(error)
                notification.error({
                  message: error.response.data.title,
                  duration: 3,
                });
              }
              return false;
            }
            if (file &&  file.size > 10e6){ 
                setShow(false);
                notification.error({ message: "Lütfen 10 MB'tan küçük bir dosya yükleyin" }); 
                return Upload.LIST_IGNORE
            }
            return false 
        }
        if(!isPdf){
            notification.error({
                message: `Yalnızca PDF uzantılı dosya yükleyebilirsiniz!`,
            }); 
            return Upload.LIST_IGNORE
        }
       
        
    };
   

    useEffect(() => {
        setData(
            invoiceData.invoices.length &&  invoiceData.invoices.filter((val) => (val.status === 'SECILDI'
            )).map((val) => (val.invoiceNumber))
           )
    },[])
  

    return (
        <>
        <Spin tip={pageLoading ? 'Dosya yükleniyor.Lütfen bekleyiniz.': 'Temlikname e-postanıza gönderiliyor.'} spinning={pageLoading || mailLoading} >
            <Row>
                <Col xl={24}>
                    <SuccessBox>
                        <CheckCircleOutlined className="tick-icon" />
                        <Text>Onayınız finans kurumuna bildirildi.</Text>
                        <Text style={{ fontWeight: 'bold' }}>İşlemi tamamlamak için:</Text>
                        <Divider type="vertical" className="files-divider" />
                        <div className="num-round">1</div>
                        <Text>İşleme ait hazırlanan temliknameyi buradan indirin.</Text>
                        <Button onClick={() => handleTemlikDownload(btn1)} className="download-temlik">
                            <DownloadOutlined className="download-icon" />
                            Temliknameyi İndir
                        </Button>
                        <span>ya da</span>
                        <Button onClick={() => handleTemlikDownload(btn2)} className="download-temlik" disabled={mailLoading}>
                            <MailOutlined className="download-icon" /> 
                            E-posta Olarak Gönder
                        </Button>
                        <Divider type="vertical" className="files-divider" />
                        <div className="num-round">2</div>
                        <Text>Temliknameyi bastırıp kaşeli ve imzalı olarak buraya yükleyin.</Text>
                        <Upload
                            listType="picture-card"
                            className="temlik-upload"
                            multiple={false}
                            accept="application/pdf"
                            onRemove={() => setTemlikFile(undefined)}
                            loading={isLoadingTemlikFile}
                            beforeUpload={(file) => uploadTemlikFile(file)}
                            showUploadList={show}
                        >
                            {!temlikFile && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon icon="plus" color="inherit" />
                                    <Text className="d-block" bold>
                                        Temliknameyi Yükle 
                                    </Text>
                                </div>
                            )}
                        </Upload>
                        <Divider type="vertical" className="files-divider" />
                        <div className="num-round">3</div>
                        <Text>İmzali ve kaşeli bir kopyayı da şubeye iletin.</Text>
                    </SuccessBox>
                </Col>
            </Row>
        </Spin>  
        </>
    );
};

export default SuccessMsg;
