import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Form, Divider } from 'antd';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import { login } from '../../../apiServices/userApi';
import urls from '../../../routes/urls';
import { setEmail, setLoggedIn, setIsAdmin } from '../../../store/reducers/userSlice';
import LocalStorageService from '../../../services/LocalStorageService';

const LoginTab = ({ setActiveTabSignUp }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false); 
    
     const onLogin = async (userCredentials) => {       
        setLoading(true);
        const response = await login(userCredentials);           
        if(response && response.token){           
            dispatch(setLoggedIn(response.token));
            dispatch(setIsAdmin(response.isAdmin));
            LocalStorageService.setUserEmail(response.email);
            LocalStorageService.setIsAdmin(response.isAdmin);
            LocalStorageService.setIsLogout(false);
            history.push(urls.buyers);
            setLoading(false);
        }
       else if(response) {
            dispatch(setEmail(response));
            LocalStorageService.setUserEmail(response);
            history.push(urls.twoFactor);
            setLoading(false);
        } else{
            setLoading(false);
        }
       
    };

    return (
        <>
            <Text type="title" color="primaryDark">
                Giriş Yap 
            </Text>
            <Form
                name="login"
                initialValues={{ email: '', password: '' }}
                onFinish={onLogin}>
                <Form.Item
                    name="email"
                    rules={[
                        { type: 'email', message: 'Lütfen geçerli bir e-posta adresi giriniz!' },
                        {
                            required: true,
                            message: 'Lütfen email giriniz!',
                        },
                    ]}>
                    <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Lütfen şifrenizi giriniz!',
                        },
                    ]}>
                    <Input.Password placeholder="Şifre" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" loading={loading} block>
                        Giriş Yap
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Link to={urls.passwordChange}>
                        <Button type="link" disabled={loading}>
                            Parolamı unuttum
                        </Button>
                    </Link>
                </Form.Item>
            </Form>

            <Divider>
                <Text color="smoke">Henüz Hesabım Yok</Text>
            </Divider>
            <Button className="sign-up-btn" type="outline" size="large" onClick={setActiveTabSignUp} loading={loading} block>
                Ücretsiz Üye Ol
            </Button>
        </>
    );
};

LoginTab.propTypes = {
    setActiveTabSignUp: PropTypes.func.isRequired,
};

export default LoginTab;
