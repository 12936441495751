/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    discountInvoices: '',
    discountInvoice: '',
    certifiedInvoice: '',
    unapprovedInvoice: '',
    invoicesPendingPayment:'',
    paidInvoices:''
};

const fundSlice = createSlice({
    name: 'fund',
    initialState,
    reducers: {
        setDiscountInvoices(state, action) {
            state.discountInvoices = action.payload;
        },
        setInvoicesPending(state, action) {
            state.invoicesPendingPayment = action.payload;
        },
        setPaidInvoices(state, action) {
            state.paidInvoices = action.payload;
        },
        setDiscountInvoice(state, action) {
            state.discountInvoice = action.payload;
            state.certifiedInvoice = state.discountInvoice.invoices.filter( item => item.status === 'SECILDI'  || item.status ==='ON_ODEME_YAPILDI' || item.status ==='ODENDI')
            state.unapprovedInvoice = state.discountInvoice.invoices.filter( item => item.status !== 'SECILDI'  && item.status !=='ON_ODEME_YAPILDI' && item.status !=='ODENDI')
        },
    },
});

export const { setDiscountInvoices, setDiscountInvoice, setInvoicesPending, setPaidInvoices } = fundSlice.actions;
export default fundSlice.reducer;
