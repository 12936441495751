import React from 'react';
import { Col, Row, Form, Input, notification } from 'antd';
import { useSelector } from 'react-redux';
import {  useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import urls from '../../../routes/urls';
import { forgotPassword } from '../../../apiServices/userApi';
import { validatePassword } from '../../../utils/validators';

function PasswordNew() {
    const history = useHistory();
    const location = useLocation();
    const path = location.pathname.split('/');
    const updateId = path[2]
    const token = path[3]
    const [form] = Form.useForm();

    const { isResetPasswordLoading } = useSelector(({ user }) => user);    
  
    const handleResetPassword = async (vals) => {
        try{

            if(vals.newpassword !== vals.repeatpassword){
                notification.warning({
                    message:
                        'Şifre ile tekrar edilen şifre aynı olmalıdır.',
                });
            } else{
              const res =  await forgotPassword({
                    userId : updateId,
                    resetPasswordToken: token,
                    newPassword : vals.newpassword,
                    newPasswordConfirmation : vals.repeatpassword
                });
               
                if(res.status === 204){
                    history.push(urls.supplierFinancing);
                    form.resetFields();
                } 
            } 

        }catch(e){
            console.log(e)
        }
        
    };

    return (
        <Row>
            <Col xs={24} md={12} lg={6} style={{ margin: '30px auto' }}>
                <Text type="title" style={{  marginBottom:'20px'}}>
                    Parolamı Güncelle 
                </Text>
                <Text style={{ marginBottom:'20px', display:'block'}}>Lütfen belirlemiş olduğunuz yeni şifrenizi onaylamak için iki defa giriniz.</Text>                 
                    <Form 
                        className="d-block update-input"
                        name="passwordReset" 
                        onFinish={handleResetPassword}
                        labelCol={{ span: 7 }}
                        layout="horizontal"
                        form={form}
                        >                            
                        <Form.Item
                            name="newpassword"
                            size='large'
                            rules={[
                                {
                                    required: true,
                                    validator: (_, value) => validatePassword(value)
                                },
                            ]}>
                            <Input.Password placeholder="Yeni Şifre" autoComplete="new-password" />
                        </Form.Item>

                        <Form.Item
                            name="repeatpassword"
                            rules={[
                                {
                                    required: true,
                                    validator: (_, value) => validatePassword(value)
                                },
                            ]}>
                            <Input.Password placeholder="Yeni Şifre Tekrarla" autoComplete="new-password" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{width:'100%', margin:'0 auto', display:'block'}}
                                size="large"
                                loading={isResetPasswordLoading}
                                block>
                                Kaydet
                            </Button>
                        </Form.Item>
                    </Form>
            </Col>
        </Row>
    );
}

export default PasswordNew;
