/* eslint-disable no-empty */
import {notification} from 'antd';
import {apiV1,apiV2, endpoints} from '../services/apis';
import LocalStorageService from '../services/LocalStorageService';

const login = async (payload) => {
    try {
        const { email, password } = payload;
        const data = await apiV1.post(endpoints.login, { email, password });
        if (data.token) {
            LocalStorageService.setAuthToken(data.token);
        }
        return data;
    } catch (e) {
        if(e.data.Status === 401 && e.data.Type.includes('AuthenticationException')){
            notification.error({
                message: `Kullanıcı adınız ya da şifreniz hatalıdır.`,
            });
        }
        if(e.data.Status === 403){
            notification.warning({
                message: `Devam edebilmek için şifrenizi değiştirmeniz gerekmektedir. Kayıtlı mailinizdeki bağlantıyı takip ederek şifrenizi değiştirebilirsiniz.`,
            });
            await apiV1.post(endpoints.expiredPassword, { email: payload.email });           
        }
        if(e.data.Status === 404 && e.data.Type.includes('UserNotFoundException')){
            notification.error({
                message: `Kullanıcı adı veya şifresi hatalıdır.`,
            });
        }
        if(e.data.Status === 401 && e.data.Type.includes('UserHasLockedException')){
            notification.error({
                message: `Birden fazla başarısız giriş denemesi gerçekleştirdiniz. Lütfen 1 dakika sonra tekrar deneyiniz.`,
            });
        }
    }
};

const twoFactor = async (email, code) => {
    try {        
        const data = await apiV1.post(endpoints.twoFactor, { email, code });       
        if (data.token) {
            LocalStorageService.setAuthToken(data.token);
        }
        return data;
    } catch (e) {
        console.log(e);
        if(e.data.Status === 403 ){
            notification.error({
                message: `Doğrulama kodunuz hatalıdır veya süresi dolmuştur.`,
            });
        }     
    }
};

const logoutApi = async () => {
    try {        
        const data = await apiV1.post(endpoints.logout);      
        return data;
    } catch (e) {
        console.log(e);
           
    }
};

const changePassword = async (supplierId, value) => {
    try {       
        const data = await apiV1.put(`${endpoints.changePassword}/${supplierId}`,{
            oldPassword : value.CurrentPassword,
            newPassword : value.newPassword
        });
        notification.success({ message: 'Şifreniz başarıyla güncellenmiştir.' });
        return data;
    } catch (e) 
    {
        if(e.status === 400){
            notification.error({
                message: `Eski şifreniz yanlış`,
            });
        }
    }
};

const logout = () => {
    LocalStorageService.removeAuthToken();
    LocalStorageService.removeBuyerUser();
    LocalStorageService.removeBuyerLimit();
    LocalStorageService.removeBuyerRisk();
    LocalStorageService.removeUserEmail();
    LocalStorageService.removeIsAdmin();
    window.location.reload(); 
};

const fetchUser = async (token) => {
    try {
        const response = await apiV1.get(endpoints.fetchUser, {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        });
        return response;
    } catch (e) {console.log(e)}
};

const usersCurrent = async (token) => {
    try {
        const response = await apiV1.get(endpoints.usersCurrent, {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        });
        return response;
    } catch (e) {console.log(e)}
};

const fetchUserRisks = async (token) => {    
    try {
        const response = await apiV1.get(endpoints.fetchRisks, {
            headers: {
                Authorization: `Bearer ${token}`,
                accept: 'application/json',
            },
        });
        return response;
    } catch (e) {console.log(e)}
};

const assignmentDownloaded = async (data) => {
    try {
        const response = await apiV1.post(endpoints.assignmentDownloaded, data );       
        return response;
    } catch (error) {console.log(error)}
};


const resetPasswordSendCode = async (payload) => {
    try {
        const { data } = await apiV1.post(endpoints.sendResetPasswordCode, { emailAddress: payload });      
        notification.info({ message: 'Lütfen e-postanızı kontrol ediniz.' });
        return data;
    } catch (e) {
        if(e.status === 404 && e.data.Type.includes('UserNotFoundException')){
            notification.warning({
                message: `Kayıt olurken kullandığınız e-posta adresiyle kayıtlı bir üye bulunmaktadır.`,
            });
        }
    }
};

const resetPasswordstring = async (payload) => {
    try {
        const { data } = await apiV2.patch(endpoints.usersResetToken, payload);      
        notification.info({ message: 'Şifreniz başarıyla güncellenmiştir.' });
        return data;
    } catch (e) {
        if(e.status === 400 && e.data.Type.includes('IncorrectOldPasswordException')){
            notification.warning({
                message: `Eski şifrenizi hatalı girdiniz. Lütfen kontrol ediniz.`,
            });
        }
        if(e.status === 409 && e.data.Type.includes('NewPasswordCannotBeOldPasswordException')){
            notification.warning({
                message: `Eski şifre ile yeni şifre aynı olamaz.`,
            });
        }
        if(e.status === 409 && e.data.Type.includes('LastThreePasswordMatchedException')){
            notification.warning({
                message: `Yeni parola son üç parola ile aynı olamaz`,
            });
        }
        if(e.status === 400 && e.data.Type.includes('PasswordDoesNotMatchException')){
            notification.warning({
                message: `Yeni şifre ile şifre onayınız aynı olmalıdır.`,
            });
        }
        if(e.status === 400 && e.data.Messages ){
            const length = e.data.Messages.length - 1;
            let x = 0;
            for( x=0; x < length + 1 ; x++){
                notification.warning({
                    message: ` ${e.data.Messages[x]}`  
                });
            } 
        }
    }
};

const forgotPasswordSendCode = async (payload) => {
    try {
        const { data } = await apiV1.post(endpoints.sendForgotPasswordCode, { emailAddress: payload });        
        notification.info({ message: 'Lütfen e-postanızı kontrol ediniz.' });
        return data;
    } catch (e) {
        console.log(e);
        if(e.status === 404 && e.data.Type.includes('UserNotFoundException')){
            notification.warning({
                message: `Kullanıcı email adresi hatalıdır.`,
            });
        }
    }
};

const resetPassword = async (payload) => {
    try {
        const { data } = await apiV1.patch(endpoints.resetPassword, payload);
        notification.success({ message: 'Şifreniz başarıyla güncellenmiştir.' });
        return data;
    } catch (e) {
        if(e.status === 400 && e.data.Messages ){
            const length = e.data.Messages.length - 1;
            let x = 0;
            for( x=0; x < length + 1 ; x++){
                notification.warning({
                    message: ` ${e.data.Messages[x]}`  
                });
            } 
        }
    }
};

const forgotPassword = async (payload) => {
    try {
        const  response = await apiV1.patch2(endpoints.forgotPassword, payload);
        notification.success({ message: 'Şifreniz başarıyla güncellenmiştir.' });
        return response;
    } catch (e) {
        if(e.status === 409 && e.data.Type.includes('LastThreePasswordMatchedException')){
            notification.warning({
                message: `Yeni parola son üç parola ile aynı olamaz`,
            });
        }
        if(e.status === 400 && e.data.Messages ){
            const length = e.data.Messages.length - 1;
            let x = 0;
            for( x=0; x < length + 1 ; x++){
                notification.warning({
                    message: ` ${e.data.Messages[x]}`  
                });
            } 
        }
    }
};

const preSignUp = async (payload) => {
    try {
        const gsmNumber = `0${payload.gsmNumber}`;
        const newUser = { ...payload, gsmNumber };
        await apiV1.post(endpoints.preSmeUserRegistiration, { ...payload, gsmNumber });

        return newUser;
    } catch (e) {console.log(e)}
};

const signUp = async (payload) => {
    try {
        const newUser = await apiV2.post(endpoints.signup, { ...payload });
        notification.success({
            message: `Kaydınız başarılı bir şekilde oluşturuldu. Alıcılarınızın yüklediği faturalardan iskonto ettirmek istediklerinizi seçebilirsiniz.`,
        });        
        if (newUser.token) {
            LocalStorageService.setAuthToken(newUser.token);
        }
        return newUser;
    } catch (e) {
        if(e.status === 409 && e.data.Type.includes('TaxIdAlreadyExistsException')){
            notification.warning({
                message: `Bu vergi numarası ile bir kayıt bulunmaktadır.`,
            });
        }
        if(e.status === 409 && e.data.Type.includes('EmailAddressAlreadyExistsException')){
            notification.warning({
                message: `Kayıt olurken kullandığınız e-posta adresiyle kayıtlı bir üye bulunmaktadır.`,
            });
        }
        if(e.status === 409 && e.data.Type.includes('InvalidSupplierTaxIdException')){
            notification.warning({
                message: `Kayıt olabilmeniz için alıcınızın sizi tanımlaması gerekmektedir. Lütfen alıcınız ile iletişime geçiniz.`,
            });
        }
        if(e.status === 400 && e.data.Messages ){
            const length = e.data.Messages.length - 1;
            let x = 0;
            for( x=0; x < length + 1 ; x++){
                notification.warning({
                    message: ` ${e.data.Messages[x]}`  
                });
            } 
        }
    }
};

const sendCode = async (payload) => {
    try {
        const phoneNumber = `0${payload.gsmNumber}`;
        const newUser = await apiV1.post(
            endpoints.validateOTP,
            { code: payload.code, phoneNumber },
            { headers: { 'X-Platform': 'Web'} }
        );
        if (newUser.isOtpValid) {
            notification.success({ message: 'Telefonunuz doğrulandı..' });
            return newUser;
        }
        notification.error({ message: 'Geçersiz telefon kodu girdiniz.Tekrar deneyiniz.' });
        return '';
    } catch (e) {console.log(e)}
};

const getCode = async (payload) => {
    try {
        const phoneNumber = `0${payload}`;
         await apiV1.post(
            endpoints.getCode,
            { phoneNumber },
            { headers: { 'X-Platform': 'Web'} }
        );

        return phoneNumber;
    } catch (e) {console.log(e)}
};

const getDocType = async () => {
    try {
        const newUser = await apiV1.get(endpoints.docType, { headers: { 'X-Platform': 'Web' } });
        return newUser.documentTypes;
    } catch (e) {console.log(e)}
};

const getDocsData = async (supplierId) => {
    try {
        const response = await apiV1.get(endpoints.getDocsEndpoint(supplierId),
         { headers: { 'X-Platform': 'Web' } });
        return response;
    } catch (e) {console.log(e)}
};

const getDocDownload = (supplierId, docId, name) => apiV1.downloadFile(endpoints.getDocDownloadEndpoint(supplierId, docId), name);

const fetchSupplierTitle = async (data) => {
 
    try {
        const response = await apiV1.get(
            endpoints.getSupplierTitel(data.taxId, data.provinceId, data.taxAdmin, data.securityCode),
            { headers: { 'X-Platform': 'Web' } }
        );        
        return response;
    } catch (e) {
        if(e.data.Status === 500 && e.data.Type.includes('TitleDoesNotFoundException')){
            notification.error({
                message: `Girdiğiniz bilgilerle eşleşen bir ünvan bulunamadı.`,
            });
        } 
        if(e.data.Status === 404 && e.data.Type.includes('TitleDoesNotFoundException')){
            notification.error({
                message: `Firma bilgilerini veya kodunuzu yanlış girdiniz. Tekrar deneyiniz. `,
            });
        } 
    }
};

const uploadDoc = async (payload) => {
    try {
        const { formData } = payload;       
        const newUser = await apiV1.post(
            `${endpoints.signup}/${payload.supplierId}/documents?document-type-id=${payload.documentTypeId}`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        return newUser;       
    } catch (e) {
        console.log(e)
        if(e.data.Status === 400 && e.data.Type.includes('InvalidFileTypeException')){
            notification.error({
                message: `Yalnızca PDF uzantılı dosya yükleyebilirsiniz! `,
            });
        } 
        if(e.data.Status === 413 && e.data.Type.includes('BadHttpRequestException')){
            notification.error({
                message: `En fazla 10MB büyüklüğünde dosya yükleyebilirsiniz. `,
            });
        } 
    }
};

const updateUser = async ({ payload }) => {
    try {
        let userData = { ...payload };
        if (payload.gsmNumber) {
            const gsmNumber = `0${payload.gsmNumber}`;
            userData = { ...payload, gsmNumber };
        }
        if (userData !== {}) {
            const { data } = await apiV1.patch(endpoints.smeUsers, userData);
            notification.success({ message: 'Hesabınız başarıyla güncellendi.' });
            return data;
        }
    } catch (error) {console.log(error)}
};

const getSupOnboading = async (supId) => {
    try {
        const response = await apiV1.get(endpoints.supplierOnboarding(supId));
        return response;
    } catch (error) {console.log(error)}
};

const setSupOnboading = async (supId, status) => {
    try {
        const response = await apiV1.post(endpoints.supplierOnboarding(supId), { status });
        return response;
    } catch (error) {console.log(error)}
};

const getBuyers = async (supId) => {
    try {
        const response = await apiV1.get(endpoints.buyersList(supId));
        return response;
    } catch (error) {console.log(error)}
};

const getBanks = async () => {
    try {
        const response = await apiV1.get(endpoints.banksList);
        return response;
    } catch (error) {console.log(error)}
};

const setBuyers = async (supId, buyerId) => {
    try {
        const response = await apiV1.post(endpoints.buyersList(supId), { buyerId });
        if (response) {
            LocalStorageService.setAuthToken(response.token);
          }
        return response;
    } catch (error) {console.log(error)}
};

const deleteDocs = async (supId, documentId) => {
    try {
        const response = await apiV1.delete(endpoints.deleteDocs(supId, documentId));
        return response;
    } catch (error) {console.log(error)}
};

const verifyCaptchaToken = async (token) =>
{
    try {
        const response = await apiV1.post(endpoints.validateRecaptcha, {token});
        return response;
    }
    catch (error) {console.log(error)}
}


export {
    login,
    logout,
    fetchUser,
    resetPasswordSendCode,
    resetPassword,
    preSignUp,
    signUp,
    sendCode,
    getCode,
    getDocType,
    uploadDoc,
    updateUser,
    getDocsData,
    getDocDownload,
    fetchSupplierTitle,
    setSupOnboading,
    getSupOnboading,
    getBuyers,
    changePassword,
    setBuyers,
    fetchUserRisks,
    deleteDocs,
    forgotPasswordSendCode,
    forgotPassword,
    assignmentDownloaded,
    getBanks,
    twoFactor,
    verifyCaptchaToken,
    resetPasswordstring,
    logoutApi,
    usersCurrent
};
