import HttpService from './HttpService';

const v1ApiUrls = process.env.REACT_APP_API_ADDRESS;

const v2ApiUrls = process.env.REACT_APP_API_ADDRESS_V2;

const generalUrl = process.env.REACT_APP_GENERAL_API_ADDRESS;

const v1NotificationApiUrls = process.env.REACT_APP_NOTIFICATION_API_ADDRESS;

const notificationApiKeys = process.env.REACT_APP_NOTIFICATION_KEY;


// const notificationApiKeys = {
//     dev: 'TmFmbzB4RmhUQ1BxWmpsSGFhZmF0RmNuSDNibmJZWFhnOGVMVmxkekcwWWxXZWY0YnM3MkNtYjJseVFQYXZuNw==',
//     test: 'TmFmbzB4RmhUQ1BxWmpsSGFhZmF0RmNuSDNibmJZWFhnOGVMVmxkekcwWWxXZWY0YnM3MkNtYjJseVFQYXZuNw==',
//     staging: 'NmRpN1VKT2hDbjdaSGVRTkpYV1pwWkJncHAzRTB0ZWlFcmt3QjVFRkFLMHBmWkRYNktBV3l4Uk5haXZ2MmVWUw==',
//     prod: 'dnhIZG9DcjRoSUNBVUtTUGdBcmRaNFZwMkVYREpCUkxwTWtxNmd6ZkxLU3NrVFJsc2NOM2JUREtPc2Mxd05tUQ==', 
// };

export const endpoints = {
    login: '/users/authenticate',
    logout:'/users/logout',
    twoFactor: '/otp/validate/mail',
    changePassword: '/suppliers',
    getCode: 'otp/send/phone',
    validateOTP: 'otp/validate/sms',
    signup: '/suppliers',
    provinces: '/provinces',
    companies: '/companies/kinds',
    docType: '/document-types',
    invoices: 'invoices',
    uploadInvoices: '/discounts',
    fetchUser: '/suppliers/current',
    financialInstitution : '/financial-institutions',
    fetchRisks: '/risks',
    sendResetPasswordCode:'/users/reset-password-token',
    sendForgotPasswordCode:'/users/forgot-password-token',
    resetPassword:'/users/reset-password',
    forgotPassword:'/users/forgot-password',
    assignmentDownloaded:'/assignment-downloaded',
    banksList:'/risks',
    calculateDiscountAmount:'/discounts/calculate-discount-amount',
    validateRecaptcha:'/users/recaptcha',
    usersResetToken:'/users/reset-password',
    employees:'/employees',
    usersCurrent: '/users/current',
    expiredPassword:'/users/expired-password',

    uploadTemlikInvoice: (supplierId, discountId) => `/suppliers/${supplierId}/discounts/${discountId}/conveyance`,
    getDistrictsEndpoint: (provinceId) => `/provinces/${provinceId}/districts`,
    getTaxOfficesEndpoint: (provinceId) => `/provinces/${provinceId}/tax-administrations`,
    getDocsEndpoint: (supplierId) => `suppliers/${supplierId}/documents`,
    getDocDownloadEndpoint: (supplierId, docId) => `suppliers/${supplierId}/documents/${docId}`,
    getSupplierTitel: (taxId, provinceId, taxAdmin,securityCode) =>
        `/suppliers/title?taxId=${taxId}&provinceId=${provinceId}&taxAdministration=${taxAdmin}&securityCode=${securityCode}`,
       
    getFundDetailEndpoint: (fundId) => `/funds/${fundId}/sme-users`,
    getApproveFundEndpoint: (fundId) => `/funds/${fundId}/approves`,
    getFinancialDataDetailEndpoint: (financialDataId) => `/sme-users/financial-data/${financialDataId}`,
    
    supplierOnboarding: (supplierId) => `/users/${supplierId}/guide-status`,
    getUploadInvoices: (supplierId) => `/suppliers/${supplierId}/discounts`,
    buyersList: (supplierId) => `/suppliers/${supplierId}/buyers`,
    deleteDocs: (supplierId, documentId) => `/suppliers/${supplierId}/documents/${documentId}`,
    rates: (maturity) => `/rates?maturity=${maturity}`,
    businessDay: (day, month, year) => `/business-days/${day}.${month}.${year}`,
    importTemplate: (discountId) => `/discounts/${discountId}/conveyance`,
    conveyanceMail: (supplierId, discountId) => `suppliers/${supplierId}/discounts/${discountId}/conveyance/mail`,
    deleteUser: (userId) => `/employees/${userId}`,

    zendeskRequest: '/requests',
};

const enviroment = process?.env?.REACT_APP_NODE_ENV || 'dev';

export const apiV1 = new HttpService({ baseURL: `${v1ApiUrls}` });
export const apiV2 = new HttpService({ baseURL: `${v2ApiUrls}` });
export const apiV3 = new HttpService({ baseURL: `${generalUrl}` });
export const generalApi = new HttpService({ baseURL: `${generalUrl}` });
export const notificationApiV1 = new HttpService({ baseURL: `${v1NotificationApiUrls}` });
export const notificationApiKey = `${notificationApiKeys[enviroment]}`;

// export const apiBuyerV3 = new HttpService({ baseURL: `${v1BuyerApiUrls[enviroment]}` });

export const googleConfig = {
    clientId: '531915396144-pbv5ut3vfr0bqra8ecbkesf22jjlu0tl.apps.googleusercontent.com',
};

export const appleConfig = {
    clientId: 'app.netlify.tender-kalam-09ae07',
};
