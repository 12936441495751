import React, { useState, useEffect } from 'react';
import {  Col, Form, Row, Upload, Spin, notification } from 'antd';
import {  CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import Icon from '../../../components/Icon';
import Text from '../../../components/Text';
import { uploadDoc, getDocType, getDocsData, 
    getDocDownload, deleteDocs
} from '../../../apiServices/userApi';
import { setDocType } from '../../../store/reducers/userSlice';

const antIcon = <LoadingOutlined style={{ fontSize: 17, color: 'black', marginRight: 10 }} spin />;

const LegalInfo = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(-1);
    const [pageLoading, setPageLoading] = useState(false);
    const { user, docTypes } = useSelector((state) => state.user);
    const [legalDocs, setLegalDocs] = useState({
        taxBoard: undefined,
        authorizedSignatures: undefined,
        activityCertificate: undefined,
    });
 
    const getDocs = async () => {
        const response = await getDocsData(user.id || user.supplierId);
        if (response) {
            const taxBoard = response?.documents.find((val) =>  val.documentTypeId === '9d4e2f8a-909d-423a-8fbe-a4d05c8a5849' );
            const authorizedSignatures = response?.documents.find((val) => val.documentTypeId  === 'bd015910-d302-4a5e-b9e7-ffffe9db7e7e');
            const activityCertificate = response?.documents.find((val) => val.documentTypeId  === 'ca17b4f7-6602-4986-af0a-e8e1b47db843');
            setLegalDocs({
                taxBoard: taxBoard
                    ? {
                          uid: taxBoard?.id,
                          documentType: taxBoard?.id,
                          name: taxBoard?.name,
                      }
                    : undefined,
                authorizedSignatures: authorizedSignatures
                    ? {
                          uid: authorizedSignatures?.id,
                          documentType: authorizedSignatures?.id,
                          name: authorizedSignatures?.name,
                      }
                    : undefined,
                activityCertificate: activityCertificate
                    ? {
                          uid: activityCertificate?.id,
                          documentType: activityCertificate?.id,
                          name: activityCertificate?.name,
                      }
                    : undefined,
            });      
        }
    };
  

    const getDocTypeData = async () => {
        const response = await getDocType();
        if (response) {
            dispatch(setDocType(response));
        }
    };

    const handleDocumentUpload = async (file, key) => {
        setLegalDocs({ ...legalDocs, [key]: file });
        const formData = new FormData();
        formData.append('File', file);
        if( key === 'taxBoard') {
            const documentTypeId = docTypes.find((doc) => doc.name === 'Vergi Levhası');
            if (documentTypeId) {
                setPageLoading(true);
                await uploadDoc({
                    formData,
                    supplierId: user.id,
                    documentTypeId: documentTypeId.id,
                });
                getDocs();
                setPageLoading(false);
            }
        }
        if(key === 'authorizedSignatures') {
            const documentTypeId = docTypes.find((doc) => doc.name === 'İmza Sirküleri');
            if (documentTypeId) {
                setPageLoading(true);
                await uploadDoc({
                    formData,
                    supplierId: user.id,
                    documentTypeId: documentTypeId.id,
                });
                getDocs();
                setPageLoading(false);
            }
        }
        if(key === 'activityCertificate') {
            const documentTypeId = docTypes.find((doc) => doc.name === 'Faaliyet Belgesi');
            if (documentTypeId) {
                setPageLoading(true);
                await uploadDoc({
                    formData,
                    supplierId: user.id,
                    documentTypeId: documentTypeId.id,
                });
                getDocs();
                setPageLoading(false);
            }
        }
      
        return false;
    };

    const handleDeleteUpload = async (key) => {
        setLegalDocs({ ...legalDocs, [key]: undefined }); 
        const supplierId = user.id;

        if(key === 'taxBoard') {
            const documentTypeId = legalDocs.taxBoard;
            if (!documentTypeId.documentType) {
                notification.warning({
                    message: 'Uyarı',
                    description: (
                      <div className='common-err'>
                        Bir hata oluştu.Tekrar deneyiniz.
                      </div>
                    ),
                    duration: 2
                  })
            }else{
                await deleteDocs(supplierId,documentTypeId.documentType);
            }
        }

        if(key === 'authorizedSignatures') {
            const documentTypeId = legalDocs.authorizedSignatures;
            if (!documentTypeId.documentType) {
                notification.warning({
                    message: 'Uyarı',
                    description: (
                      <div className='common-err'>
                        Bir hata oluştu.Tekrar deneyiniz.
                      </div>
                    ),
                    duration: 2
                  })                
            }else{
                await deleteDocs(supplierId,documentTypeId.documentType);
            }
        }
        if(key === 'activityCertificate') {
            const documentTypeId = legalDocs.activityCertificate;
            if (!documentTypeId.documentType) {
                notification.warning({
                    message: 'Uyarı',
                    description: (
                      <div className='common-err'>
                        Bir hata oluştu.Tekrar deneyiniz.
                      </div>
                    ),
                    duration: 2
                  })                
            }else{
                await deleteDocs(supplierId,documentTypeId.documentType);
            }
        }
      
        return true;
    };


    useEffect(() => {               
        if(user && (user.id || user.supplierId)) {           
            getDocTypeData();
            getDocs();         
        }      
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const downloadDoc = async (id, name) => {
        setLoading(id);
        const response = await getDocDownload(user.id, id, name);
        if (response) {
            setLoading(-1);
        }
    }

    return (
        <Spin tip="Dosya yükleniyor.Lütfen bekleyiniz." spinning={pageLoading}>
        <Row>
            <Col>
                <div className="mb">
                    <Text type="title" bold className='page-title'>
                        Firma Belgeleri
                    </Text>
                    <Text>Firma belgelerinizi ekleyin veya güncelleyin.</Text>
                </div>
                <Form.Item name="taxBoard" className="mr">
                    <Text type="label" style={{ fontSize: 17, marginBottom: 0, marginTop: 10 }}>
                        Vergi Levhası
                    </Text>
                    <Text type="label" style={{ fontSize: 14 }}>
                        Şirkete ait vergi levhasını yükleyin.
                    </Text>
                    {!legalDocs.taxBoard ? (
                        <Upload
                            className="legal-doc"
                            listType="picture-card"
                            accept="application/pdf"
                            multiple={false}
                            onRemove={() => handleDeleteUpload('taxBoard')}
                            beforeUpload={(file) => handleDocumentUpload(file, 'taxBoard')}>
                            {!legalDocs.taxBoard && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon icon="plus" color="inherit" />
                                    <Text className="d-block" bold>
                                        Vergi Levhası
                                    </Text>
                                </div>
                            )}
                        </Upload>
                    ) : !legalDocs.taxBoard.file && (
                        <div className="legal-doc-download">
                            <div role="button" tabIndex={-1} aria-hidden="true" onClick={() => downloadDoc(legalDocs.taxBoard.uid, legalDocs.taxBoard.name)}>
                                <Text style={{marginRight: 10}} className="d-block" bold>
                                    {loading === legalDocs.taxBoard.uid ? <Spin indicator={antIcon} /> : <Icon icon="download" color="inherit" />}
                                    {legalDocs.taxBoard.name}
                                </Text>
                            </div>
                            <CloseOutlined onClick={() => handleDeleteUpload('taxBoard')}/>
                        </div>
                    )}
                </Form.Item>
                <Form.Item name="authorizedSignatures" className="mr">
                    <Text type="label" style={{ fontSize: 17, marginBottom: 0 }}>
                        İmza Sirküleri
                    </Text>
                    <Text type="label" style={{ fontSize: 14 }}>
                        Güncel imza sirkülerini yükleyin.
                    </Text>
                    {!legalDocs.authorizedSignatures ? (
                        <Upload
                            className="legal-doc"
                            listType="picture-card"
                            accept="application/pdf" 
                            multiple={false}
                            onRemove={() => handleDeleteUpload('authorizedSignatures')}
                            beforeUpload={(file) => handleDocumentUpload(file, 'authorizedSignatures')}>
                            {!legalDocs.authorizedSignatures && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon icon="plus" color="inherit" />
                                    <Text className="d-block" bold>
                                        İmza Sirküleri
                                    </Text>
                                </div>
                            )}
                        </Upload>
                    ) : !legalDocs.authorizedSignatures.file && (
                        <div className="legal-doc-download">
                            <div role="button" tabIndex={-1} aria-hidden="true" onClick={() => downloadDoc(legalDocs.authorizedSignatures.uid, legalDocs.authorizedSignatures.name)}>
                                <Text style={{marginRight: 10}} className="d-block" bold>
                                    {loading === legalDocs.authorizedSignatures.uid ? <Spin indicator={antIcon} /> : <Icon icon="download" color="inherit" />}
                                    {legalDocs.authorizedSignatures.name}
                                </Text>
                            </div>
                            <CloseOutlined onClick={() => handleDeleteUpload('authorizedSignatures')}/>
                        </div>
                    )}
                </Form.Item>
                <Form.Item name="activityCertificate" className="mr">
                    <Text type="label" style={{ fontSize: 17, marginBottom: 0 }}>
                        Faaliyet Belgesi
                    </Text>
                    <Text type="label" style={{ fontSize: 14 }}>
                        Bağlı olduğunuz ticaret odasından temin ettiğiniz faaliyet belgenizi yükleyin.
                    </Text>
                    {!legalDocs.activityCertificate ? (
                        <Upload
                            className="legal-doc"
                            listType="picture-card"
                            accept="application/pdf"
                            multiple={false}
                            onRemove={() => handleDeleteUpload('activityCertificate')}
                            beforeUpload={(file) => handleDocumentUpload(file, 'activityCertificate')}>
                            {!legalDocs.activityCertificate && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon icon="plus" color="inherit" />
                                    <Text className="d-block" bold>
                                        Faaliyet Belgesi
                                    </Text>
                                </div>
                            )}
                        </Upload>
                    ) : !legalDocs.activityCertificate.file && (
                        <div className="legal-doc-download">
                            <div role="button" tabIndex={-1} aria-hidden="true" onClick={() => downloadDoc(legalDocs.activityCertificate.uid, legalDocs.activityCertificate.name)}>
                                <Text style={{marginRight: 10}} className="d-block" bold>
                                    {loading === legalDocs.activityCertificate.uid ? <Spin indicator={antIcon} /> : <Icon icon="download" color="inherit" />}
                                    {legalDocs.activityCertificate.name}
                                </Text>
                            </div>
                            <CloseOutlined onClick={() => handleDeleteUpload('activityCertificate')}/>
                        </div>
                    )}
                </Form.Item>
              
            </Col>
        </Row>
        </Spin>
    );
}
export default LegalInfo;
