import React from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import Text from '../../Text';
import StyledFooter from './styles';
import LOGO_WHITE from '../../../assests/depario-white.png';


function Footer() {
    const { isLoggedIn } = useSelector((state) => state.user);
    return (
        <StyledFooter>
            <Row justify="space-between" style={{alignItems:'center'}}>
                <Col md={4} lg={4} >
                    <div className="text-l">
                        <img src={LOGO_WHITE} alt="depar logo" style={{width:'200px'}} />
                    </div>                   
                </Col> 
                <Col md={20} lg={20} >
                    <div style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                        {
                            isLoggedIn ? 
                            <a href="https://cdn.depar.io/documents/depario-tedarikci-kk.pdf#toolbar=0" target='_blank' rel="noreferrer"  
                            style={{textAlign: 'center', color: '#fff', width: '100%', textDecoration:'underline'}}>Kullanım Kılavuzu</a>
                            :
                            ''
                        }
                      
                        <Text style={{textAlign: 'center', color: '#fff', width: '100%', fontSize:'14px', fontWeight:'normal' }}>
                        © 2024 – depar.io bir Neo Elektronik Ticaret Bilişim Teknolojileri Yazılım ve Danışmanlık A.Ş. kuruluşudur. Tüm hakları saklıdır.
                        </Text>
                    </div>
                   
                </Col>
              
            </Row>            
        </StyledFooter>
    );
}

export default Footer;
