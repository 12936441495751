import React from 'react';
import { Col, Row, Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import {  forgotPasswordSendCode} from '../../../apiServices/userApi';

function PasswordUpdate() {

    const [form] = Form.useForm();
    const { isResetPasswordSendCodeLoading } = useSelector(({ user }) => user);
 
    const sendCode = async ({ email }) => {
        await forgotPasswordSendCode(email);
        form.resetFields();
    };
  

    return (
        <Row>
            <Col xs={24} md={10} lg={6} style={{ margin: '30px auto' }}>
                <Text type="title" style={{  marginBottom:'20px'}}>
                    Şifre Yenile
                </Text>
                <Text style={{  marginBottom:'20px', display:'block'}}>Şifrenizi güncellemek için mail adresinizi giriniz.</Text>
                <Form 
                    name="sendCode" 
                    onFinish={sendCode}
                    className="d-block email-input" 
                    labelCol={{ span: 5 }}
                    layout="horizontal"
                    form={form}
                    >
                    <Form.Item
                        name="email"
                        style={{width:'100%'}}
                        rules={[
                            { 
                                type: 'email',
                                message: 'Lütfen geçerli bir email giriniz!' },
                                    {
                                        required: true,
                                        message: 'Lütfen email giriniz!',
                                    },
                                ]}>
                            <Input placeholder="E-posta adresi" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                style={{width:'100%', margin:'0 auto', display:'block'}}
                                loading={isResetPasswordSendCodeLoading}
                                block>
                                    Gönder
                            </Button>
                        </Form.Item>
                    </Form>

            </Col>
        </Row>
    );
}

export default PasswordUpdate;
