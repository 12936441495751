import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router';
import Text from '../../components/Text';
import { chequeStatusMapByValue } from '../../constants';
import { getDiscountBuyerInvoices } from '../../apiServices/fundApi';
import { setDiscountInvoices } from '../../store/reducers/fundSlice';
import { convertFloatDotSeperated } from '../../utils';
import urls from '../../routes/urls';

function FundList() {
    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => state.user);
    const { discountInvoices } = useSelector((state) => state.fund);
    const [pSize, setPsize] = useState(10);
    const [pNumber, setpNumber] = useState(1);
    const [totalDataCount, setTotalDataCount] = useState(1);
    
    const getDiscountInvoice = async () => {     
        try {            
            setLoading(true);
            const response = await getDiscountBuyerInvoices(user.id, pSize, pNumber);       
            if (response) {
                setLoading(false);
                dispatch(setDiscountInvoices(response.data.sort((a, b) => b.applicationNumber - a.applicationNumber)));
                setTotalDataCount(response.totalDataCount)
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }       
    };

    useEffect(() => {      
        user && user.id && getDiscountInvoice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if( user && user.id){
            const MINUTE = 10000 ;
            const interval = setInterval(() => {
                getDiscountInvoice();
            }, MINUTE);
            return () => clearInterval(interval);
          }  
    }, [user, pNumber, pSize]);

    const onClickFund = (fund) => {
       history.push(urls.getfundDetail(fund.id));
    };
    
    const handleShowSize = (current, size) => {
        setpNumber(current);
        setPsize(size);
      };

    const tableCols = [
        {
            title: 'Başvuru No',
            dataIndex: 'applicationNumber',
            key: 'applicationNumber',
        },
        {
            title: 'Başvuru Tarihi',
            dataIndex: 'createdDate',
            render: (date) => moment(date).format('DD-MM-YYYY'),
            key: 'createdDate',
        },
        {
            title: 'Fatura Toplamı',
            dataIndex: 'invoiceTotal',
            key: 'invoiceTotal',
            render: convertFloatDotSeperated,
        },
        {
            title: 'Fatura Adedi',
            dataIndex: 'invoiceCount',
            key: 'invoiceCount',
        },
        {
            title: 'Banka',
            dataIndex: 'financialInstitutionName',
            key: 'financialInstitutionName',
        },
        {
            title: 'Durumu',
            dataIndex: 'status',
            render: (value) => (
                <Text color={theme?.getThemedColor(chequeStatusMapByValue[value]?.color)} bold>
                    {chequeStatusMapByValue[value] && chequeStatusMapByValue[value].text}
                </Text>
            ),
            key: 'status',
        },
    ];
   

    return (
        <>
            <Row style={{ marginTop:'20px', marginBottom:'20px'}}>
                <Col xl={24}>
                <Text className='page-title'>Başvurularım</Text>
                </Col>
            </Row>
            <Row>
                <Col xl={24}>
                    <Table
                        rowKey="invoiceNo"
                        className='cursor-pointer'
                        loading={loading}
                        dataSource={discountInvoices}
                        columns={tableCols}
                        pagination={{
                            position: ['topRight', 'none'],
                            showLessItems: true,                          
                            showSizeChanger: true,
                            current: pNumber,
                            pageSize: pSize,
                            total:totalDataCount,                                                       
                            onChange: handleShowSize, 
                        }}
                        onRow={(fund) => ({
                            onClick: () => onClickFund(fund),
                        })}
                        size="middle"
                        scroll={{
                            x: 1000,
                          }}
                    />
                </Col>
            </Row>
        </>
    );
}

export default FundList;
